import React, { forwardRef, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { animated } from 'react-spring';

import {
  RootRoute,
  AboutRoute,
  WorkRoute,
  SkillsRoute,
  SettingsRoute,
  ContactRoute,
} from '../../Constants/Routes';
import { PALETTE_EASE_IN } from '../../Constants/TransitionCurves';
import FontFamilies from '../../Constants/FontFamilies';

import useI18nConsumer from '../../Hooks/useI18nConsumer';

import HomeIcon from '../Icons/HomeIcon';
import MehBlankIcon from '../Icons/MehBlankIcon';
import BriefcaseIcon from '../Icons/BriefcaseIcon';
import FireIcon from '../Icons/FireIcon';
import CogIcon from '../Icons/CogIcon';
import MailIcon from '../Icons/MailIcon';

import MenuExpandHamburger from './MenuExpandHamburger';
import MainLinks from './MainLinks';

const StyledSidebar = styled(animated.nav)`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: ${props => props.theme.panel.string()};
  transition: ${PALETTE_EASE_IN};
  font-family: 'Dank Mono', ${FontFamilies};
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`;

const Divider = styled.div`
  border-bottom: 1px solid ${props => props.theme.guide.string()};
  transition: ${PALETTE_EASE_IN};
`;

const SidebarMenu = ({
  baseRef = () => {},
  className = '',
  spring = {},

  expanded = false,

  onHamburgerClick = () => {},
}) => {
  const { t, currentLanguage } = useI18nConsumer();
  const theme = useContext(ThemeContext);

  const renderHomeIcon = useCallback(size => <HomeIcon size={size} />, []);
  const renderAboutIcon = useCallback(size => <MehBlankIcon size={size} />, []);
  const renderWorkIcon = useCallback(size => <BriefcaseIcon size={size} />, []);
  const renderSkillsIcon = useCallback(size => <FireIcon size={size} />, []);
  const renderSettingsIcon = useCallback(size => <CogIcon size={size} />, []);
  const renderContactIcon = useCallback(size => <MailIcon size={size} />, []);

  const centerButtons = [
    {
      id: 'home',
      renderIcon: renderHomeIcon,
      highlightColor: theme.foregroundHome.string(),
      label: t('SidebarMenu.home'),
      route: RootRoute,
    },
    {
      id: 'aboutMe',
      renderIcon: renderAboutIcon,
      highlightColor: theme.foregroundAbout.string(),
      label: t('SidebarMenu.aboutMe'),
      route: AboutRoute,
    },
    {
      id: 'myWork',
      renderIcon: renderWorkIcon,
      highlightColor: theme.foregroundWork.string(),
      label: t('SidebarMenu.myWork'),
      route: WorkRoute,
    },
    {
      id: 'skills',
      renderIcon: renderSkillsIcon,
      highlightColor: theme.foregroundSkills.string(),
      label: t('SidebarMenu.skills'),
      route: SkillsRoute,
    },
    {
      id: 'settings',
      renderIcon: renderSettingsIcon,
      highlightColor: theme.foregroundSettings.string(),
      label: t('SidebarMenu.settings'),
      route: SettingsRoute,
    },
  ];

  const bottomButtons = [
    {
      id: 'contact',
      renderIcon: renderContactIcon,
      label: t('SidebarMenu.contact'),
      highlightColor: theme.foregroundContact.string(),
      route: ContactRoute,
    },
  ];

  const renderSidebarContent = () => {
    return (
      <>
        <MenuExpandHamburger direction={expanded ? 'left' : 'right'} onClick={onHamburgerClick} />
        <Divider />
        <LinksWrapper>
          <MainLinks buttons={centerButtons} />
        </LinksWrapper>
        <Divider />
        <MainLinks buttons={bottomButtons} />
      </>
    );
  };

  return (
    <StyledSidebar ref={baseRef} className={className} style={spring} language={currentLanguage}>
      {renderSidebarContent()}
    </StyledSidebar>
  );
};

SidebarMenu.propTypes = {
  baseRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  className: PropTypes.string,
  spring: PropTypes.objectOf(PropTypes.any),

  expanded: PropTypes.bool,

  onHamburgerClick: PropTypes.func,
};

export default forwardRef((props, ref) => <SidebarMenu baseRef={ref} {...props} />);
