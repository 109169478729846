import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';

import { DisplayModeContext, MOBILE } from '../../Constants/DisplayModes';
import { calculateForeground } from '../../Constants/Colors';
import { HOVER_SIZE_EASE_IN } from '../../Constants/TransitionCurves';

import HighlightButton, { ButtonLogo } from './Generic/HighlightButton';

const StyledSkilledButton = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  color: ${props => props.theme.foreground.string()};
  ${ButtonLogo} {
    padding: 10px;
  }
`;

const StyledLink = styled.a`
  text-decoration: none;
`;

const IconWrapper = styled.div`
  height: auto;
  transform: scale(1.05);

  width: ${props => {
    switch (props.displayMode) {
      case MOBILE:
        return '80px';
      default:
        return '100px';
    }
  }};
  transition: ${HOVER_SIZE_EASE_IN};
`;

const SkillButton = ({
  className = '',

  icon = null,
  href = '',
  hoverText = '',
}) => {
  const theme = useContext(ThemeContext);
  const displayMode = useContext(DisplayModeContext);

  const hoverBgColor = theme.foregroundSkills;
  const hoverFgColor = calculateForeground(theme, hoverBgColor);

  return (
    <StyledSkilledButton className={className}>
      <StyledLink href={href} target="_blank" rel="noopener noreferrer">
        <HighlightButton
          hoverText={hoverText}
          hoverBgColor={hoverBgColor.toString()}
          hoverFgColor={hoverFgColor.toString()}
        >
          <IconWrapper displayMode={displayMode}>{icon}</IconWrapper>
        </HighlightButton>
      </StyledLink>
      {hoverText}
    </StyledSkilledButton>
  );
};

SkillButton.propTypes = {
  className: PropTypes.string,

  icon: PropTypes.element.isRequired,
  href: PropTypes.string.isRequired,
  hoverText: PropTypes.string,
};

export default SkillButton;
