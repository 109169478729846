import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { detect } from 'detect-browser';

import useI18nConsumer from '../../Hooks/useI18nConsumer';

import { LIGHT_PALETTE, MIRAGE_PALETTE, DARK_PALETTE } from '../../Constants/Colors';
import { DisplayModeContext, MOBILE } from '../../Constants/DisplayModes';

import Portlet, { PortletContent } from './Generic/Portlet';
import PaletteButton from '../Inputs/PaletteButton';

const browser = detect() || {};

const StyledPaletteButton = styled(PaletteButton)`
  margin-bottom: ${props => (props.displayMode === MOBILE ? '10px' : '')};
`;

const StyledPalettePortlet = styled(Portlet)`
  ${PortletContent} {
    display: flex;
    flex-direction: ${props => (props.displayMode === MOBILE ? 'column' : 'row')};
    flex-wrap: wrap;
    justify-content: ${['edge', 'ie'].includes(browser.name) ? 'space-around' : 'space-evenly'};
  }
  ${StyledPaletteButton}:last-child {
    margin-bottom: ${props => (props.displayMode === MOBILE ? '0' : '')};
  }
`;

const PalettePortlet = ({ className = '', onPaletteIdChange = () => {} }) => {
  const { t } = useI18nConsumer();
  const theme = useContext(ThemeContext);
  const displayMode = useContext(DisplayModeContext);

  const buttons = [
    {
      id: 'light',
      hoverText: t('PalettePortlet.light'),
      highlighted: theme.id === LIGHT_PALETTE,
      paletteId: LIGHT_PALETTE,
      onClick: () => onPaletteIdChange(LIGHT_PALETTE),
    },
    {
      id: 'mirage',
      hoverText: t('PalettePortlet.mirage'),
      highlighted: theme.id === MIRAGE_PALETTE,
      paletteId: MIRAGE_PALETTE,
      onClick: () => onPaletteIdChange(MIRAGE_PALETTE),
    },
    {
      id: 'dark',
      hoverText: t('PalettePortlet.dark'),
      highlighted: theme.id === DARK_PALETTE,
      paletteId: DARK_PALETTE,
      onClick: () => onPaletteIdChange(DARK_PALETTE),
    },
  ];

  return (
    <StyledPalettePortlet
      className={className}
      displayMode={displayMode}
      title={t('PalettePortlet.theme')}
    >
      {buttons.map(button => {
        const { id, hoverText, highlighted, paletteId, onClick } = button;

        return (
          <StyledPaletteButton
            displayMode={displayMode}
            key={id}
            hoverText={hoverText}
            highlighted={highlighted}
            paletteId={paletteId}
            onClick={onClick}
          />
        );
      })}
    </StyledPalettePortlet>
  );
};

PalettePortlet.propTypes = {
  className: PropTypes.string,

  onPaletteIdChange: PropTypes.func,
};

export default PalettePortlet;
