import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { MIRAGE_PALETTE, DARK_PALETTE } from '../../../Constants/Colors';

const StyledTimelineMeta = styled.div`
  display: flex;
  flex-direction: column;

  color: ${props => {
    const { theme } = props;
    if (theme.id === MIRAGE_PALETTE || theme.id === DARK_PALETTE) {
      return theme.foregroundIdle.lighten(0.23).string();
    }

    return theme.foregroundIdle.string();
  }};
`;

const Title = styled.div`
  color: ${props => props.theme.foreground.string()};
  font-size: 1.1em;
`;

const DateRange = styled.div`
  margin-bottom: 20px;
`;

const StyledLink = styled.a`
  color: ${props => props.theme.foregroundAbout.fade(0.1).string()};
  text-decoration: none;
  font-weight: bold;
`;

const TimelineMeta = ({ className = '', title = '', dateRange = '', site = {} }) => {
  return (
    <StyledTimelineMeta className={className}>
      <Title>{title}</Title>
      <DateRange>{dateRange}</DateRange>
      <StyledLink href={site.href} target="_blank" rel="noopener noreferrer">
        {site.value}
      </StyledLink>
    </StyledTimelineMeta>
  );
};

TimelineMeta.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  dateRange: PropTypes.string,
  site: PropTypes.shape({
    href: PropTypes.string,
    value: PropTypes.string,
  }),
};

export default TimelineMeta;
