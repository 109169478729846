import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Overlay from './Overlay';

export const ModalContent = styled.div`
  position: absolute;
  display: flex;
  top: 50%;
  left: 50%;
  min-height: 250px;
  width: 300px;
  box-sizing: border-box;
  padding: 20px;
  background-color: ${props => props.theme.panel.lighten(0.5).string()};
  transition: all 300ms;
  transform-origin: 50% 50%;
  transform: ${props =>
    props.mounted ? 'translate(-50%, -50%)' : 'translate(-50%, -50%) scale(0.5, 0.5)'};
  visibility: ${props => (props.mounted ? '' : 'hidden')};
`;

const Modal = ({
  className = '',
  children = null,

  overlayColor = 'rgba(0,0,0,0.5)',
  onOverlayClick = () => {},
}) => {
  const contentRef = useRef(null);

  const [mounted, setMounted] = useState(false);

  // start animating modal expansion
  useEffect(() => setMounted(true), []);

  const renderContent = () => {
    return (
      <ModalContent ref={contentRef} mounted={mounted}>
        {children}
      </ModalContent>
    );
  };

  return (
    <Overlay className={className} overlayColor={overlayColor} onClick={onOverlayClick}>
      {renderContent()}
    </Overlay>
  );
};

Modal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),

  overlayColor: PropTypes.string,
  onOverlayClick: PropTypes.func,
};

export default Modal;
