import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { PALETTE_EASE_IN } from '../../../Constants/TransitionCurves';

const StyledPageHeader = styled.header`
  display: flex;
  flex-direction: column;
  margin: 0 0 10px;
`;

const HeaderText = styled.h1`
  font-size: 2.25em;
  font-weight: bold;
  margin: 0 0 10px;

  transition: ${PALETTE_EASE_IN};
`;

const PageHeader = ({ className = '', title = '' }) => {
  return (
    <StyledPageHeader className={className}>
      <HeaderText>{title}</HeaderText>
    </StyledPageHeader>
  );
};

PageHeader.propTypes = {
  className: PropTypes.string,

  title: PropTypes.string,
};

export default PageHeader;
