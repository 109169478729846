import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Bars } from 'styled-icons/fa-solid/Bars';

import IconButton from './Generic/IconButton';

const StyledHamburgerIcon = styled(IconButton)`
  cursor: pointer;
`;

const HamburgerIcon = ({ className = '', size = 30, onClick = () => {} }) => {
  const renderIcon = () => {
    return <Bars size={size} />;
  };

  return <StyledHamburgerIcon className={className} renderIcon={renderIcon} onClick={onClick} />;
};

HamburgerIcon.propTypes = {
  className: PropTypes.string,

  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
};

export default HamburgerIcon;
