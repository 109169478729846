import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  WorkRoute,
  FlightSimulatorRoute,
  ParticlesRoute,
  TeapotRoute,
} from '../../Constants/Routes';

import useI18nConsumer from '../../Hooks/useI18nConsumer';
import useImageLoaded from '../../Hooks/useImageLoaded';

import flightSimulatorLogoSrc from '../../Images/Work/flightSimulatorLogo.jpg';
import particlesLogoSrc from '../../Images/Work/particlesLogo.jpg';
import teapotLogoSrc from '../../Images/Work/teapotLogo.jpg';

import WorkGroupPortlet from './Generic/WorkGroupPortlet';

const UniversityWorkPortlet = ({ className = '', onLoad }) => {
  const { t } = useI18nConsumer();

  const universityButtons = [
    {
      id: 'flightSimulator',
      src: flightSimulatorLogoSrc,
      hoverText: t('UniversityWorkPortlet.flightSimulator'),
      to: `${WorkRoute}${FlightSimulatorRoute}`,
    },
    {
      id: 'particles',
      src: particlesLogoSrc,
      hoverText: t('UniversityWorkPortlet.particleSystem'),
      to: `${WorkRoute}${ParticlesRoute}`,
    },
    {
      id: 'teapot',
      src: teapotLogoSrc,
      hoverText: t('UniversityWorkPortlet.utahTeapot'),
      to: `${WorkRoute}${TeapotRoute}`,
    },
  ];

  const flightSimulatorLogoLoaded = useImageLoaded(flightSimulatorLogoSrc);
  const particlesLogoLoaded = useImageLoaded(particlesLogoSrc);
  const teapotLogoLoaded = useImageLoaded(teapotLogoSrc);

  useEffect(() => {
    if (flightSimulatorLogoLoaded && particlesLogoLoaded && teapotLogoLoaded) {
      onLoad();
    }
  }, [flightSimulatorLogoLoaded, onLoad, particlesLogoLoaded, teapotLogoLoaded]);

  return (
    <WorkGroupPortlet
      className={className}
      title={t('UniversityWorkPortlet.university')}
      buttons={universityButtons}
    />
  );
};

UniversityWorkPortlet.propTypes = {
  className: PropTypes.string,

  onLoad: PropTypes.func,
};

export default UniversityWorkPortlet;
