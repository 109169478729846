import { useCallback, useState } from 'react';

// initial implementation: https://usehooks.com/useLocalStorage/

const getInitialValue = (key, defaultVal) => {
  try {
    // Get from local storage by key
    const item = window.localStorage.getItem(key);
    // Parse stored json or if none return getDefaultVal
    return item ? JSON.parse(item) : defaultVal;
  } catch (error) {
    // If error also return getDefaultVal
    console.error(error);
    return defaultVal;
  }
};

const useLocalStorage = (key, defaultVal) => {
  const [storedValue, setStoredValue] = useState(() => getInitialValue(key, defaultVal));

  const setValue = useCallback(
    updater => {
      const newVal = updater instanceof Function ? updater(storedValue) : updater;
      // store in localstorage and setState
      window.localStorage.setItem(key, JSON.stringify(newVal));
      setStoredValue(newVal);
    },
    [key, storedValue]
  );

  return [storedValue, setValue];
};

export default useLocalStorage;
