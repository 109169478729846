import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Linkedin } from 'styled-icons/fa-brands/Linkedin';

import IconButton from './Generic/IconButton';

const StyledLinkedInIcon = styled(IconButton)`
  cursor: pointer;
`;

const LinkedInIcon = ({ className = '', size = 30, onClick = () => {} }) => {
  const renderIcon = () => {
    return <Linkedin size={size} />;
  };

  return <StyledLinkedInIcon className={className} renderIcon={renderIcon} onClick={onClick} />;
};

LinkedInIcon.propTypes = {
  className: PropTypes.string,

  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
};

export default forwardRef((props, ref) => <LinkedInIcon {...props} baseRef={ref} />);
