import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledTimelineRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 10px 17.5px 15px 17.5px;
`;

const Title = styled.div`
  color: ${props => props.theme.foreground.string()};
  font-size: 1.1em;
`;

const TimelineRow = ({ className = '', title = '', dateRange = '' }) => {
  return (
    <StyledTimelineRow className={className}>
      <Title>{title}</Title>
      <div>{dateRange}</div>
    </StyledTimelineRow>
  );
};

TimelineRow.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  dateRange: PropTypes.string,
};

export default TimelineRow;
