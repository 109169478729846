import React from 'react';
import PropTypes from 'prop-types';

import useI18nConsumer from '../../Hooks/useI18nConsumer';

import SkillGroupPortlet from './Generic/SkillGroupPortlet';
import HTML5Icon from '../Icons/Skills/HTML5Icon';
import CSS3Icon from '../Icons/Skills/CSS3Icon';
import StyledComponentsIcon from '../Icons/Skills/StyledComponentsIcon';

const HtmlCssSkillsPortlet = ({ className = '' }) => {
  const { t } = useI18nConsumer();

  const htmlCssButtons = [
    {
      id: 'html5',
      icon: <HTML5Icon />,
      href: 'https://www.w3.org/html/',
      hoverText: t('HtmlCssSkillsPortlet.html5'),
    },
    {
      id: 'css3',
      icon: <CSS3Icon />,
      href: 'https://www.w3.org/Style/CSS/Overview.en.html',
      hoverText: t('HtmlCssSkillsPortlet.css3'),
    },
    {
      id: 'styledComponents',
      icon: <StyledComponentsIcon />,
      href: 'https://www.styled-components.com/',
      hoverText: t('HtmlCssSkillsPortlet.styledComponents'),
    },
  ];

  return (
    <SkillGroupPortlet
      className={className}
      title={t('HtmlCssSkillsPortlet.htmlCss')}
      buttons={htmlCssButtons}
    />
  );
};

HtmlCssSkillsPortlet.propTypes = {
  className: PropTypes.string,
};

export default HtmlCssSkillsPortlet;
