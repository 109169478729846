import Color from 'color';

export const LIGHT_PALETTE = 'LIGHT_PALETTE';
export const MIRAGE_PALETTE = 'MIRAGE_PALETTE';
export const DARK_PALETTE = 'DARK_PALETTE';

const Light = {
  id: LIGHT_PALETTE,

  background: Color('rgb(250, 250, 250)').darken(0.0125),
  spaceBackground: Color('rgb(254, 255, 255)'),

  foreground: Color('rgb(92, 103, 115)').darken(0.05),
  foregroundChevron: Color('rgb(255, 119, 51)'),
  foregroundHamburger: Color('rgb(62, 73, 85)'),
  foregroundIdle: Color('rgb(130, 140, 153)').darken(0.05),

  foregroundHome: Color('rgb(54, 163, 217)'),
  foregroundAbout: Color('rgb(134, 179, 0)'),
  foregroundContact: Color('rgb(76, 191, 153)'),
  foregroundWork: Color('rgb(242, 151, 24)'),
  foregroundSettings: Color('rgb(163, 122, 204)'),
  foregroundSkills: Color('rgb(240, 113, 120)'),

  guide: Color('rgb(217, 216, 215)'),
  panel: Color('rgb(255, 255, 255)'),

  error: Color('rgb(255, 51, 51)').lighten(0.2),

  bg: Color('rgb(250, 250, 250)'),
  comment: Color('rgb(171, 176, 182)'),
  markup: Color('rgb(240, 113, 120)'),
  constant: Color('rgb(163, 122, 204)'),
  operator: Color('rgb(231, 197, 71)'),
  tag: Color('rgb(54, 163, 217)'),
  regexp: Color('rgb(76, 191, 153)'),
  string: Color('rgb(134, 179, 0)'),
  function: Color('rgb(242, 151, 24)'),
  special: Color('rgb(230, 182, 115)'),
  keyword: Color('rgb(255, 119, 51)'),
  accent: Color('rgb(255, 106, 0)'),
  line: Color('rgb(243, 243, 243)'),
  selection: Color('rgb(240, 238, 228)'),
  fg: Color('rgb(92, 103, 115)'),
  fg_idle: Color('rgb(130, 140, 153)'),
};

const Mirage = {
  id: MIRAGE_PALETTE,

  background: Color('rgb(33, 39, 51)'),
  spaceBackground: Color('rgb(28, 35, 45)'),

  foreground: Color('rgb(255, 255, 255)'),
  foregroundChevron: Color('rgb(255, 174, 87)'),
  foregroundHamburger: Color('rgb(247, 245, 236)'),
  foregroundIdle: Color('rgb(96, 112, 128)'),

  foregroundHome: Color('rgb(92, 207, 230)'),
  foregroundAbout: Color('rgb(187, 230, 126)'),
  foregroundContact: Color('rgb(149, 230, 203)'),
  foregroundWork: Color('rgb(255, 213, 127)'),
  foregroundSettings: Color('rgb(212, 191, 255)'),
  foregroundSkills: Color('rgb(240, 113, 120)'),

  guide: Color('rgb(61, 71, 81)'),
  panel: Color('rgb(39, 45, 56)').lighten(0.15),

  error: Color('rgb(255, 51, 51)').lighten(0.2),

  bg: Color('rgb(33, 39, 51)'),
  comment: Color('rgb(92, 103, 115)'),
  markup: Color('rgb(240, 113, 120)'),
  constant: Color('rgb(212, 191, 255)'),
  operator: Color('rgb(128, 212, 255)'),
  tag: Color('rgb(92, 207, 230)'),
  regexp: Color('rgb(149, 230, 203)'),
  string: Color('rgb(187, 230, 126)'),
  function: Color('rgb(255, 213, 127)'),
  special: Color('rgb(255, 196, 76)'),
  keyword: Color('rgb(255, 174, 87)'),
  accent: Color('rgb(255, 204, 102)'),
  line: Color('rgb(36, 43, 56)'),
  selection: Color('rgb(52, 63, 76)'),
  fg: Color('rgb(217, 215, 206)'),
  fg_idle: Color('rgb(96, 112, 128)'),
};

const Dark = {
  id: DARK_PALETTE,

  background: Color('rgb(15, 20, 25)'),
  spaceBackground: Color('rgb(15, 21, 24)'),

  foreground: Color('rgb(230, 225, 207)'),
  foregroundChevron: Color('rgb(255, 119, 51)'),
  foregroundHamburger: Color('rgb(255, 255, 237)'),
  foregroundIdle: Color('rgb(62, 75, 89)'),

  foregroundHome: Color('rgb(54, 163, 217)'),
  foregroundAbout: Color('rgb(184, 204, 82)'),
  foregroundContact: Color('rgb(149, 230, 203)'),
  foregroundWork: Color('rgb(255, 180, 84)'),
  foregroundSettings: Color('rgb(255, 238, 153)'),
  foregroundSkills: Color('rgb(240, 113, 120)'),

  guide: Color('rgb(45, 54, 64)'),
  panel: Color('rgb(20, 25, 31)').darken(0.1),

  error: Color('rgb(255, 51, 51)').lighten(0.2),

  bg: Color('rgb(15, 20, 25)'),
  comment: Color('rgb(92, 103, 115)'),
  markup: Color('rgb(240, 113, 120)'),
  constant: Color('rgb(255, 238, 153)'),
  operator: Color('rgb(231, 197, 71)'),
  tag: Color('rgb(54, 163, 217)'),
  regexp: Color('rgb(149, 230, 203)'),
  string: Color('rgb(184, 204, 82)'),
  function: Color('rgb(255, 180, 84)'),
  special: Color('rgb(230, 182, 115)'),
  keyword: Color('rgb(255, 119, 51)'),
  accent: Color('rgb(242, 151, 24)'),
  line: Color('rgb(21, 26, 30)'),
  selection: Color('rgb(37, 51, 64)'),
  fg: Color('rgb(230, 225, 207)'),
  fg_idle: Color('rgb(62, 75, 89)'),
};

export const getPalette = themeId => {
  switch (themeId) {
    case LIGHT_PALETTE:
      return Light;
    case MIRAGE_PALETTE:
      return Mirage;
    case DARK_PALETTE:
      return Dark;
    default:
      console.error('getPalette, invalid themeId', themeId);
      return Mirage;
  }
};

export const calculateForeground = (theme, bgColor) => {
  const { foreground } = theme;

  const mixedForeground = foreground.mix(bgColor);

  if (bgColor.isDark()) {
    return mixedForeground.grayscale().lighten(0.6);
  }
  return mixedForeground.grayscale().darken(0.6);
};

export const allPalettes = [LIGHT_PALETTE, MIRAGE_PALETTE, DARK_PALETTE];

export const toVector = color => [color.red() / 255.0, color.green() / 255.0, color.blue() / 255.0];

export const getRGBHeightVectors = theme => {
  const toVectorString = color => {
    return `vec3(${toVector(color).join(', ')})`;
  };

  const redColor = theme.foregroundSkills;
  const greenColor = theme.foregroundAbout;
  const blueColor = theme.foregroundHome;

  return {
    r: toVectorString(redColor),
    g: toVectorString(greenColor),
    b: toVectorString(blueColor),
  };
};

export default {
  Light,
  Mirage,
};
