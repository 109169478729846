import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { animated } from 'react-spring';
import { Route, Switch } from 'react-router-dom';

import {
  RootRoute,
  AboutRoute,
  WorkRoute,
  SkillsRoute,
  SettingsRoute,
  ContactRoute,
} from '../../Constants/Routes';

import HomePage from '../Pages/HomePage';
import AboutPage from '../Pages/AboutPage';
import WorkPage from '../Pages/WorkPage';
import SettingsPage from '../Pages/SettingsPage';
import SkillsPage from '../Pages/SkillsPage';
import ContactPage from '../Pages/ContactPage';

const StyledMainScreenContent = styled(animated.div)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
`;

const MainScreenContent = ({
  className = '',
  spring = {},
  onPaletteIdChange = () => {},
  onLanguageChange = () => {},
}) => {
  return (
    <StyledMainScreenContent className={className} style={spring}>
      <Switch>
        <Route exact path={RootRoute}>
          <HomePage />
        </Route>
        <Route path={AboutRoute}>
          <AboutPage />
        </Route>
        <Route path={WorkRoute}>
          <WorkPage />
        </Route>
        <Route path={SkillsRoute}>
          <SkillsPage />
        </Route>
        <Route path={SettingsRoute}>
          <SettingsPage onPaletteIdChange={onPaletteIdChange} onLanguageChange={onLanguageChange} />
        </Route>
        <Route path={ContactRoute}>
          <ContactPage />
        </Route>
        <Route>
          <HomePage />
        </Route>
      </Switch>
    </StyledMainScreenContent>
  );
};

MainScreenContent.propTypes = {
  className: PropTypes.string,
  spring: PropTypes.objectOf(PropTypes.any),

  onPaletteIdChange: PropTypes.func,
  onLanguageChange: PropTypes.func,
};

export default MainScreenContent;
