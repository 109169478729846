import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import FontFamilies from '../../../Constants/FontFamilies';
import { HOVER_COLOR_EASE_IN, HOVER_SIZE_EASE_IN } from '../../../Constants/TransitionCurves';

const StyledTextInput = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px 10px 0px;
`;

const Label = styled.label`
  margin-bottom: 10px;
  font-size: 1.2em;
  color: ${props => (props.complain ? props.theme.error.string() : '')};
  transition: ${HOVER_COLOR_EASE_IN};
`;

export const Input = styled.input`
  height: 2.4em;
  width: 250px;
  border: medium none;
  padding: 0px 8px;
  font-family: ${FontFamilies};
  font-size: 1.2em;
  background-color: ${props => props.theme.guide.string()};
  color: ${props => props.theme.foreground.string()};
  outline: none;

  box-shadow: ${props =>
    props.complain ? `${props.theme.error.string()} 0px 0px 0px 1px` : 'none'};

  transition: ${HOVER_COLOR_EASE_IN};

  :focus {
    box-shadow: ${props =>
        props.complain ? props.theme.error.string() : props.theme.foregroundContact.string()}
      0px 0px 0px 1px;
  }
  :disabled {
    color: ${props => props.theme.foreground.fade(0.5).string()};
  }
`;

const ErrorMessage = styled.div`
  height: ${props => (props.complain ? '0.9em' : 0)};
  opacity: ${props => (props.complain ? 1 : 0)};
  margin-top: 3px;
  font-size: 0.9em;
  color: ${props => props.theme.error.string()};
  transition: ${HOVER_COLOR_EASE_IN}, ${HOVER_SIZE_EASE_IN};
`;

const TextInput = ({
  className = '',
  label = '',
  value = '',
  placeholder = '',
  maxLength = '',
  disabled = false,
  valid = true,
  error = '',
  inputAs = 'input',
  onChange = () => {},
}) => {
  const [touched, setTouched] = useState(false);

  const complain = touched && !valid && error !== '';

  const handleBlur = useCallback(() => setTouched(true), []);

  const renderError = () => {
    return <ErrorMessage complain={complain}>{error}</ErrorMessage>;
  };

  return (
    <StyledTextInput className={className}>
      <Label complain={complain}>{label}</Label>
      <Input
        aria-label={label}
        value={value}
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={disabled}
        onChange={onChange}
        onBlur={handleBlur}
        complain={complain}
        as={inputAs}
      />
      {renderError()}
    </StyledTextInput>
  );
};

TextInput.propTypes = {
  className: PropTypes.string,

  label: PropTypes.string,

  placeholder: PropTypes.string,
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  valid: PropTypes.bool,
  error: PropTypes.string,
  inputAs: PropTypes.string,

  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default TextInput;
