import { mat3, mat4, quat, vec3 } from 'gl-matrix';
import { createGLContext } from './webGLUtils';

export default class GLCanvas {
  constructor({
    canvas,
    eyePt = [0.0, 0.0, 1.0],
    viewPt = [0.0, 0.0, 0.0],
    viewDir = [0.0, 0.0, -1.0],
    up = [0.0, 1.0, 0.0],
    lightPt = [1.0, 1.0, 1.0],
    clearColor = [1.0, 1.0, 1.0, 1.0],
    orbitSpeed = 0.025,
  }) {
    // gl obj
    this.gl = createGLContext({ canvas });
    this.shaderProgram = null;
    this.clearColor = clearColor;

    // view matrices
    this.pMatrix = mat4.create();
    this.nMatrix = mat3.create();

    // initial parameters
    this.initialEyePt = eyePt;
    this.initialViewPt = viewPt;
    this.initialViewDir = viewDir;
    this.initialUp = up;
    this.initialLightPt = lightPt;
    this.initialOrbitSpeed = orbitSpeed;

    // view parameters
    this.eyePt = null;
    this.viewPt = null;
    this.viewDir = null;
    this.up = null;
    this.lightPt = null;
    this.resetCamera();

    // flight parameters
    this.orbitSpeed = this.initialOrbitSpeed;

    // ModelView matrix
    this.mvMatrix = mat4.create();
    this.mvMatrixStack = [];

    // transform
    this.inverseViewTransform = mat3.create();

    // state
    this.state = {
      currentlyPressedKeys: {},
    };

    // bindings
    this.initialize = this.initialize.bind(this);
    this.teardown = this.teardown.bind(this);
    this.setupShaders = this.setupShaders.bind(this);
    this.resetCamera = this.resetCamera.bind(this);
  }

  setState = updater => {
    let newState;
    if (typeof updater === 'function') {
      newState = updater(this.state);
    } else {
      newState = updater;
    }
    this.state = {
      ...this.state,
      ...newState,
    };
  };

  initialize() {
    this.gl.clearColor(...this.clearColor);
    this.gl.enable(this.gl.DEPTH_TEST);

    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('keyup', this.handleKeyUp);
  }

  teardown() {
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('keyup', this.handleKeyUp);
  }

  handleKeyDown = event => {
    const { code } = event;
    this.setState(prevState => {
      return {
        ...prevState,
        currentlyPressedKeys: {
          ...prevState.currentlyPressedKeys,
          [code]: true,
        },
      };
    });
  };

  handleKeyUp = event => {
    const { code } = event;
    this.setState(prevState => {
      return {
        ...prevState,
        currentlyPressedKeys: {
          ...prevState.currentlyPressedKeys,
          [code]: false,
        },
      };
    });
  };

  mvPushMatrix = () => {
    const copy = mat4.clone(this.mvMatrix);
    this.mvMatrixStack.push(copy);
  };

  mvPopMatrix = () => {
    if (this.mvMatrixStack.length === 0) {
      throw new Error('Invalid popMatrix!');
    }
    this.mvMatrix = this.mvMatrixStack.pop();
  };

  setMatrixUniforms = () => {
    // uploadModelViewMatrixToShader
    this.gl.uniformMatrix4fv(this.shaderProgram.mvMatrixUniform, false, this.mvMatrix);
    // uploadNormalMatrixToShader
    mat3.normalFromMat4(this.nMatrix, this.mvMatrix);
    this.gl.uniformMatrix3fv(this.shaderProgram.nMatrixUniform, false, this.nMatrix);
    // uploadProjectionMatrixToShader
    this.gl.uniformMatrix4fv(this.shaderProgram.pMatrixUniform, false, this.pMatrix);

    // final
    this.gl.uniformMatrix3fv(
      this.shaderProgram.inverseViewTransform,
      false,
      this.inverseViewTransform
    );
  };

  uploadLightsToShader = ({ loc, a, d, s }) => {
    this.gl.uniform3fv(this.shaderProgram.uniformLightPositionLoc, loc);
    this.gl.uniform3fv(this.shaderProgram.uniformAmbientLightColorLoc, a);
    this.gl.uniform3fv(this.shaderProgram.uniformDiffuseLightColorLoc, d);
    this.gl.uniform3fv(this.shaderProgram.uniformSpecularLightColorLoc, s);
  };

  uploadMaterialToShader = ({ dcolor, acolor, scolor, shiny }) => {
    this.gl.uniform3fv(this.shaderProgram.uniformDiffuseMaterialColor, dcolor);
    this.gl.uniform3fv(this.shaderProgram.uniformAmbientMaterialColor, acolor);
    this.gl.uniform3fv(this.shaderProgram.uniformSpecularMaterialColor, scolor);
    this.gl.uniform1f(this.shaderProgram.uniformShininess, shiny);
  };

  createShader = ({ src, type }) => {
    // Compiles either a shader of type gl.VERTEX_SHADER or gl.FRAGMENT_SHADER
    const shader = this.gl.createShader(type);
    this.gl.shaderSource(shader, src);
    this.gl.compileShader(shader);

    if (!this.gl.getShaderParameter(shader, this.gl.COMPILE_STATUS)) {
      const info = this.gl.getShaderInfoLog(shader);
      throw new Error(`Could not compile WebGL program. \n\n${info}`);
    }
    return shader;
  };

  setupShaders({ vertexShaderSrc, fragmentShaderSrc }) {
    const vertexShader = this.createShader({ src: vertexShaderSrc, type: this.gl.VERTEX_SHADER });
    const fragmentShader = this.createShader({
      src: fragmentShaderSrc,
      type: this.gl.FRAGMENT_SHADER,
    });

    this.shaderProgram = this.gl.createProgram();
    this.gl.attachShader(this.shaderProgram, vertexShader);
    this.gl.attachShader(this.shaderProgram, fragmentShader);
    this.gl.linkProgram(this.shaderProgram);

    if (!this.gl.getProgramParameter(this.shaderProgram, this.gl.LINK_STATUS)) {
      console.error('Failed to setup shaders');
    }

    this.gl.useProgram(this.shaderProgram);
  }

  orbit = ({ dir }) => {
    const orbitHor = orbitSpeed => {
      const rotationQuat = quat.create();
      quat.setAxisAngle(rotationQuat, this.up, orbitSpeed);
      vec3.transformQuat(this.eyePt, this.eyePt, rotationQuat);
      vec3.transformQuat(this.viewDir, this.viewDir, rotationQuat);
      vec3.transformQuat(this.lightPt, this.lightPt, rotationQuat);
    };

    const orbitVer = orbitSpeed => {
      const rotationQuat = quat.create();
      const sidewaysVec = vec3.create();
      vec3.cross(sidewaysVec, this.up, this.viewDir);
      quat.setAxisAngle(rotationQuat, sidewaysVec, orbitSpeed);

      vec3.transformQuat(this.up, this.up, rotationQuat);
      vec3.transformQuat(this.eyePt, this.eyePt, rotationQuat);
      vec3.transformQuat(this.viewDir, this.viewDir, rotationQuat);
      vec3.transformQuat(this.lightPt, this.lightPt, rotationQuat);
    };

    switch (dir) {
      case 'left':
        orbitHor(-this.orbitSpeed);
        break;
      case 'right':
        orbitHor(this.orbitSpeed);
        break;
      case 'up':
        orbitVer(this.orbitSpeed);
        break;
      case 'down':
        orbitVer(-this.orbitSpeed);
        break;
      default:
        console.error('invalid direction');
    }
  };

  handleKeys = () => {};

  resetCamera() {
    this.eyePt = vec3.fromValues(...this.initialEyePt);
    this.viewPt = vec3.fromValues(...this.initialViewPt);
    this.viewDir = vec3.fromValues(...this.initialViewDir);
    this.up = vec3.fromValues(...this.initialUp);
    this.lightPt = vec3.fromValues(...this.initialLightPt);
  }

  draw = () => {};

  animate = () => {};

  tick = () => {
    requestAnimationFrame(this.tick);
    this.draw();
    this.handleKeys();
    this.animate();
  };
}
