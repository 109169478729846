import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';

import './Styles/index.css';

import App from './Components/App';

ReactDOM.createRoot(document.getElementById('root')).render(<App />);
