import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TextInput, { Input } from './TextInput';

const StyledTextArea = styled(TextInput)`
  ${Input} {
    height: 10em;
    padding: 10px 8px;
    width: auto;
    max-width: 1000px;
  }
`;

const TextArea = ({
  className = '',
  label = '',
  value = '',
  placeholder = '',
  maxLength = '',
  disabled = false,
  valid = true,
  error = '',
  onChange = () => {},
}) => {
  return (
    <StyledTextArea
      className={className}
      label={label}
      value={value}
      placeholder={placeholder}
      maxLength={maxLength}
      disabled={disabled}
      valid={valid}
      error={error}
      inputAs="textarea"
      onChange={onChange}
    />
  );
};

TextArea.propTypes = {
  className: PropTypes.string,

  label: PropTypes.string,

  placeholder: PropTypes.string,
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  valid: PropTypes.bool,
  error: PropTypes.string,

  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default TextArea;
