import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { PALETTE_EASE_IN } from '../../../Constants/TransitionCurves';

export const StyledPortlet = styled.section`
  display: flex;
  flex-direction: column;
`;

export const PortletTitle = styled.header`
  font-size: 1.4em;
  margin-bottom: 7.5px;
  transition: ${PALETTE_EASE_IN};
  color: ${props => props.theme.foreground.string()};
`;

export const PortletContent = styled.div`
  border-radius: 10px;
  transition: ${PALETTE_EASE_IN};
  background-color: ${props => props.theme.panel.string()};

  padding: 15px;
`;

const Portlet = ({ className = '', children = null, title = '' }) => {
  return (
    <StyledPortlet className={className}>
      <PortletTitle>{title}</PortletTitle>
      <summary>
        <PortletContent>{children}</PortletContent>
      </summary>
    </StyledPortlet>
  );
};

Portlet.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),

  title: PropTypes.string,
};

export default Portlet;
