import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { detect } from 'detect-browser';

import useI18nConsumer from '../../Hooks/useI18nConsumer';

import USFlagIcon from '../Icons/USFlagIcon';
import JapanFlagIcon from '../Icons/JapanFlagIcon';

import Portlet, { PortletContent } from './Generic/Portlet';
import LanguageButton from '../Inputs/LanguageButton';

const browser = detect() || {};

const StyledLanguagePortlet = styled(Portlet)`
  ${PortletContent} {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: ${['edge', 'ie'].includes(browser.name) ? 'space-around' : 'space-evenly'};
  }
`;

const LanguagePortlet = ({ className = '', onLanguageChange = () => {} }) => {
  const { currentLanguage, t } = useI18nConsumer();

  const buttons = useMemo(
    () => [
      {
        id: 'usEnglish',
        icon: <USFlagIcon />,
        hoverText: 'US English',
        highlighted: currentLanguage === 'en',
        onClick: () => onLanguageChange('en'),
      },
      {
        id: 'japanese',
        icon: <JapanFlagIcon />,
        hoverText: '日本語',
        highlighted: currentLanguage === 'ja',
        onClick: () => onLanguageChange('ja'),
      },
    ],
    [currentLanguage, onLanguageChange]
  );

  return (
    <StyledLanguagePortlet className={className} title={t('LanguagePortlet.language')}>
      {buttons.map(button => {
        const { id, icon, highlighted, hoverText, onClick } = button;

        return (
          <LanguageButton
            key={id}
            highlighted={highlighted}
            hoverText={hoverText}
            onClick={onClick}
          >
            {icon}
          </LanguageButton>
        );
      })}
    </StyledLanguagePortlet>
  );
};

LanguagePortlet.propTypes = {
  className: PropTypes.string,
  onLanguageChange: PropTypes.func,
};

export default LanguagePortlet;
