import { useState, useLayoutEffect } from 'react';

const useDimensions = ref => {
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);

  const setDimensions = () => {
    const node = ref.current;
    setHeight(node.offsetHeight);
    setWidth(node.offsetWidth);
  };

  useLayoutEffect(() => {
    if (ref.current) {
      setDimensions(ref);
      window.addEventListener('resize', setDimensions);

      return () => {
        window.removeEventListener('resize', setDimensions);
      };
    }
    return () => {};
  });

  return [height, width];
};

export default useDimensions;
