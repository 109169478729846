import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSkillBadge = styled.span`
  box-sizing: border-box;
  height: 1.3em;
  min-height: 1.3em;
  padding-left: 8px;
  padding-right: 8px;
  margin: 3px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  font-size: 1em;
  background-color: ${props => props.theme.guide.lighten(0.1).string()};
  color: ${props => props.theme.foreground.string()};
`;

const SkillBadge = ({ className = '', children = null }) => {
  return <StyledSkillBadge className={className}>{children}</StyledSkillBadge>;
};

SkillBadge.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default SkillBadge;
