import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { HOVER_COLOR_EASE_IN } from '../../../Constants/TransitionCurves';

const StyledPreviewWindow = styled.div`
  overflow: hidden;

  border-color: ${props => props.theme.foregroundIdle.string()};
  border-radius: 40px;
  border-style: solid;
  border-width: ${props => (props.showBorder ? '5px' : '0px')};

  height: auto;
  max-width: 800px;
  transition: ${HOVER_COLOR_EASE_IN};

  ${props =>
    props.supportHover
      ? `
      :hover {
        border-color: ${props.hoverBorderColor};
        cursor: pointer;
      }
    `
      : ''}
`;

const PreviewWindow = ({
  className = '',
  children = null,
  hoverBorderColor = 'rgb(0,0,0,1)',

  showBorder = false,
  supportHover = true,
}) => {
  return (
    <StyledPreviewWindow
      className={className}
      hoverBorderColor={hoverBorderColor}
      showBorder={showBorder}
      supportHover={supportHover}
    >
      {children}
    </StyledPreviewWindow>
  );
};

PreviewWindow.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),

  hoverBorderColor: PropTypes.string,

  showBorder: PropTypes.bool,
  supportHover: PropTypes.bool,
};

export default PreviewWindow;
