import React, { StrictMode, useCallback, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router } from 'react-router-dom';

import { MIRAGE_PALETTE, getPalette } from '../Constants/Colors';
import { DisplayModeContext } from '../Constants/DisplayModes';

import useI18nProvider, { LanguageContext } from '../Hooks/useI18nProvider';
import useI18nConsumer from '../Hooks/useI18nConsumer';
import useLocalStorage from '../Hooks/useLocalStorage';
import useDisplayMode from '../Hooks/useDisplayMode';

import MainScreen from './Screens/MainScreen';

const DocumentTitleSetter = () => {
  const { t, currentLanguage } = useI18nConsumer();

  useEffect(() => {
    document.title = t('App.htmlTitle');
  }, [currentLanguage, t]);

  return null;
};

const App = () => {
  const { currentLanguage, handleLanguageChange } = useI18nProvider();
  const displayMode = useDisplayMode();
  const [paletteId, setPaletteId] = useLocalStorage('palette', MIRAGE_PALETTE);

  const handlePaletteIdChange = useCallback(
    newPaletteId => {
      setPaletteId(newPaletteId);
    },
    [setPaletteId]
  );

  return (
    <StrictMode>
      <Router>
        <DisplayModeContext.Provider value={displayMode}>
          <ThemeProvider theme={getPalette(paletteId)}>
            <LanguageContext.Provider value={currentLanguage}>
              <DocumentTitleSetter />
              <MainScreen
                onPaletteIdChange={handlePaletteIdChange}
                onLanguageChange={handleLanguageChange}
              />
            </LanguageContext.Provider>
          </ThemeProvider>
        </DisplayModeContext.Provider>
      </Router>
    </StrictMode>
  );
};

export default App;
