import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledBlurb = styled.p`
  font-size: 1.35em;
  color: ${props => props.theme.foreground.string()};
  margin: 10.8px 0;
`;

const Blurb = ({ className = '', children = null }) => {
  return <StyledBlurb className={className}>{children}</StyledBlurb>;
};

Blurb.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default Blurb;
