import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring';

import { DisplayModeContext, MOBILE } from '../../../Constants/DisplayModes';

import useI18nConsumer from '../../../Hooks/useI18nConsumer';
import useDimensions from '../../../Hooks/useDimensions';

import LoadingIcon from '../../Icons/LoadingIcon';
import SocialMediaMenu from '../../NavMenus/SocialMediaMenu';
import NameHeader from './NameHeader';

const LoadingWrapper = styled(animated.div)`
  position: absolute;

  height: 100%;
  width: 100%;
  max-height: 100vh;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  color: ${props => props.theme.foreground.string()};
  pointer-events: none;
`;

const LoadingText = styled.div`
  margin-bottom: 15px;

  font-size: 1.75em;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: ${props => (props.displayMode === MOBILE ? '15px' : '25px')};
`;

const PageContent = styled(animated.article)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const StyledPaddedPage = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  min-height: 100%;
  width: 100%;
  box-sizing: border-box;
`;

const PaddedPage = ({ className = '', children = null, loadOnMount = true, loaded = false }) => {
  const { t } = useI18nConsumer();
  const displayMode = useContext(DisplayModeContext);
  const [mounted, setMounted] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const showLoadingTimeout = useRef(null);

  const fadeInContent = (loadOnMount && mounted) || (!loadOnMount && mounted && loaded);
  const fadeInLoading = !fadeInContent && showLoading;

  const socialMediaMenuRef = useRef();
  const socialMediaMenuHeight = useDimensions(socialMediaMenuRef)[0];

  const fadeInContentSpring = useSpring({
    opacity: fadeInContent ? 1 : 0,
  });

  const fadeInLoadingSpring = useSpring({
    opacity: fadeInLoading ? 1 : 0,
  });

  useEffect(() => setMounted(true), []);

  useEffect(() => {
    let didCancel = false;

    showLoadingTimeout.current = setTimeout(() => {
      if (!didCancel) {
        setShowLoading(true);
      }
    }, 1000);

    return () => {
      didCancel = true;
    };
  }, []);

  const renderLoading = () => {
    return (
      <LoadingWrapper style={fadeInLoadingSpring}>
        <LoadingText>{t('loading')}</LoadingText>
        <LoadingIcon />
      </LoadingWrapper>
    );
  };

  return (
    <StyledPaddedPage className={className} displayMode={displayMode}>
      <ContentWrapper>
        <NameHeader />
        <PageContent style={fadeInContentSpring}>{children}</PageContent>
        {displayMode === MOBILE && <div style={{ height: socialMediaMenuHeight }} />}
      </ContentWrapper>
      {renderLoading()}
      <SocialMediaMenu ref={socialMediaMenuRef} />
    </StyledPaddedPage>
  );
};

PaddedPage.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),

  loadOnMount: PropTypes.bool,
  loaded: PropTypes.bool,
};

export default PaddedPage;
