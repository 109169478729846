import React, { useContext, useEffect, useRef } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Link } from 'react-router-dom';
import Color from 'color';

import ParticlesCanvas from '../../../Libs/Particles/ParticlesCanvas';

import { toVector } from '../../../Constants/Colors';
import { WorkRoute } from '../../../Constants/Routes';
import { DisplayModeContext, MOBILE } from '../../../Constants/DisplayModes';

import useI18nConsumer from '../../../Hooks/useI18nConsumer';

import PageHeader from '../Generic/PageHeader';
import PaddedPage from '../Generic/PaddedPage';
import Blurb from '../Generic/Blurb';
import Portlet, { PortletTitle, PortletContent } from '../../Portlets/Generic/Portlet';
import UserControl from '../../Inputs/Generic/UserControl';
import FlatButton from '../../Inputs/Generic/FlatButton';
import PreviewWindow from '../../Inputs/Generic/PreviewWindow';

const StyledParticlesPage = styled(PaddedPage)`
  color: ${props => props.theme.foregroundWork.string()};
`;

const CanvasRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
`;

const StyledPreviewWindow = styled(PreviewWindow)`
  max-width: 500px;
`;

const Canvas = styled.canvas`
  height: 100%;
  width: 100%;
  max-width: 500px;
  border-radius: 40px;
`;

const StyledBlurb = styled(Blurb)`
  margin: 20px 0px;
`;

const StyledPortlet = styled(Portlet)`
  ${PortletTitle} {
    color: ${props => props.theme.foregroundWork.string()};
  }
  ${PortletContent} {
    color: ${props => props.theme.foregroundIdle.string()};
  }
`;

const StyledButtonWrapper = styled(Link)`
  align-self: flex-start;
`;

const ParticlesPage = () => {
  const { t } = useI18nConsumer();
  const theme = useContext(ThemeContext);
  const displayMode = useContext(DisplayModeContext);

  const canvasRef = useRef();
  const particlesCanvasRef = useRef();

  useEffect(() => {
    const initialize = async () => {
      particlesCanvasRef.current = new ParticlesCanvas({
        canvas: canvasRef.current,
        clearColor: [...toVector(theme.background), 1],
        floorColor: toVector(theme.foregroundHome),
        colors: Object.values(theme)
          .filter(val => val instanceof Color)
          .map(val => toVector(val)),
      });

      const particlesCanvas = particlesCanvasRef.current;

      await particlesCanvas.initialize();

      particlesCanvas.tick();
    };

    initialize();

    return () => {
      const particlesCanvas = particlesCanvasRef.current;
      particlesCanvas.teardown();
    };
  }, [theme]);

  const renderBackButton = () => {
    const primaryColor = theme.foregroundWork.string();
    return (
      <StyledButtonWrapper to={WorkRoute}>
        <FlatButton primaryColor={primaryColor}>{t('back')}</FlatButton>
      </StyledButtonWrapper>
    );
  };

  const renderCanvas = () => {
    return (
      <CanvasRow>
        <StyledPreviewWindow showBorder={false} supportHover={false}>
          <Canvas
            ref={canvasRef}
            width="800"
            height="600"
            onClick={() => {
              particlesCanvasRef.current.addBalls();
            }}
          />
        </StyledPreviewWindow>
      </CanvasRow>
    );
  };

  const renderDescription = () => {
    return <StyledBlurb>{t('ParticlesPage.blurb')}</StyledBlurb>;
  };

  const renderControls = () => {
    const controls = [
      {
        id: 'orbitKeys',
        contentLeft: 'w, a, s, d',
        contentRight: t('ParticlesPage.controlDesc.orbitCamera'),
      },
      {
        id: 'addBallsKeys',
        contentLeft: t('ParticlesPage.controlDesc.leftClick'),
        contentRight: t('ParticlesPage.controlDesc.addBalls'),
      },
      {
        id: 'clearBallsKeys',
        contentLeft: 'c',
        contentRight: t('ParticlesPage.controlDesc.clearBalls'),
      },
      {
        id: 'resetKeys',
        contentLeft: 'r',
        contentRight: t('ParticlesPage.controlDesc.resetCamera'),
      },
    ];

    const title =
      displayMode !== MOBILE
        ? t('ParticlesPage.controls')
        : t('ParticlesPage.controlsMobileComingSoon');
    return (
      <StyledPortlet title={title}>
        {controls.map(control => {
          const { id, contentLeft, contentRight } = control;
          return <UserControl key={id} contentLeft={contentLeft} contentRight={contentRight} />;
        })}
      </StyledPortlet>
    );
  };

  return (
    <StyledParticlesPage>
      <PageHeader title={t('ParticlesPage.particleSystem')} />
      {renderBackButton()}
      {renderCanvas()}
      {renderDescription()}
      {renderControls()}
    </StyledParticlesPage>
  );
};

export default ParticlesPage;
