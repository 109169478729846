import { createContext, useCallback, useMemo } from 'react';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import enStrings from '../Locales/en/strings.json';
import jaStrings from '../Locales/ja/strings.json';

import useLocalStorage from './useLocalStorage';

const resources = {
  en: {
    translation: enStrings,
  },
  ja: {
    translation: jaStrings,
  },
};

const whitelist = ['en', 'ja'];

i18next.use(initReactI18next).init({
  resources,
  fallbackLng: 'en',
  whitelist,
  nonExplicitWhitelist: true,
});

const pureLanguageFromCode = localeCode => {
  if (!localeCode || localeCode.indexOf('-') < 0) {
    return localeCode;
  }

  return localeCode.split('-')[0];
};

const allowedLanguage = lng => whitelist.includes(pureLanguageFromCode(lng));

const detectionOptions = {
  order: ['querystring', 'navigator', 'htmlTag'],
  caches: [],
  lookupQuerystring: 'lng',
};

const getCurrentLanguage = storedLanguage => {
  try {
    if (storedLanguage && allowedLanguage(storedLanguage)) {
      return storedLanguage;
    }

    const detector = new LanguageDetector();
    const { order: detectionOrder } = detectionOptions;
    const { detectors } = detector;

    for (let i = 0; i < detectionOrder.length; i += 1) {
      const detectionType = detectionOrder[i];
      const foundLanguages = detectors[detectionType].lookup(detectionOptions);

      if (typeof foundLanguages === 'string' && allowedLanguage(foundLanguages)) {
        return foundLanguages;
      }

      if (Array.isArray(foundLanguages)) {
        for (let j = 0; j < foundLanguages.length; j += 1) {
          const foundLanguage = foundLanguages[j];
          if (typeof foundLanguage === 'string' && allowedLanguage(foundLanguage)) {
            return foundLanguage;
          }
        }
      }
    }

    return i18next.options.fallbackLng;
  } catch (err) {
    console.error(err);
    return 'en';
  }
};

const useI18nProvider = () => {
  const [storedLanguage, setStoredLanguage] = useLocalStorage('lng', null);

  const currentLanguage = useMemo(() => getCurrentLanguage(storedLanguage), [storedLanguage]);

  const handleLanguageChange = useCallback(
    newLanguage => {
      setStoredLanguage(newLanguage);
    },
    [setStoredLanguage]
  );

  return {
    currentLanguage: pureLanguageFromCode(currentLanguage),
    handleLanguageChange,
  };
};

export const LanguageContext = createContext('en');

export const i18n = i18next;

export default useI18nProvider;
