import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { PALETTE_EASE_IN } from '../../Constants/TransitionCurves';

const StyledSpaceBackground = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: ${props => props.theme.spaceBackground.string()};
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: right top;
  opacity: ${props => (props.show ? 1 : 0)};
  transition: ${PALETTE_EASE_IN};
`;

const SpaceBackground = ({ show = false, url = '' }) => {
  const style = {};

  if (url) {
    style.backgroundImage = url;
  }

  return <StyledSpaceBackground style={style} show={show} />;
};

SpaceBackground.propTypes = {
  show: PropTypes.bool,
  url: PropTypes.string,
};

export default SpaceBackground;
