import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledUSFlagIcon = styled.svg`
  enable-background: new 0 0 512 512;
`;

const GreyRect = styled.rect`
  fill: #f5f5f5;
`;

const GreyPath = styled.path`
  fill: #f5f5f5;
`;

const RedRect = styled.rect`
  fill: #ff4b55;
`;

const RedPath = styled.path`
  fill: #ff4b55;
`;

const BluePath = styled.path`
  fill: #41479b;
`;

const USFlagIcon = ({ className = '' }) => {
  return (
    <StyledUSFlagIcon
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <g>
        <GreyRect y="118.15" width="512" height="39.38" />
        <GreyPath d="M485.264,39.385H26.736C17.028,50.773,9.557,64.126,5.013,78.77h501.974   C502.443,64.126,494.972,50.773,485.264,39.385z" />
        <GreyRect y="196.92" width="512" height="39.38" />
        <GreyRect y="275.69" width="512" height="39.38" />
        <GreyRect y="354.46" width="512" height="39.38" />
        <GreyPath d="M506.987,433.231H5.013c4.543,14.644,12.015,27.996,21.723,39.384h458.527   C494.972,461.227,502.443,447.875,506.987,433.231z" />
      </g>
      <g>
        <RedPath d="M400,0H112C77.852,0,47.279,15.287,26.736,39.385h458.527C464.721,15.287,434.148,0,400,0z" />
        <RedRect y="157.54" width="512" height="39.38" />
        <RedPath d="M512,112c0-11.571-1.755-22.731-5.013-33.23H5.013C1.755,89.269,0,100.429,0,112v6.154h512V112z" />
        <RedRect y="236.31" width="512" height="39.38" />
        <RedPath d="M512,400v-6.154H0V400c0,11.571,1.755,22.731,5.013,33.231h501.974   C510.245,422.731,512,411.571,512,400z" />
        <RedPath d="M485.264,472.615H26.736C47.279,496.713,77.852,512,112,512h288   C434.148,512,464.721,496.713,485.264,472.615z" />
        <RedRect y="315.08" width="512" height="39.38" />
      </g>
      <BluePath d="M275.692,0H112C50.144,0,0,50.144,0,112v163.692h275.692V0z" />
      <g>
        <GreyPath d="M29.745,43.063c-0.262,0.829,0.681,1.515,1.389,1.009l5.463-3.904l5.463,3.904   c0.707,0.506,1.651-0.18,1.389-1.009l-2.025-6.402l5.402-3.989c0.699-0.517,0.339-1.626-0.531-1.633l-6.714-0.052l-1.146-3.436   c-2.878,2.509-5.623,5.165-8.23,7.952l1.567,1.158L29.745,43.063z" />
        <GreyPath d="M37.455,77.755l2.124,6.37l6.714,0.052c0.869,0.007,1.23,1.116,0.531,1.633l-5.402,3.989   l2.025,6.402c0.262,0.829-0.681,1.514-1.389,1.009l-5.463-3.905l-5.463,3.905c-0.707,0.506-1.651-0.18-1.389-1.009l2.025-6.402   l-5.402-3.989c-0.699-0.516-0.339-1.626,0.53-1.633l6.714-0.052l2.124-6.37C36.013,76.93,37.18,76.93,37.455,77.755z" />
        <GreyPath d="M37.455,136.688l2.124,6.37l6.714,0.052c0.869,0.007,1.23,1.116,0.531,1.633l-5.402,3.989   l2.025,6.402c0.262,0.829-0.681,1.514-1.389,1.009l-5.463-3.904l-5.463,3.904c-0.707,0.505-1.651-0.18-1.389-1.009l2.025-6.402   l-5.402-3.989c-0.699-0.516-0.339-1.626,0.53-1.633l6.714-0.052l2.124-6.37C36.013,135.863,37.18,135.863,37.455,136.688z" />
        <GreyPath d="M37.455,190.834l2.124,6.37l6.714,0.052c0.869,0.007,1.23,1.116,0.531,1.633l-5.402,3.989   l2.025,6.402c0.262,0.829-0.681,1.514-1.389,1.009l-5.463-3.905l-5.463,3.905c-0.707,0.505-1.651-0.18-1.389-1.009l2.025-6.402   l-5.402-3.989c-0.699-0.516-0.339-1.626,0.53-1.633l6.714-0.052l2.124-6.37C36.013,190.009,37.18,190.009,37.455,190.834z" />
        <GreyPath d="M37.455,240.106l2.124,6.37l6.714,0.052c0.869,0.007,1.23,1.116,0.531,1.633l-5.402,3.989   l2.025,6.402c0.262,0.829-0.681,1.514-1.389,1.009l-5.463-3.904l-5.463,3.904c-0.707,0.505-1.651-0.18-1.389-1.009l2.025-6.402   l-5.402-3.989c-0.699-0.516-0.339-1.626,0.53-1.633l6.714-0.052l2.124-6.37C36.013,239.281,37.18,239.281,37.455,240.106z" />
        <GreyPath d="M63.318,49.781l2.124,6.37l6.714,0.052c0.869,0.007,1.23,1.116,0.531,1.632l-5.402,3.989   l2.025,6.402c0.262,0.829-0.681,1.515-1.389,1.009L62.46,65.33l-5.463,3.905c-0.707,0.506-1.651-0.18-1.389-1.009l2.025-6.402   l-5.401-3.989c-0.699-0.516-0.339-1.626,0.53-1.632l6.714-0.052l2.124-6.37C61.876,48.956,63.043,48.956,63.318,49.781z" />
        <GreyPath d="M63.318,108.714l2.124,6.37l6.714,0.052c0.869,0.007,1.23,1.116,0.531,1.633l-5.402,3.989   l2.025,6.402c0.262,0.829-0.681,1.515-1.389,1.009l-5.463-3.905l-5.463,3.905c-0.707,0.506-1.651-0.18-1.389-1.009l2.025-6.402   l-5.401-3.989c-0.699-0.516-0.339-1.626,0.53-1.633l6.714-0.052l2.124-6.37C61.876,107.889,63.043,107.889,63.318,108.714z" />
        <GreyPath d="M63.318,164.705l2.124,6.37l6.714,0.052c0.869,0.007,1.23,1.116,0.531,1.633l-5.402,3.989   l2.025,6.402c0.262,0.829-0.681,1.514-1.389,1.009l-5.463-3.905l-5.463,3.905c-0.707,0.506-1.651-0.18-1.389-1.009l2.025-6.402   l-5.401-3.989c-0.699-0.516-0.339-1.626,0.53-1.633l6.714-0.052l2.124-6.37C61.876,163.881,63.043,163.881,63.318,164.705z" />
        <GreyPath d="M63.318,214.987l2.124,6.37l6.714,0.052c0.869,0.007,1.23,1.116,0.531,1.633l-5.402,3.989   l2.025,6.402c0.262,0.829-0.681,1.515-1.389,1.009l-5.463-3.904l-5.463,3.904c-0.707,0.506-1.651-0.18-1.389-1.009l2.025-6.402   l-5.401-3.989c-0.699-0.516-0.339-1.626,0.53-1.633l6.714-0.052l2.124-6.37C61.876,214.162,63.043,214.162,63.318,214.987z" />
        <GreyPath d="M89.181,24.618l2.124,6.37l6.714,0.052c0.869,0.007,1.23,1.116,0.531,1.632l-5.402,3.989   l2.025,6.402c0.262,0.829-0.681,1.514-1.389,1.009l-5.463-3.905l-5.463,3.905c-0.707,0.506-1.651-0.18-1.389-1.009l2.025-6.402   l-5.401-3.989c-0.699-0.516-0.339-1.626,0.53-1.632l6.714-0.052l2.124-6.37C87.739,23.794,88.906,23.794,89.181,24.618z" />
        <GreyPath d="M89.181,77.755l2.124,6.37l6.714,0.052c0.869,0.007,1.23,1.116,0.531,1.633l-5.402,3.989   l2.025,6.402c0.262,0.829-0.681,1.514-1.389,1.009l-5.463-3.905l-5.463,3.905c-0.707,0.506-1.651-0.18-1.389-1.009l2.025-6.402   l-5.401-3.989c-0.699-0.516-0.339-1.626,0.53-1.633l6.714-0.052l2.124-6.37C87.739,76.93,88.906,76.93,89.181,77.755z" />
        <GreyPath d="M89.181,136.688l2.124,6.37l6.714,0.052c0.869,0.007,1.23,1.116,0.531,1.633l-5.402,3.989   l2.025,6.402c0.262,0.829-0.681,1.514-1.389,1.009l-5.463-3.904l-5.463,3.904c-0.707,0.505-1.651-0.18-1.389-1.009l2.025-6.402   l-5.401-3.989c-0.699-0.516-0.339-1.626,0.53-1.633l6.714-0.052l2.124-6.37C87.739,135.863,88.906,135.863,89.181,136.688z" />
        <GreyPath d="M89.181,190.834l2.124,6.37l6.714,0.052c0.869,0.007,1.23,1.116,0.531,1.633l-5.402,3.989   l2.025,6.402c0.262,0.829-0.681,1.514-1.389,1.009l-5.463-3.905l-5.463,3.905c-0.707,0.505-1.651-0.18-1.389-1.009l2.025-6.402   l-5.401-3.989c-0.699-0.516-0.339-1.626,0.53-1.633l6.714-0.052l2.124-6.37C87.739,190.009,88.906,190.009,89.181,190.834z" />
        <GreyPath d="M89.181,240.106l2.124,6.37l6.714,0.052c0.869,0.007,1.23,1.116,0.531,1.633l-5.402,3.989   l2.025,6.402c0.262,0.829-0.681,1.514-1.389,1.009l-5.463-3.904l-5.463,3.904c-0.707,0.505-1.651-0.18-1.389-1.009l2.025-6.402   l-5.401-3.989c-0.699-0.516-0.339-1.626,0.53-1.633l6.714-0.052l2.124-6.37C87.739,239.281,88.906,239.281,89.181,240.106z" />
        <GreyPath d="M115.044,49.781l2.124,6.37l6.714,0.052c0.869,0.007,1.23,1.116,0.53,1.632l-5.401,3.989   l2.025,6.402c0.262,0.829-0.681,1.515-1.389,1.009l-5.463-3.905l-5.463,3.905c-0.707,0.506-1.651-0.18-1.389-1.009l2.025-6.402   l-5.402-3.989c-0.699-0.516-0.339-1.626,0.531-1.632l6.714-0.052l2.124-6.37C113.603,48.956,114.769,48.956,115.044,49.781z" />
        <GreyPath d="M115.044,108.714l2.124,6.37l6.714,0.052c0.869,0.007,1.23,1.116,0.53,1.633l-5.401,3.989   l2.025,6.402c0.262,0.829-0.681,1.515-1.389,1.009l-5.463-3.905l-5.463,3.905c-0.707,0.506-1.651-0.18-1.389-1.009l2.025-6.402   l-5.402-3.989c-0.699-0.516-0.339-1.626,0.531-1.633l6.714-0.052l2.124-6.37C113.603,107.889,114.769,107.889,115.044,108.714z" />
        <GreyPath d="M115.044,164.705l2.124,6.37l6.714,0.052c0.869,0.007,1.23,1.116,0.53,1.633l-5.401,3.989   l2.025,6.402c0.262,0.829-0.681,1.514-1.389,1.009l-5.463-3.905l-5.463,3.905c-0.707,0.506-1.651-0.18-1.389-1.009l2.025-6.402   l-5.402-3.989c-0.699-0.516-0.339-1.626,0.531-1.633l6.714-0.052l2.124-6.37C113.603,163.881,114.769,163.881,115.044,164.705z" />
        <GreyPath d="M115.044,214.987l2.124,6.37l6.714,0.052c0.869,0.007,1.23,1.116,0.53,1.633l-5.401,3.989   l2.025,6.402c0.262,0.829-0.681,1.515-1.389,1.009l-5.463-3.904l-5.463,3.904c-0.707,0.506-1.651-0.18-1.389-1.009l2.025-6.402   l-5.402-3.989c-0.699-0.516-0.339-1.626,0.531-1.633l6.714-0.052l2.124-6.37C113.603,214.162,114.769,214.162,115.044,214.987z" />
        <GreyPath d="M140.907,24.618l2.124,6.37l6.714,0.052c0.869,0.007,1.23,1.116,0.53,1.632l-5.401,3.989   l2.025,6.402c0.262,0.829-0.681,1.514-1.389,1.009l-5.463-3.905l-5.463,3.905c-0.707,0.506-1.651-0.18-1.389-1.009l2.025-6.402   l-5.401-3.989c-0.699-0.516-0.339-1.626,0.531-1.632l6.714-0.052l2.124-6.37C139.466,23.794,140.632,23.794,140.907,24.618z" />
        <GreyPath d="M140.907,77.755l2.124,6.37l6.714,0.052c0.869,0.007,1.23,1.116,0.53,1.633l-5.401,3.989L146.9,96.2   c0.262,0.829-0.681,1.514-1.389,1.009l-5.463-3.905l-5.463,3.905c-0.707,0.506-1.651-0.18-1.389-1.009l2.025-6.402l-5.401-3.989   c-0.699-0.516-0.339-1.626,0.531-1.633l6.714-0.052l2.124-6.37C139.466,76.93,140.632,76.93,140.907,77.755z" />
        <GreyPath d="M140.907,136.688l2.124,6.37l6.714,0.052c0.869,0.007,1.23,1.116,0.53,1.633l-5.401,3.989   l2.025,6.402c0.262,0.829-0.681,1.514-1.389,1.009l-5.463-3.904l-5.463,3.904c-0.707,0.505-1.651-0.18-1.389-1.009l2.025-6.402   l-5.401-3.989c-0.699-0.516-0.339-1.626,0.531-1.633l6.714-0.052l2.124-6.37C139.466,135.863,140.632,135.863,140.907,136.688z" />
        <GreyPath d="M140.907,190.834l2.124,6.37l6.714,0.052c0.869,0.007,1.23,1.116,0.53,1.633l-5.401,3.989   l2.025,6.402c0.262,0.829-0.681,1.514-1.389,1.009l-5.463-3.905l-5.463,3.905c-0.707,0.505-1.651-0.18-1.389-1.009l2.025-6.402   l-5.401-3.989c-0.699-0.516-0.339-1.626,0.531-1.633l6.714-0.052l2.124-6.37C139.466,190.009,140.632,190.009,140.907,190.834z" />
        <GreyPath d="M140.907,240.106l2.124,6.37l6.714,0.052c0.869,0.007,1.23,1.116,0.53,1.633l-5.401,3.989   l2.025,6.402c0.262,0.829-0.681,1.514-1.389,1.009l-5.463-3.904l-5.463,3.904c-0.707,0.505-1.651-0.18-1.389-1.009l2.025-6.402   l-5.401-3.989c-0.699-0.516-0.339-1.626,0.531-1.633l6.714-0.052l2.124-6.37C139.466,239.281,140.632,239.281,140.907,240.106z" />
        <GreyPath d="M166.77,49.781l2.124,6.37l6.714,0.052c0.869,0.007,1.23,1.116,0.53,1.632l-5.402,3.989l2.025,6.402   c0.262,0.829-0.682,1.515-1.389,1.009l-5.463-3.905l-5.463,3.905c-0.707,0.506-1.651-0.18-1.389-1.009l2.025-6.402l-5.401-3.989   c-0.699-0.516-0.339-1.626,0.53-1.632l6.714-0.052l2.124-6.37C165.329,48.956,166.495,48.956,166.77,49.781z" />
        <GreyPath d="M166.77,108.714l2.124,6.37l6.714,0.052c0.869,0.007,1.23,1.116,0.53,1.633l-5.402,3.989   l2.025,6.402c0.262,0.829-0.682,1.515-1.389,1.009l-5.463-3.905l-5.463,3.905c-0.707,0.506-1.651-0.18-1.389-1.009l2.025-6.402   l-5.401-3.989c-0.699-0.516-0.339-1.626,0.53-1.633l6.714-0.052l2.124-6.37C165.329,107.889,166.495,107.889,166.77,108.714z" />
        <GreyPath d="M166.77,164.705l2.124,6.37l6.714,0.052c0.869,0.007,1.23,1.116,0.53,1.633l-5.402,3.989   l2.025,6.402c0.262,0.829-0.682,1.514-1.389,1.009l-5.463-3.905l-5.463,3.905c-0.707,0.506-1.651-0.18-1.389-1.009l2.025-6.402   l-5.401-3.989c-0.699-0.516-0.339-1.626,0.53-1.633l6.714-0.052l2.124-6.37C165.329,163.881,166.495,163.881,166.77,164.705z" />
        <GreyPath d="M166.77,214.987l2.124,6.37l6.714,0.052c0.869,0.007,1.23,1.116,0.53,1.633l-5.402,3.989   l2.025,6.402c0.262,0.829-0.682,1.515-1.389,1.009l-5.463-3.904l-5.463,3.904c-0.707,0.506-1.651-0.18-1.389-1.009l2.025-6.402   l-5.401-3.989c-0.699-0.516-0.339-1.626,0.53-1.633l6.714-0.052l2.124-6.37C165.329,214.162,166.495,214.162,166.77,214.987z" />
        <GreyPath d="M192.633,24.618l2.124,6.37l6.714,0.052c0.869,0.007,1.23,1.116,0.53,1.632l-5.402,3.989   l2.025,6.402c0.262,0.829-0.682,1.514-1.389,1.009l-5.463-3.905l-5.463,3.905c-0.707,0.506-1.651-0.18-1.389-1.009l2.025-6.402   l-5.402-3.989c-0.699-0.516-0.339-1.626,0.531-1.632l6.714-0.052l2.124-6.37C191.192,23.794,192.358,23.794,192.633,24.618z" />
        <GreyPath d="M192.633,77.755l2.124,6.37l6.714,0.052c0.869,0.007,1.23,1.116,0.53,1.633l-5.402,3.989   l2.025,6.402c0.262,0.829-0.682,1.514-1.389,1.009l-5.463-3.905l-5.463,3.905c-0.707,0.506-1.651-0.18-1.389-1.009l2.025-6.402   l-5.402-3.989c-0.699-0.516-0.339-1.626,0.531-1.633l6.714-0.052l2.124-6.37C191.192,76.93,192.358,76.93,192.633,77.755z" />
        <GreyPath d="M192.633,136.688l2.124,6.37l6.714,0.052c0.869,0.007,1.23,1.116,0.53,1.633l-5.402,3.989   l2.025,6.402c0.262,0.829-0.682,1.514-1.389,1.009l-5.463-3.904l-5.463,3.904c-0.707,0.505-1.651-0.18-1.389-1.009l2.025-6.402   l-5.402-3.989c-0.699-0.516-0.339-1.626,0.531-1.633l6.714-0.052l2.124-6.37C191.192,135.863,192.358,135.863,192.633,136.688z" />
        <GreyPath d="M192.633,190.834l2.124,6.37l6.714,0.052c0.869,0.007,1.23,1.116,0.53,1.633l-5.402,3.989   l2.025,6.402c0.262,0.829-0.682,1.514-1.389,1.009l-5.463-3.905l-5.463,3.905c-0.707,0.505-1.651-0.18-1.389-1.009l2.025-6.402   l-5.402-3.989c-0.699-0.516-0.339-1.626,0.531-1.633l6.714-0.052l2.124-6.37C191.192,190.009,192.358,190.009,192.633,190.834z" />
        <GreyPath d="M192.633,240.106l2.124,6.37l6.714,0.052c0.869,0.007,1.23,1.116,0.53,1.633l-5.402,3.989   l2.025,6.402c0.262,0.829-0.682,1.514-1.389,1.009l-5.463-3.904l-5.463,3.904c-0.707,0.505-1.651-0.18-1.389-1.009l2.025-6.402   l-5.402-3.989c-0.699-0.516-0.339-1.626,0.531-1.633l6.714-0.052l2.124-6.37C191.192,239.281,192.358,239.281,192.633,240.106z" />
        <GreyPath d="M218.496,49.781l2.124,6.37l6.715,0.052c0.869,0.007,1.23,1.116,0.53,1.632l-5.402,3.989   l2.025,6.402c0.262,0.829-0.682,1.515-1.389,1.009l-5.463-3.905l-5.463,3.905c-0.707,0.506-1.651-0.18-1.389-1.009l2.025-6.402   l-5.402-3.989c-0.699-0.516-0.339-1.626,0.53-1.632l6.715-0.052l2.124-6.37C217.055,48.956,218.221,48.956,218.496,49.781z" />
        <GreyPath d="M218.496,108.714l2.124,6.37l6.715,0.052c0.869,0.007,1.23,1.116,0.53,1.633l-5.402,3.989   l2.025,6.402c0.262,0.829-0.682,1.515-1.389,1.009l-5.463-3.905l-5.463,3.905c-0.707,0.506-1.651-0.18-1.389-1.009l2.025-6.402   l-5.402-3.989c-0.699-0.516-0.339-1.626,0.53-1.633l6.715-0.052l2.124-6.37C217.055,107.889,218.221,107.889,218.496,108.714z" />
        <GreyPath d="M218.496,164.705l2.124,6.37l6.715,0.052c0.869,0.007,1.23,1.116,0.53,1.633l-5.402,3.989   l2.025,6.402c0.262,0.829-0.682,1.514-1.389,1.009l-5.463-3.905l-5.463,3.905c-0.707,0.506-1.651-0.18-1.389-1.009l2.025-6.402   l-5.402-3.989c-0.699-0.516-0.339-1.626,0.53-1.633l6.715-0.052l2.124-6.37C217.055,163.881,218.221,163.881,218.496,164.705z" />
        <GreyPath d="M218.496,214.987l2.124,6.37l6.715,0.052c0.869,0.007,1.23,1.116,0.53,1.633l-5.402,3.989   l2.025,6.402c0.262,0.829-0.682,1.515-1.389,1.009l-5.463-3.904l-5.463,3.904c-0.707,0.506-1.651-0.18-1.389-1.009l2.025-6.402   l-5.402-3.989c-0.699-0.516-0.339-1.626,0.53-1.633l6.715-0.052l2.124-6.37C217.055,214.162,218.221,214.162,218.496,214.987z" />
        <GreyPath d="M244.359,24.618l2.124,6.37l6.714,0.052c0.869,0.007,1.23,1.116,0.531,1.632l-5.402,3.989   l2.025,6.402c0.262,0.829-0.682,1.514-1.389,1.009l-5.463-3.905l-5.463,3.905c-0.707,0.506-1.651-0.18-1.389-1.009l2.025-6.402   l-5.402-3.989c-0.699-0.516-0.339-1.626,0.53-1.632l6.714-0.052l2.124-6.37C242.918,23.794,244.084,23.794,244.359,24.618z" />
        <GreyPath d="M244.359,77.755l2.124,6.37l6.714,0.052c0.869,0.007,1.23,1.116,0.531,1.633l-5.402,3.989   l2.025,6.402c0.262,0.829-0.682,1.514-1.389,1.009l-5.463-3.905l-5.463,3.905c-0.707,0.506-1.651-0.18-1.389-1.009l2.025-6.402   l-5.402-3.989c-0.699-0.516-0.339-1.626,0.53-1.633l6.714-0.052l2.124-6.37C242.918,76.93,244.084,76.93,244.359,77.755z" />
        <GreyPath d="M244.359,136.688l2.124,6.37l6.714,0.052c0.869,0.007,1.23,1.116,0.531,1.633l-5.402,3.989   l2.025,6.402c0.262,0.829-0.682,1.514-1.389,1.009l-5.463-3.904l-5.463,3.904c-0.707,0.505-1.651-0.18-1.389-1.009l2.025-6.402   l-5.402-3.989c-0.699-0.516-0.339-1.626,0.53-1.633l6.714-0.052l2.124-6.37C242.918,135.863,244.084,135.863,244.359,136.688z" />
        <GreyPath d="M244.359,190.834l2.124,6.37l6.714,0.052c0.869,0.007,1.23,1.116,0.531,1.633l-5.402,3.989   l2.025,6.402c0.262,0.829-0.682,1.514-1.389,1.009l-5.463-3.905l-5.463,3.905c-0.707,0.505-1.651-0.18-1.389-1.009l2.025-6.402   l-5.402-3.989c-0.699-0.516-0.339-1.626,0.53-1.633l6.714-0.052l2.124-6.37C242.918,190.009,244.084,190.009,244.359,190.834z" />
        <GreyPath d="M244.359,240.106l2.124,6.37l6.714,0.052c0.869,0.007,1.23,1.116,0.531,1.633l-5.402,3.989   l2.025,6.402c0.262,0.829-0.682,1.514-1.389,1.009l-5.463-3.904l-5.463,3.904c-0.707,0.505-1.651-0.18-1.389-1.009l2.025-6.402   l-5.402-3.989c-0.699-0.516-0.339-1.626,0.53-1.633l6.714-0.052l2.124-6.37C242.918,239.281,244.084,239.281,244.359,240.106z" />
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </StyledUSFlagIcon>
  );
};

USFlagIcon.propTypes = {
  className: PropTypes.string,
};

export default USFlagIcon;
