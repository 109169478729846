import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { Link } from 'react-router-dom';

import { DisplayModeContext, MOBILE, TABLET } from '../../Constants/DisplayModes';
import { calculateForeground } from '../../Constants/Colors';
import { HOVER_SIZE_EASE_IN } from '../../Constants/TransitionCurves';

import HighlightButton, { ButtonLogo } from './Generic/HighlightButton';

const StyledWorkButton = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  color: ${props => props.theme.foreground.string()};
  ${ButtonLogo} {
    padding: 0px;
  }
  font-size: 1.3em;
`;

const StyledAnchor = styled.a`
  text-decoration: none;

  margin-bottom: 10px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;

  margin-bottom: 10px;
`;

const IconWrapper = styled.div`
  border-radius: 40px;

  ${props => {
    return props.bgImg
      ? `
      background-image: url('${props.bgImg}');
      background-repeat: no-repeat;
      background-size: cover;
    `
      : '';
  }}

  ${props => {
    switch (props.displayMode) {
      case MOBILE:
        return 'width: 80px; height 80px;';
      case TABLET:
        return 'width: 100px; height 100px;';
      default:
        return 'width: 140px; height 140px;';
    }
  }};
  transition: ${HOVER_SIZE_EASE_IN};
`;

const WorkButton = ({
  className = '',

  icon = null,
  src = '',
  hoverText = '',
  href = '',
  to = '',
}) => {
  const theme = useContext(ThemeContext);
  const displayMode = useContext(DisplayModeContext);

  const hoverBgColor = theme.foregroundWork;
  const hoverFgColor = calculateForeground(theme, hoverBgColor);

  const renderHighlightButton = () => (
    <HighlightButton
      hoverText={hoverText}
      hoverBgColor={hoverBgColor.toString()}
      hoverFgColor={hoverFgColor.toString()}
    >
      <IconWrapper displayMode={displayMode} bgImg={src}>
        {icon}
      </IconWrapper>
    </HighlightButton>
  );

  const renderLink = () => {
    if (href) {
      return (
        <StyledAnchor href={href} target="_blank" rel="noopener noreferrer">
          {renderHighlightButton()}
        </StyledAnchor>
      );
    }

    return <StyledLink to={to}>{renderHighlightButton()}</StyledLink>;
  };

  return (
    <StyledWorkButton className={className}>
      {renderLink()}
      {hoverText}
    </StyledWorkButton>
  );
};

WorkButton.propTypes = {
  className: PropTypes.string,

  icon: PropTypes.node,
  src: PropTypes.string,
  href: PropTypes.string,
  to: PropTypes.string,
  hoverText: PropTypes.string,
};

export default WorkButton;
