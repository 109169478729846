import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Gitlab } from 'styled-icons/fa-brands/Gitlab';

import IconButton from './Generic/IconButton';

const StyledGitlabIcon = styled(IconButton)`
  cursor: pointer;
`;

const GitlabIcon = ({ className = '', size = 30, onClick = () => {} }) => {
  const renderIcon = () => {
    return <Gitlab size={size} />;
  };

  return <StyledGitlabIcon className={className} renderIcon={renderIcon} onClick={onClick} />;
};

GitlabIcon.propTypes = {
  className: PropTypes.string,

  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
};

export default forwardRef((props, ref) => <GitlabIcon {...props} baseRef={ref} />);
