import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { DisplayModeContext, MOBILE } from '../../../Constants/DisplayModes';
import { HOVER_SIZE_EASE_IN } from '../../../Constants/TransitionCurves';

import useI18nConsumer from '../../../Hooks/useI18nConsumer';

import IconButton from '../../Icons/Generic/IconButton';
import NameIcon from '../../Icons/NameIcon';
import GitlabIcon from '../../Icons/GitlabIcon';
import LinkedInIcon from '../../Icons/LinkedInIcon';

const StyledNameHeader = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
`;

const ContactRow = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: row;
  margin-right: 20px;
`;

const StyledIconButton = styled(IconButton)`
  color: ${props => props.theme.foreground.string()};
  svg {
    transition: ${HOVER_SIZE_EASE_IN};
  }

  :hover {
    svg {
      height: 35px;
      width: 35px;
    }
  }
`;

const StyledLink = styled.a`
  text-decoration: none;
`;

const NameHeader = ({ className = '' }) => {
  const { t } = useI18nConsumer();
  const displayMode = useContext(DisplayModeContext);

  const buttons = [
    {
      id: 'gitlab',
      label: t('NameHeader.gitlab'),
      href: 'https://gitlab.com/aaron.chen',
      renderIcon: () => <GitlabIcon />,
    },
    {
      id: 'linkedin',
      label: t('NameHeader.linkedIn'),
      href: 'https://www.linkedin.com/in/aaron-chen-100/',
      renderIcon: () => <LinkedInIcon />,
    },
  ];

  return (
    <StyledNameHeader className={className}>
      {displayMode !== MOBILE && (
        <ContactRow>
          {buttons.map(button => {
            const { id, ref, label, href, renderIcon } = button;

            const ButtonComponent = <StyledIconButton ref={ref} renderIcon={renderIcon} />;

            return (
              <StyledLink key={id} href={href} aria-label={label} target="_blank" rel="noreferrer">
                {ButtonComponent}
              </StyledLink>
            );
          })}
        </ContactRow>
      )}
      <NameIcon height={displayMode === MOBILE ? 40 : 50} />
    </StyledNameHeader>
  );
};

NameHeader.propTypes = {
  className: PropTypes.string,
};

export default NameHeader;
