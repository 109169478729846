import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';

import { DisplayModeContext, TABLET, MOBILE } from '../../Constants/DisplayModes';
import { HOVER_COLOR_EASE_IN, HOVER_SIZE_EASE_IN } from '../../Constants/TransitionCurves';
import { MIRAGE_PALETTE, calculateForeground, getPalette } from '../../Constants/Colors';

import HighlightButton from './Generic/HighlightButton';

const PalettePreviewWrapper = styled.div`
  border-radius: 40px;
  height: auto;
  width: ${props => {
    switch (props.displayMode) {
      case MOBILE:
        return '';
      case TABLET:
        return '120px';
      default:
        return '160px';
    }
  }};
  min-width: 80px;
  padding-bottom: 100%;

  background-color: ${props => props.backgroundColor};
  transition: ${HOVER_SIZE_EASE_IN};
`;

const PalettePreview = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;

  height: 100%;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;

  transition: ${HOVER_COLOR_EASE_IN};
`;

const PalettePanelPreview = styled.div`
  width: 20%;
  border-radius: 5px;

  margin-right: 5px;

  background-color: ${props => props.backgroundColor};
`;

const PaletteContentPreview = styled.div`
  display: flex;
  flex-direction: ${props => (props.displayMode === MOBILE ? 'row' : 'column')};
  align-items: stretch;
  justify-content: space-evenly;
  box-sizing: border-box;

  flex-grow: 1;
`;

const PaletteButtonPreview = styled.div`
  display: flex;
  flex-grow: 1;
  border-radius: 5px;

  margin: 3px;

  background-color: ${props => props.backgroundColor};
`;

const PaletteButton = ({
  className = '',

  highlighted = false,
  paletteId = MIRAGE_PALETTE,
  hoverText = '',

  onClick = () => {},
}) => {
  const theme = useContext(ThemeContext);
  const displayMode = useContext(DisplayModeContext);

  const palette = getPalette(paletteId);

  const renderPalettePreview = () => {
    const renderContentPreview = () => {
      return (
        <PaletteContentPreview displayMode={displayMode}>
          {displayMode === TABLET ? null : (
            <PaletteButtonPreview backgroundColor={palette.foreground.string()} />
          )}
          <PaletteButtonPreview backgroundColor={palette.foregroundHome.string()} />
          <PaletteButtonPreview backgroundColor={palette.foregroundAbout.string()} />
          <PaletteButtonPreview backgroundColor={palette.foregroundWork.string()} />
          <PaletteButtonPreview backgroundColor={palette.foregroundSkills.string()} />
          {displayMode === TABLET ? null : (
            <PaletteButtonPreview backgroundColor={palette.foregroundSettings.string()} />
          )}
        </PaletteContentPreview>
      );
    };

    return (
      <PalettePreviewWrapper
        displayMode={displayMode}
        backgroundColor={palette.background.string()}
      >
        <PalettePreview>
          {displayMode === MOBILE ? null : (
            <PalettePanelPreview backgroundColor={palette.panel.string()} />
          )}
          {renderContentPreview()}
        </PalettePreview>
      </PalettePreviewWrapper>
    );
  };

  const hoverBgColor = theme.foregroundSettings;
  const hoverFgColor = calculateForeground(theme, hoverBgColor);

  return (
    <HighlightButton
      className={className}
      centerContent={false}
      highlighted={highlighted}
      hoverText={hoverText}
      hoverBgColor={hoverBgColor.toString()}
      hoverFgColor={hoverFgColor.toString()}
      onClick={onClick}
    >
      {renderPalettePreview()}
    </HighlightButton>
  );
};

PaletteButton.propTypes = {
  className: PropTypes.string,

  paletteId: PropTypes.string,
  highlighted: PropTypes.bool,
  hoverText: PropTypes.string,

  onClick: PropTypes.func,
};

export default PaletteButton;
