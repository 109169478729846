import React from 'react';
import PropTypes from 'prop-types';

import useI18nConsumer from '../../Hooks/useI18nConsumer';

import SkillGroupPortlet from './Generic/SkillGroupPortlet';
import JSIcon from '../Icons/Skills/JSIcon';
import ReactIcon from '../Icons/Skills/ReactIcon';
import PreactIcon from '../Icons/Skills/PreactIcon';
import MeteorIcon from '../Icons/Skills/MeteorIcon';
import NodeJSIcon from '../Icons/Skills/NodeJSIcon';
// import WebpackIcon from '../Icons/WebpackIcon';

const JsSkillsPortlet = ({ className = '' }) => {
  const { t } = useI18nConsumer();

  const jsButtons = [
    {
      id: 'javascript',
      icon: <JSIcon />,
      href: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript',
      hoverText: t('JsSkillsPortlet.javascript'),
    },
    {
      id: 'react',
      icon: <ReactIcon />,
      href: 'https://reactjs.org/',
      hoverText: t('JsSkillsPortlet.react'),
    },
    {
      id: 'preact',
      icon: <PreactIcon />,
      href: 'https://preactjs.com/',
      hoverText: t('JsSkillsPortlet.preact'),
    },
    {
      id: 'meteor',
      icon: <MeteorIcon />,
      href: 'https://www.meteor.com/',
      hoverText: t('JsSkillsPortlet.meteor'),
    },
    {
      id: 'nodeJS',
      icon: <NodeJSIcon />,
      href: 'https://nodejs.org/',
      hoverText: t('JsSkillsPortlet.nodeJS'),
    },
    // {
    //   id: 'webpack',
    //   icon: <WebpackIcon />,
    //   href: 'https://webpack.js.org/',
    //   hoverText: t('JsSkillsPortlet.webpack'),
    // },
  ];
  return (
    <SkillGroupPortlet
      className={className}
      title={t('JsSkillsPortlet.javascript')}
      buttons={jsButtons}
    />
  );
};

JsSkillsPortlet.propTypes = {
  className: PropTypes.string,
};

export default JsSkillsPortlet;
