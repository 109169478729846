import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { FlightSimulatorRoute, ParticlesRoute, TeapotRoute } from '../../Constants/Routes';

import useI18nConsumer, { Trans } from '../../Hooks/useI18nConsumer';

import FlightSimulatorPage from './Work/FlightSimulatorPage';
import ParticlesPage from './Work/ParticlesPage';
import TeapotPage from './Work/TeapotPage';

import PageHeader from './Generic/PageHeader';
import PaddedPage from './Generic/PaddedPage';
import PageShadow from './Generic/PageShadow';
import CompaniesPortletContainer from '../Portlets/CompaniesPortletContainer';
import UniversityWorkPortlet from '../Portlets/UniversityWorkPortlet';

const PortletWrapper = styled.div`
  display: flex;
  flex-direction: column;

  flex-grow: 1;
  justify-content: space-between;
`;

const DefaultWorkPage = styled(PaddedPage)`
  color: ${props => props.theme.foregroundWork.string()};
`;

const WorkPage = () => {
  const { t } = useI18nConsumer();
  const match = useRouteMatch();

  const [uniWorkLoaded, setUniWorkLoaded] = useState(false);

  const handleUniWorkLoaded = useCallback(() => setUniWorkLoaded(true), []);

  const renderDefaultWorkPage = () => {
    return (
      <DefaultWorkPage loadOnMount={false} loaded={uniWorkLoaded}>
        <PageHeader title={t('WorkPage.work')} />
        <PortletWrapper>
          <CompaniesPortletContainer />
          <UniversityWorkPortlet onLoad={handleUniWorkLoaded} />
        </PortletWrapper>
      </DefaultWorkPage>
    );
  };

  return (
    <div>
      <PaddedPage>
        <PageShadow>
          <Trans i18nKey="WorkPage.pageShadow" defaults="W<0/>ork" components={[<br />]} />
        </PageShadow>
      </PaddedPage>
      <Switch>
        <Route path={`${match.path}${FlightSimulatorRoute}`}>
          <FlightSimulatorPage />
        </Route>
        <Route path={`${match.path}${ParticlesRoute}`}>
          <ParticlesPage />
        </Route>
        <Route path={`${match.path}${TeapotRoute}`}>
          <TeapotPage />
        </Route>
        <Route>{renderDefaultWorkPage()}</Route>
      </Switch>
    </div>
  );
};

export default WorkPage;
