import React, { useContext, useEffect, useState, useRef } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Link } from 'react-router-dom';

import TeapotCanvas from '../../../Libs/Reflective-Teapot/TeapotCanvas';

import { WorkRoute } from '../../../Constants/Routes';
import { DisplayModeContext, MOBILE } from '../../../Constants/DisplayModes';

import useI18nConsumer from '../../../Hooks/useI18nConsumer';

import PageHeader from '../Generic/PageHeader';
import PaddedPage from '../Generic/PaddedPage';
import Blurb from '../Generic/Blurb';
import Portlet, { PortletTitle, PortletContent } from '../../Portlets/Generic/Portlet';
import UserControl from '../../Inputs/Generic/UserControl';
import FlatButton from '../../Inputs/Generic/FlatButton';
import Switch from '../../Inputs/Generic/Switch';
import PreviewWindow from '../../Inputs/Generic/PreviewWindow';

const CanvasRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
`;

const StyledPreviewWindow = styled(PreviewWindow)`
  max-width: 500px;
`;

const Canvas = styled.canvas`
  height: 100%;
  width: 100%;
  max-width: 500px;
  border-radius: 40px;
`;

const StyledBlurb = styled(Blurb)`
  margin: 20px 0px;
`;

const StyledPortlet = styled(Portlet)`
  ${PortletTitle} {
    color: ${props => props.theme.foregroundWork.string()};
  }
  ${PortletContent} {
    color: ${props => props.theme.foregroundIdle.string()};
  }
`;

const StyledTeapotPage = styled(PaddedPage)`
  color: ${props => props.theme.foregroundWork.string()};

  ${StyledPortlet}:last-child {
    margin-top: 20px;
  }
`;

const StyledButtonWrapper = styled(Link)`
  align-self: flex-start;
`;

const TeapotPage = () => {
  const { t } = useI18nConsumer();
  const theme = useContext(ThemeContext);
  const displayMode = useContext(DisplayModeContext);

  const canvasRef = useRef();
  const teapotCanvasRef = useRef();

  const [reflect, setReflect] = useState(true);
  const [blinnPhong, setBlinnPhong] = useState(false);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const initialize = async () => {
      teapotCanvasRef.current = new TeapotCanvas({ canvas: canvasRef.current });

      const teapotCanvas = teapotCanvasRef.current;

      await teapotCanvas.initialize();

      setLoaded(true);

      teapotCanvas.tick();
    };

    initialize();

    return () => {
      const teapotCanvas = teapotCanvasRef.current;
      teapotCanvas.teardown();
    };
  }, []);

  useEffect(() => {
    const teapotCanvas = teapotCanvasRef.current;

    teapotCanvas.setState({
      blinnPhong,
      reflect,
    });
  }, [blinnPhong, reflect]);

  const renderBackButton = () => {
    const primaryColor = theme.foregroundWork.string();
    return (
      <StyledButtonWrapper to={WorkRoute}>
        <FlatButton primaryColor={primaryColor}>{t('back')}</FlatButton>
      </StyledButtonWrapper>
    );
  };

  const renderCanvas = () => {
    return (
      <CanvasRow>
        <StyledPreviewWindow showBorder={false} supportHover={false}>
          <Canvas ref={canvasRef} width="800" height="600" />
        </StyledPreviewWindow>
      </CanvasRow>
    );
  };

  const renderDescription = () => {
    return <StyledBlurb>{t('TeapotPage.blurb')}</StyledBlurb>;
  };

  const renderOptions = () => {
    const options = [
      {
        id: 'reflect',
        contentLeft: t('TeapotPage.optionDesc.reflect'),
        contentRight: (
          <Switch
            checked={reflect}
            onChange={() => setReflect(prevReflect => !prevReflect)}
            aria-label={t('TeapotPage.optionDesc.reflect')}
          />
        ),
      },
      {
        id: 'blinnPhong',
        contentLeft: t('TeapotPage.optionDesc.blinnPhong'),
        contentRight: (
          <Switch
            checked={blinnPhong}
            onChange={() => setBlinnPhong(prevBlinnPhong => !prevBlinnPhong)}
            aria-label={t('TeapotPage.optionDesc.blinnPhong')}
          />
        ),
      },
    ];
    return (
      <StyledPortlet title={t('TeapotPage.options')}>
        {options.map(option => {
          const { id, contentLeft, contentRight } = option;

          return <UserControl key={id} contentLeft={contentLeft} contentRight={contentRight} />;
        })}
      </StyledPortlet>
    );
  };

  const renderControls = () => {
    const controls = [
      {
        id: 'orbitKeys',
        contentLeft: 'w, a, s, d',
        contentRight: t('TeapotPage.controlDesc.orbitCamera'),
      },
      {
        id: 'rotateTeapotKeys',
        contentLeft: '←, →',
        contentRight: t('TeapotPage.controlDesc.rotateTeapot'),
      },
      {
        id: 'resetKeys',
        contentLeft: 'r',
        contentRight: t('TeapotPage.controlDesc.resetCamera'),
      },
    ];

    const title =
      displayMode !== MOBILE ? t('TeapotPage.controls') : t('TeapotPage.controlsMobileComingSoon');
    return (
      <StyledPortlet title={title}>
        {controls.map(control => {
          const { id, contentLeft, contentRight } = control;
          return <UserControl key={id} contentLeft={contentLeft} contentRight={contentRight} />;
        })}
      </StyledPortlet>
    );
  };

  return (
    <StyledTeapotPage loadOnMount={false} loaded={loaded}>
      <PageHeader title={t('TeapotPage.utahTeapot')} />
      {renderBackButton()}
      {renderCanvas()}
      {renderDescription()}
      {renderOptions()}
      {renderControls()}
    </StyledTeapotPage>
  );
};

export default TeapotPage;
