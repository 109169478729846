import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Menu2 } from 'styled-icons/remix-line/Menu2';

import IconButton from './Generic/IconButton';

const StyledRightChevronIcon = styled(IconButton)`
  cursor: pointer;
`;

const RightChevronIcon = ({ className = '', spring = {}, size = 30, onClick = () => {} }) => {
  const renderIcon = () => {
    return <Menu2 size={size} />;
  };

  return (
    <StyledRightChevronIcon
      className={className}
      spring={spring}
      renderIcon={renderIcon}
      onClick={onClick}
    />
  );
};

RightChevronIcon.propTypes = {
  className: PropTypes.string,
  spring: PropTypes.objectOf(PropTypes.any),

  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
};

export default RightChevronIcon;
