import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';

import useI18nConsumer from '../../Hooks/useI18nConsumer';

import {
  PALETTE_EASE_IN,
  HOVER_COLOR_EASE_IN,
  HOVER_SIZE_EASE_IN,
} from '../../Constants/TransitionCurves';
import { ContactRoute } from '../../Constants/Routes';

import HamburgerIcon from '../Icons/HamburgerIcon';
import MainLinks from './MainLinks';
import MailIcon from '../Icons/MailIcon';

const StyledTopbarMenu = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 100vw;
  background-color: ${props => props.theme.panel.string()};
  transition: ${PALETTE_EASE_IN};
`;

const StyledHamburgerIcon = styled(HamburgerIcon)`
  transition: ${HOVER_COLOR_EASE_IN};
  color: ${props => props.theme.foregroundIdle.string()};

  svg {
    transition: ${HOVER_SIZE_EASE_IN};
  }

  :hover {
    color: ${props => props.theme.foregroundHamburger.string()};

    svg {
      height: 35px;
      width: 35px;
    }
  }
`;

const Spacer = styled.div`
  flex-grow: 1;
`;

const TopbarMenu = ({ className = '', onHamburgerClick = () => {} }) => {
  const { t } = useI18nConsumer();
  const theme = useContext(ThemeContext);

  const rightButtons = [
    {
      id: 'contact',
      renderIcon: () => <MailIcon />,
      highlightColor: theme.foregroundContact.string(),
      label: t('TopbarMenu.contact'),
      route: ContactRoute,
      showLabel: false,
    },
  ];

  return (
    <StyledTopbarMenu className={className}>
      <StyledHamburgerIcon onClick={onHamburgerClick} />
      <Spacer />
      <MainLinks buttons={rightButtons} />
    </StyledTopbarMenu>
  );
};

TopbarMenu.propTypes = {
  className: PropTypes.string,

  onHamburgerClick: PropTypes.func,
};

export default TopbarMenu;
