import React from 'react';

const CSS3Icon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <title>CSS3 Logo Badge</title>
      <path fill="#264DE4" d="M71,460 L30,0 481,0 440,460 255,512" />
      <path fill="#2965F1" d="M256,472 L405,431 440,37 256,37" />
      <g transform="translate(-63.8, -128), scale(1.25)">
        <polygon
          id="polygon2993"
          fill="#EBEBEB"
          points="150.31,268.217 154.38,313.627 256,313.627 256,268.217   "
        />
        <polygon
          id="polygon2995"
          fill="#EBEBEB"
          points="142.132,176.305 146.26,221.716 256,221.716 256,176.305 255.843,176.305   "
        />
        <polygon
          id="polygon2997"
          fill="#EBEBEB"
          points="256,386.153 255.801,386.206 205.227,372.55 201.994,336.333 177.419,336.333     156.409,336.333 162.771,407.634 255.791,433.457 256,433.399   "
        />
        <polygon
          id="polygon3005"
          fill="#FFFFFF"
          points="361.399,268.217 369.597,176.305 255.843,176.305 255.843,221.716     319.831,221.716 315.699,268.217 255.843,268.217 255.843,313.627 311.761,313.627 306.49,372.521 255.843,386.191     255.843,433.435 348.937,407.634 349.62,399.962 360.291,280.411   "
        />
      </g>
    </svg>
  );
};

export default CSS3Icon;
