import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { DisplayModeContext, MOBILE } from '../../../Constants/DisplayModes';

const StyledLinkButton = styled.div``;

export const ButtonLabelRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  cursor: pointer;

  background-color: rgba(0, 0, 0, 0);
  border: 0px solid rgba(0, 0, 0, 0);
  padding: 0;
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 10px;
  white-space: nowrap;
`;

const Label = styled.label`
  font-size: ${props => (props.displayMode === MOBILE ? '13pt' : '15pt')};
  font-weight: bold;
  cursor: pointer;
`;

const LabeledIconButton = ({
  className = '',

  label = '',
  showLabel = true,
  renderIcon = () => {},

  onClick = () => {},
}) => {
  const displayMode = useContext(DisplayModeContext);

  const renderLabel = () => {
    return (
      <LabelWrapper>
        <Label displayMode={displayMode}>{label}</Label>
      </LabelWrapper>
    );
  };

  const renderButtonLabelRow = () => {
    const size = displayMode === MOBILE ? 27.5 : 30;

    return (
      <ButtonLabelRow>
        {renderIcon(size)}
        {showLabel && renderLabel()}
      </ButtonLabelRow>
    );
  };

  return (
    <StyledLinkButton className={className} onClick={onClick}>
      {renderButtonLabelRow()}
    </StyledLinkButton>
  );
};

LabeledIconButton.propTypes = {
  className: PropTypes.string,

  label: PropTypes.string,
  showLabel: PropTypes.bool,
  renderIcon: PropTypes.func,

  onClick: PropTypes.func,
};

export default memo(LabeledIconButton);
