import React from 'react';
import PropTypes from 'prop-types';

import useI18nConsumer from '../../Hooks/useI18nConsumer';

import SkillGroupPortlet from './Generic/SkillGroupPortlet';
import MongoDBIcon from '../Icons/Skills/MongoDBIcon';
import PHPIcon from '../Icons/Skills/PHPIcon';
import MySqlIcon from '../Icons/Skills/MySqlIcon';
// import FlaskIcon from '../Icons/Skills/FlaskIcon';

const BackendSkillsPortlet = ({ className = '' }) => {
  const { t } = useI18nConsumer();

  const backendButtons = [
    {
      id: 'mongoDB',
      icon: <MongoDBIcon />,
      href: 'https://www.mongodb.com/',
      hoverText: t('BackendSkillsPortlet.mongoDB'),
    },
    {
      id: 'php',
      icon: <PHPIcon />,
      href: 'https://www.php.net/',
      hoverText: t('BackendSkillsPortlet.php'),
    },
    {
      id: 'mySql',
      icon: <MySqlIcon />,
      href: 'https://www.mysql.com/',
      hoverText: t('BackendSkillsPortlet.mySql'),
    },
    // {
    //   id: 'flask',
    //   icon: <FlaskIcon />,
    //   href: 'https://palletsprojects.com/p/flask/',
    //   hoverText: t('BackendSkillsPortlet.flask'),
    // },
  ];

  return (
    <SkillGroupPortlet
      className={className}
      title={t('BackendSkillsPortlet.backend')}
      buttons={backendButtons}
    />
  );
};

BackendSkillsPortlet.propTypes = {
  className: PropTypes.string,
};

export default BackendSkillsPortlet;
