import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { Link } from 'react-router-dom';

import { AboutRoute } from '../../../Constants/Routes';
import { DisplayModeContext, DESKTOP } from '../../../Constants/DisplayModes';
import { MIRAGE_PALETTE, DARK_PALETTE } from '../../../Constants/Colors';

import useI18nConsumer from '../../../Hooks/useI18nConsumer';

import PaddedPage from '../Generic/PaddedPage';
import Portlet, { PortletTitle, PortletContent } from '../../Portlets/Generic/Portlet';
import FlatButton from '../../Inputs/Generic/FlatButton';
import AccomplishmentList from './AccomplishmentList';
import TimelineMeta from './TimelineMeta';
import TimelineRow from './TimelineRow';
import SkillBadge from './SkillBadge';

const StyledCVPage = styled(PaddedPage)`
  color: ${props => props.theme.foregroundAbout.string()};
  align-items: center;
`;

const ButtonWrapper = styled.div`
  align-self: flex-start;
`;

const CV = styled.div`
  max-width: 1000px;
`;

const HeaderRow = styled.header`
  display: flex;
  flex-direction: ${props => (props.displayMode === DESKTOP ? 'row' : 'column')};
`;

const MeColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin-top: 20px;
`;

const Name = styled.h1`
  color => ${props => props.theme.foregroundAbout.string()};
  font-size: 2.25em;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 5px;
  text-align: center;
`;

const Job = styled.h2`
  margin: 5px;
  text-align: center;

  color: ${props => {
    const { theme } = props;
    if (theme.id === MIRAGE_PALETTE || theme.id === DARK_PALETTE) {
      return theme.foregroundIdle.lighten(0.3).string();
    }

    return theme.foregroundIdle.string();
  }};
`;

const MeMetaPortlet = styled(Portlet)`
  ${PortletTitle} {
    font-size: 1.2em;
  }
  align-self: ${props => (props.displayMode !== DESKTOP ? 'center' : 'stretch')};
`;

const MeMetaColumn = styled.div`
  display: flex;
  flex-direction: column;
  ${PortletContent} {
    padding: 7.5px;
  }
`;

const StyledMetaLink = styled.a`
  text-decoration: none;
  color: ${props => props.theme.foregroundAbout.string()};
  margin-bottom: 5px;
`;

const SectionRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const SectionTitle = styled.h3`
  color: ${props => props.theme.foregroundAbout.string()};
  margin: 20px 0 0 0;
  font-size: 1.5em;
`;

const SectionMetaColumn = styled.div`
  display: ${props => (props.displayMode !== DESKTOP ? 'none' : 'block')};
  flex-basis: 15%;
  flex-shrink: 0;
  min-width: 125px;
  max-width: 175px;
  margin: 10px 10px 0px 0px;
`;

const StyledTimelineRow = styled(TimelineRow)`
  display: ${props => (props.displayMode !== DESKTOP ? 'flex' : 'none')};
`;

const TimelinePortlet = styled(Portlet)`
  margin: 10px 0;
  flex-grow: 1;
  ${PortletTitle} {
    font-size: 1.2em;
  }
  ${PortletContent} {
    padding: 7.5px;
    flex-grow: 1;
  }
`;

const PlainPortletBody = styled.div`
  color: ${props => props.theme.foreground.fade(0.2).string()};
  margin: 7.5px 0;
  padding: 0 35px;
`;

const CVPage = ({ className = '' }) => {
  const { t } = useI18nConsumer();
  const theme = useContext(ThemeContext);
  const displayMode = useContext(DisplayModeContext);

  const renderBackButton = () => {
    const primaryColor = theme.foregroundAbout.string();
    return (
      <ButtonWrapper>
        <Link to={AboutRoute}>
          <FlatButton primaryColor={primaryColor}>{t('back')}</FlatButton>
        </Link>
      </ButtonWrapper>
    );
  };

  const renderLinks = links => {
    return links.map(link => {
      const { id, href, value } = link;

      return (
        <StyledMetaLink key={id} href={href} target="_blank" rel="noopener noreferrer">
          {value}
        </StyledMetaLink>
      );
    });
  };

  const renderHeader = () => {
    const links = [
      {
        id: 'myPage',
        href: 'https://aaronchen.dev',
        value: 'aaronchen.dev',
      },
      {
        id: 'myEmail',
        href: 'mailto:aaron.yujung@gmail.com',
        value: 'aaron.yujung@gmail.com',
      },
      {
        id: 'myGitlab',
        href: 'https://gitlab.com/aaron.chen',
        value: 'gitlab.com/aaron.chen',
      },
      {
        id: 'myLinkedIn',
        href: 'https://www.linkedin.com/in/aaron-chen-100',
        value: 'linkedin.com/in/aaron-chen-100',
      },
    ];

    return (
      <HeaderRow displayMode={displayMode}>
        <MeColumn>
          <Name>{t('CVPage.header.name')}</Name>
          <Job>{t('CVPage.header.job')}</Job>
        </MeColumn>
        <MeMetaPortlet title={displayMode === DESKTOP ? t('CVPage.reachMeAt') : ''}>
          <MeMetaColumn>{renderLinks(links)}</MeMetaColumn>
        </MeMetaPortlet>
      </HeaderRow>
    );
  };

  const renderExperienceSection = () => {
    const renderQualiaSubsection = () => {
      const accomplishmentList = [
        t('CVPage.experience.qualia.accomplishments.fullstackOnMarketplace'),
        [t('CVPage.experience.qualia.accomplishments.marketplaceHub')],
        t('CVPage.experience.qualia.accomplishments.internalDeveloper'),
        [t('CVPage.experience.qualia.accomplishments.leapfinIntegration')],
      ];

      return (
        <SectionRow extraMargin>
          <SectionMetaColumn displayMode={displayMode}>
            <TimelineMeta
              title={t('CVPage.experience.qualia.position')}
              dateRange={t('CVPage.experience.qualia.date')}
              site={{
                href: 'https://www.qualia.com/',
                value: 'qualia.com',
              }}
            />
          </SectionMetaColumn>
          <TimelinePortlet title={t('CVPage.experience.qualia.title')}>
            <StyledTimelineRow
              displayMode={displayMode}
              title={t('CVPage.experience.qualia.position')}
              dateRange={t('CVPage.experience.qualia.date')}
            />
            <AccomplishmentList listItems={accomplishmentList} />
          </TimelinePortlet>
        </SectionRow>
      );
    };

    const renderICSSubSection = () => {
      const accomplishmentList = [
        t('CVPage.experience.ics.accomplishments.fullstackOnNetsfere'),
        [
          t('CVPage.experience.ics.accomplishments.developingFeaturesReactWebApp'),
          t('CVPage.experience.ics.accomplishments.developedSeparateAdminWebApp'),
        ],
        t('CVPage.experience.ics.accomplishments.ledLocalizationWebAndroid'),
        [t('CVPage.experience.ics.accomplishments.implementedMultiLanguageWebApp')],
        t('CVPage.experience.ics.accomplishments.employeeOfMonth'),
      ];

      return (
        <SectionRow>
          <SectionMetaColumn displayMode={displayMode}>
            <TimelineMeta
              title={t('CVPage.experience.ics.position')}
              dateRange={t('CVPage.experience.ics.date')}
              site={{
                href: 'http://www.infinite-convergence.com/',
                value: 'infinite-convergence.com',
              }}
            />
          </SectionMetaColumn>
          <TimelinePortlet title={t('CVPage.experience.ics.title')}>
            <StyledTimelineRow
              displayMode={displayMode}
              title={t('CVPage.experience.ics.position')}
              dateRange={t('CVPage.experience.ics.date')}
            />
            <AccomplishmentList listItems={accomplishmentList} />
          </TimelinePortlet>
        </SectionRow>
      );
    };

    return (
      <>
        <SectionRow>
          <SectionMetaColumn displayMode={displayMode} />
          <SectionTitle>{t('CVPage.experience.experience')}</SectionTitle>
        </SectionRow>
        {renderQualiaSubsection()}
        {renderICSSubSection()}
      </>
    );
  };

  const renderEducationSection = () => {
    return (
      <>
        <SectionRow>
          <SectionMetaColumn displayMode={displayMode} />
          <SectionTitle>{t('CVPage.education.education')}</SectionTitle>
        </SectionRow>
        <SectionRow>
          <SectionMetaColumn displayMode={displayMode}>
            <TimelineMeta
              title={t('CVPage.education.major')}
              dateRange={t('CVPage.education.date')}
              site={{
                href: 'https://illinois.edu/',
                value: 'illinois.edu',
              }}
            />
          </SectionMetaColumn>
          <TimelinePortlet title={t('CVPage.education.uiuc')}>
            <StyledTimelineRow
              displayMode={displayMode}
              title={t('CVPage.education.major')}
              dateRange={t('CVPage.education.date')}
            />
            <PlainPortletBody>{t('CVPage.education.subjectList')}</PlainPortletBody>
          </TimelinePortlet>
        </SectionRow>
      </>
    );
  };

  const renderSkillsSection = () => {
    const renderSkills = (skills, sort = true) => {
      const rearrangedSkills = [...skills];
      if (sort) {
        rearrangedSkills.sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1));
      }

      return rearrangedSkills.map((skill, idx) => {
        return <SkillBadge key={`skill-${idx}`}>{skill}</SkillBadge>; // eslint-disable-line react/no-array-index-key
      });
    };

    const mainSkills = ['javascript', 'react', 'meteor', 'mongo', 'mySql', 'php', 'bash'].map(
      skill => t(`CVPage.skills.mainLanguagesFrameworks.${skill}`)
    );

    const usedSkills = [
      'c++',
      'flask',
      'googleAppscript',
      'java',
      'opengl',
      'postgresql',
      'python',
      'ruby',
      'x86Assembly',
    ].map(skill => t(`CVPage.skills.usedLanguages.${skill}`));

    const basicSkills = ['jira', 'asana', 'excel', 'word', 'git', 'linux', 'svn'].map(skill =>
      t(`CVPage.skills.basicSkills.${skill}`)
    );

    const languageSkills = ['english', 'japanese'].map(skill =>
      t(`CVPage.skills.languageProficiency.${skill}`)
    );

    return (
      <>
        <SectionRow>
          <SectionMetaColumn displayMode={displayMode} />
          <SectionTitle>{t('CVPage.skills.skills')}</SectionTitle>
        </SectionRow>
        <SectionRow>
          <SectionMetaColumn displayMode={displayMode} />
          <TimelinePortlet title={t('CVPage.skills.computerLanguagesFrameworks')}>
            <PlainPortletBody>
              <table>
                <tbody>
                  <tr>
                    <td>
                      {t('CVPage.skills.main')}
                      &nbsp;
                    </td>
                    <td>{renderSkills(mainSkills)}</td>
                  </tr>
                  <tr>
                    <td>
                      {t('CVPage.skills.used')}
                      &nbsp;
                    </td>
                    <td>{renderSkills(usedSkills)}</td>
                  </tr>
                </tbody>
              </table>
            </PlainPortletBody>
          </TimelinePortlet>
        </SectionRow>
        <SectionRow>
          <SectionMetaColumn displayMode={displayMode} />
          <TimelinePortlet title={t('CVPage.skills.other')}>
            <PlainPortletBody>
              <table>
                <tbody>
                  <tr>
                    <td>
                      {t('CVPage.skills.basic')}
                      &nbsp;
                    </td>
                    <td>{renderSkills(basicSkills)}</td>
                  </tr>
                  <tr>
                    <td>
                      {t('CVPage.skills.languages')}
                      &nbsp;
                    </td>
                    <td>{renderSkills(languageSkills, false)}</td>
                  </tr>
                </tbody>
              </table>
            </PlainPortletBody>
          </TimelinePortlet>
        </SectionRow>
      </>
    );
  };

  return (
    <StyledCVPage className={className}>
      {renderBackButton()}
      <CV>
        {renderHeader()}
        {renderEducationSection()}
        {renderExperienceSection()}
        {renderSkillsSection()}
      </CV>
    </StyledCVPage>
  );
};

CVPage.propTypes = {
  className: PropTypes.string,
};

export default CVPage;
