import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { detect } from 'detect-browser';

import WorkButton from '../../Inputs/WorkButton';

import Portlet, { PortletContent } from './Portlet';

const browser = detect() || {};

const StyledWorkGroupPortlet = styled(Portlet)`
  ${PortletContent} {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: ${['edge', 'ie'].includes(browser.name) ? 'space-around' : 'space-evenly'};
  }
  margin-bottom: 15px;
`;

const WorkGroupPortlet = ({ className = '', title = '', buttons = [] }) => {
  return (
    <StyledWorkGroupPortlet className={className} title={title}>
      {buttons.map(button => {
        const { id, icon, src, alt, href, to, hoverText } = button;

        return (
          <WorkButton
            key={id}
            icon={icon}
            src={src}
            alt={alt}
            href={href}
            to={to}
            hoverText={hoverText}
          />
        );
      })}
    </StyledWorkGroupPortlet>
  );
};

WorkGroupPortlet.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,

  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      src: PropTypes.string,
      alt: PropTypes.string,
      href: PropTypes.string,
      hoverText: PropTypes.string,
    })
  ),
};

export default WorkGroupPortlet;
