import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import useI18nConsumer, { Trans } from '../../Hooks/useI18nConsumer';

import PageHeader from './Generic/PageHeader';
import PaddedPage from './Generic/PaddedPage';
import PageShadow from './Generic/PageShadow';
import LanguagePortlet from '../Portlets/LanguagePortlet';
import PalettePortlet from '../Portlets/PalettePortlet';

const StyledSettingsPage = styled(PaddedPage)`
  color: ${props => props.theme.foregroundSettings.string()};
`;

const PortletWrapper = styled.div`
  display: flex;
  flex-direction: column;

  flex-grow: 1;
  justify-content: space-between;
`;

const SettingsPage = ({
  className = '',
  onPaletteIdChange = () => {},
  onLanguageChange = () => {},
}) => {
  const { t } = useI18nConsumer();

  return (
    <StyledSettingsPage className={className}>
      <PageShadow>
        <Trans i18nKey="SettingsPage.pageShadow" defaults="Set<0/>tings" components={[<br />]} />
      </PageShadow>
      <PageHeader title={t('SettingsPage.settings')} />
      <PortletWrapper>
        <LanguagePortlet onLanguageChange={onLanguageChange} />
        <PalettePortlet onPaletteIdChange={onPaletteIdChange} />
      </PortletWrapper>
    </StyledSettingsPage>
  );
};

SettingsPage.propTypes = {
  className: PropTypes.string,

  onPaletteIdChange: PropTypes.func,
  onLanguageChange: PropTypes.func,
};

export default SettingsPage;
