import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import ReactSwitch from 'react-switch';

const Switch = ({ checked = false, onChange = () => {}, 'aria-label': ariaLabel = '' }) => {
  const theme = useContext(ThemeContext);

  const offColor = theme.foregroundIdle.hex();
  const onColor = theme.foregroundWork.hex();
  const onHandleColor = theme.foregroundWork.darken(0.35).hex();

  const activeBoxShadow = `0 0 2px 3px ${theme.foregroundWork.darken(0.5).hex()}`;

  return (
    <ReactSwitch
      checked={checked}
      aria-checked={checked}
      onChange={onChange}
      offColor={offColor}
      onColor={onColor}
      onHandleColor={onHandleColor}
      activeBoxShadow={activeBoxShadow}
      height={22}
      aria-label={ariaLabel}
    />
  );
};

Switch.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  'aria-label': PropTypes.string,
};

export default Switch;
