import React from 'react';

const StyledComponentsIcon = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -1145 5120 5120"
      preserveAspectRatio="xMidYMid meet"
    >
      <g id="layerbg1" fill="#eba816" stroke="none">
        <path d="M1640 2276 c22 -22 183 -104 214 -110 15 -3 49 -19 77 -35 27 -17 52 -31 56 -31 11 0 104 -60 183 -119 75 -56 220 -195 220 -212 0 -5 35 -55 78 -111 42 -57 84 -114 93 -128 13 -20 23 -24 58 -22 46 3 81 -5 81 -18 0 -4 -31 -7 -69 -6 -63 1 -72 -2 -107 -30 -45 -36 -93 -101 -94 -126 0 -34 11 -42 -280 210 -74 64 -182 160 -240 212 -58 53 -166 149 -240 214 -74 65 -163 146 -197 178 -53 51 -64 57 -70 43 -4 -13 2 -25 21 -42 26 -22 27 -23 4 -20 -28 3 -35 -29 -19 -84 12 -38 41 -65 165 -154 31 -22 56 -43 56 -48 0 -4 8 -11 18 -14 50 -19 300 -221 341 -276 41 -56 150 -157 168 -157 12 0 43 -21 70 -46 26 -26 41 -42 33 -37 -8 6 -36 16 -62 23 -67 18 -128 0 -168 -48 -30 -36 -31 -65 -2 -39 62 54 160 66 227 28 61 -35 136 -93 205 -160 35 -34 66 -59 69 -56 11 12 9 40 -4 55 -7 8 -17 27 -20 43 -4 15 -11 30 -16 33 -5 3 -9 10 -9 16 0 7 5 6 14 -1 27 -23 28 9 2 44 -53 69 -25 135 74 179 l61 27 46 -17 c56 -22 124 -73 219 -166 73 -71 93 -78 115 -37 11 21 3 43 -12 34 -5 -4 -9 9 -9 28 0 19 -8 45 -18 58 -10 13 -21 32 -25 41 -3 9 -39 42 -79 71 -40 30 -106 90 -147 133 -41 44 -133 140 -205 214 -150 154 -134 147 31 -13 108 -105 140 -123 127 -71 -15 58 25 111 102 133 43 13 53 13 98 -4 65 -23 122 -62 215 -148 69 -64 76 -68 88 -51 8 11 10 21 4 27 -7 7 -6 18 1 31 17 30 18 97 3 102 -7 3 -10 14 -8 25 3 11 -1 27 -9 37 -16 18 -58 103 -58 117 0 4 -10 18 -22 31 -41 42 -58 64 -51 64 4 0 -37 45 -92 100 l-100 100 -57 0 c-32 0 -58 -3 -58 -6 0 -3 19 -27 43 -53 23 -26 55 -73 71 -104 18 -37 47 -72 81 -99 52 -43 76 -84 80 -141 2 -15 -1 -30 -6 -33 -5 -3 -7 -10 -4 -15 9 -14 -4 -11 -33 9 -26 17 -48 28 -105 51 -65 26 -121 7 -186 -64 -23 -25 -46 -45 -53 -45 -6 0 -34 22 -62 49 -122 118 -326 304 -460 419 -23 19 -49 32 -64 32 -26 0 -26 -2 19 -43 25 -23 98 -87 161 -142 64 -55 114 -102 112 -104 -2 -2 -42 30 -90 70 -48 41 -110 92 -138 115 -28 22 -66 55 -85 72 l-33 32 -176 0 c-153 0 -174 -2 -162 -14z" />
        <path d="M1406 1621 c-7 -10 21 -81 32 -81 4 0 30 -21 57 -47 28 -26 53 -47 57 -48 3 0 28 -20 55 -44 26 -25 68 -60 93 -79 25 -19 72 -60 105 -92 33 -31 70 -64 82 -72 12 -8 47 -38 78 -66 31 -29 60 -50 65 -47 4 3 27 -16 49 -42 29 -32 45 -43 52 -36 10 10 -6 34 -92 143 -15 19 -29 51 -31 70 -4 44 -20 45 -33 2 -5 -18 -13 -31 -17 -30 -8 3 -257 222 -387 341 -116 106 -158 139 -165 128z" />
      </g>
      <g id="layerbg2" fill="#a55071" stroke="none">
        <path d="M1970 2281 c0 -5 82 -78 183 -162 114 -95 273 -243 427 -399 135 -136 269 -264 298 -284 28 -21 52 -42 52 -48 0 -5 9 -21 20 -35 11 -14 20 -40 20 -58 0 -18 9 -43 20 -56 10 -13 20 -22 21 -19 0 3 3 28 6 55 6 59 8 63 33 47 11 -7 26 -12 34 -12 18 0 26 -22 26 -70 0 -36 19 -86 37 -99 17 -12 84 -113 79 -118 -3 -3 1 -15 9 -27 8 -11 15 -37 15 -58 0 -20 5 -40 10 -43 6 -3 10 -24 10 -45 0 -21 5 -42 10 -45 6 -3 10 -28 10 -54 0 -29 6 -53 14 -60 13 -11 18 -33 32 -134 3 -23 9 -51 14 -62 14 -34 19 -65 9 -65 -21 0 -80 -48 -109 -88 -24 -34 -30 -52 -30 -94 1 -31 7 -61 15 -72 9 -12 15 -42 15 -72 0 -29 5 -56 10 -59 6 -3 10 -15 10 -26 0 -17 7 -19 70 -19 50 0 70 4 70 13 -1 6 -7 48 -15 92 -8 44 -22 124 -30 179 -17 110 -13 126 36 126 32 0 46 15 30 33 -12 15 -25 64 -60 230 -5 23 -17 74 -26 112 -9 39 -19 87 -22 108 -3 20 -9 40 -14 43 -5 3 -9 21 -9 39 0 21 -17 66 -44 117 -84 160 -102 198 -120 251 -3 9 -16 21 -29 26 -33 13 -153 111 -279 229 -127 119 -164 147 -173 132 -4 -7 -62 43 -138 117 -73 71 -213 199 -312 286 -139 121 -186 157 -207 157 -16 0 -28 -4 -28 -9z" />
        <path d="M2875 2240 c55 -56 85 -57 33 -2 -41 45 -49 52 -68 52 -8 0 7 -22 35 -50z" />
        <path d="M2935 2181 c-7 -12 12 -24 25 -16 6 4 9 3 8 -2 -2 -4 0 -9 5 -10 18 -7 19 -2 2 17 -20 22 -31 25 -40 11z" />
        <path d="M1406 2163 c-15 -15 -4 -44 24 -58 16 -8 69 -50 117 -92 49 -43 124 -109 169 -148 167 -145 300 -262 378 -333 45 -40 87 -70 94 -67 7 3 10 1 7 -4 -3 -5 -1 -12 4 -15 5 -4 12 -2 16 3 7 13 -206 202 -222 197 -7 -3 -10 1 -7 8 3 8 -27 41 -74 82 -44 38 -169 148 -279 245 -216 190 -217 191 -227 182z" />
        <path d="M2988 2134 c-4 -5 6 -25 21 -43 16 -19 31 -30 34 -24 3 5 -6 25 -22 43 -15 19 -30 29 -33 24z" />
        <path d="M3094 1975 c-9 -23 10 -40 25 -22 9 11 8 17 -4 27 -12 10 -16 9 -21 -5z" />
        <path d="M2751 1867 c-46 -23 -55 -40 -24 -45 10 -2 30 -16 44 -30 34 -37 221 -208 253 -233 32 -25 33 -39 3 -39 -52 0 -55 -40 -5 -66 31 -16 53 -10 44 11 -3 9 8 6 30 -7 22 -13 43 -37 56 -67 12 -25 37 -70 55 -99 18 -29 33 -58 33 -65 0 -6 14 -37 31 -67 17 -30 33 -68 34 -85 2 -16 35 -156 74 -310 40 -153 71 -288 70 -300 -1 -11 1 -27 4 -35 8 -23 24 -92 57 -245 17 -77 32 -150 35 -162 5 -22 10 -23 90 -23 l85 0 0 228 c0 164 -3 231 -12 240 -7 7 -39 12 -75 12 l-62 0 -6 38 c-3 20 -10 45 -16 54 -5 10 -9 31 -9 47 0 17 -4 33 -10 36 -5 3 -10 24 -10 45 0 21 -4 42 -10 45 -5 3 -10 21 -10 39 0 19 -7 39 -15 46 -8 7 -15 25 -15 41 0 15 -4 31 -10 34 -5 3 -10 24 -10 45 0 21 -4 42 -10 45 -5 3 -10 17 -10 31 0 14 -6 27 -13 29 -9 4 -13 33 -13 100 0 59 -5 100 -12 107 -7 7 -12 20 -12 29 0 10 -10 26 -23 36 -42 33 -67 79 -67 121 0 23 -4 44 -10 47 -5 3 -10 15 -10 25 0 10 -6 23 -13 27 -6 4 -19 21 -27 37 -8 17 -30 44 -49 60 -19 17 -57 53 -84 79 -98 94 -222 168 -280 167 -12 -1 -42 -11 -66 -23z" />
        <path d="M3157 1833 c-8 -14 12 -55 25 -51 19 7 16 52 -4 56 -9 2 -18 -1 -21 -5z" />
        <path d="M3170 1755 c-7 -8 -10 -15 -6 -15 3 0 1 -11 -4 -24 -7 -19 -5 -28 10 -41 23 -21 30 -9 28 44 -2 47 -10 58 -28 36z" />
        <path d="M1405 1598 c-10 -24 10 -54 59 -91 49 -36 186 -153 261 -221 28 -25 56 -44 63 -41 7 3 11 1 8 -4 -8 -12 31 -44 43 -36 6 3 9 0 5 -8 -6 -16 72 -72 81 -58 8 13 -92 94 -106 85 -7 -4 -9 -3 -6 4 10 14 -139 146 -154 136 -7 -4 -9 -3 -6 4 4 5 -30 42 -75 81 -119 102 -142 124 -155 146 -10 18 -12 18 -18 3z" />
        <path d="M2574 1446 c-28 -13 -54 -28 -57 -33 -4 -5 44 -53 106 -106 62 -53 157 -139 211 -192 55 -52 102 -95 105 -95 3 0 31 -26 63 -57 36 -36 58 -66 58 -80 0 -30 20 -40 37 -18 19 28 18 184 -2 191 -8 4 -15 14 -15 23 0 9 -9 27 -20 41 -11 14 -20 30 -20 35 0 6 -14 22 -31 35 -18 14 -71 61 -118 106 -104 97 -146 128 -206 154 -57 24 -49 25 -111 -4z" />
        <path d="M2121 1310 c-35 -8 -82 -39 -85 -54 0 -5 12 -13 27 -17 35 -9 136 -90 228 -181 21 -21 42 -38 46 -38 4 0 70 -62 145 -139 158 -160 168 -163 168 -51 0 38 -4 72 -10 75 -5 3 -10 12 -10 20 0 29 -84 133 -173 214 -160 146 -250 192 -336 171z" />
        <path d="M2463 1284 c-3 -8 -1 -20 6 -27 22 -22 10 -30 -13 -8 -37 34 -42 7 -7 -36 16 -21 33 -50 36 -65 4 -14 16 -35 26 -44 16 -15 24 -16 39 -6 17 10 30 3 102 -56 157 -129 251 -192 288 -192 10 0 22 -4 25 -10 10 -15 71 -12 85 5 13 16 9 18 -26 16 -33 -3 -294 203 -460 361 -45 43 -84 78 -88 78 -4 0 -9 -7 -13 -16z" />
        <path d="M2004 1149 c-3 -6 7 -28 22 -49 16 -21 45 -60 64 -86 59 -81 200 -206 270 -240 19 -10 49 -27 66 -40 19 -14 49 -23 80 -26 50 -3 79 14 34 21 -31 4 -276 201 -413 331 -58 55 -107 100 -110 100 -3 0 -9 -5 -13 -11z" />
        <path d="M1936 1121 c-8 -12 55 -62 67 -54 9 6 -19 40 -45 54 -10 5 -19 5 -22 0z" />
      </g>
      <g id="layerbg3" fill="#fece49" stroke="none">
        <path d="M1400 2239 c0 -59 -7 -51 249 -277 79 -70 192 -170 250 -222 58 -53 160 -143 226 -200 249 -218 304 -262 315 -255 5 3 11 -2 14 -11 9 -25 26 -6 26 30 0 19 11 49 25 70 23 34 23 41 1 37 -19 -4 16 28 48 43 24 11 54 15 105 12 39 -2 73 -1 75 3 3 3 -4 18 -14 31 -16 21 -28 25 -77 27 l-57 1 -71 94 c-38 51 -85 117 -104 146 -18 29 -44 61 -58 72 -13 10 -37 35 -53 55 -15 19 -56 55 -91 80 -94 67 -200 136 -218 143 -9 3 -37 19 -63 34 -26 16 -59 31 -74 34 -14 3 -69 27 -122 54 l-95 50 -119 0 -118 0 0 -51z" />
        <path d="M2050 2281 c0 -5 24 -28 53 -51 28 -23 150 -132 271 -243 120 -111 226 -206 234 -211 9 -5 22 -5 31 1 13 8 16 5 16 -16 0 -37 19 -42 23 -6 2 16 12 40 23 53 10 13 17 28 14 32 -3 4 3 7 12 6 10 0 18 4 18 9 2 49 82 46 183 -8 17 -9 45 -24 60 -32 16 -8 37 -24 47 -35 17 -19 41 -18 29 1 -3 5 -10 6 -16 3 -7 -5 -8 -2 -3 5 4 7 -2 22 -15 36 -12 13 -17 26 -12 29 13 8 7 63 -12 106 -16 36 -42 64 -87 94 -14 9 -35 33 -45 54 -30 59 -76 125 -107 155 l-29 27 -344 0 c-201 0 -344 -4 -344 -9z" />
        <path d="M1407 2054 c-3 -3 -6 -102 -7 -218 l0 -213 35 -31 c65 -60 321 -286 423 -376 90 -78 104 -87 119 -75 15 12 16 12 11 -3 -9 -21 2 -31 19 -17 7 6 13 27 13 46 0 19 8 48 17 66 9 18 17 38 17 46 1 8 21 21 45 29 57 19 121 4 198 -44 63 -41 84 -27 27 19 -22 17 -52 45 -69 62 -37 37 -72 60 -107 68 -14 4 -37 21 -50 37 -46 60 -221 230 -291 283 -40 29 -108 80 -152 113 -44 32 -84 64 -90 70 -5 5 -36 27 -67 48 -35 24 -61 49 -67 67 -10 30 -14 33 -24 23z" />
        <path d="M2005 1090 c-4 -6 -1 -18 7 -27 7 -9 12 -18 10 -20 -2 -3 16 -20 40 -38 53 -42 75 -30 29 16 -18 18 -39 43 -48 56 -17 25 -28 29 -38 13z" />
      </g>
      <g id="layerbg4" fill="#eba0df" stroke="none">
        <path d="M2682 1810 c-34 -45 -29 -82 16 -116 20 -16 82 -71 137 -122 118 -112 254 -222 272 -222 7 0 13 -6 13 -13 0 -20 23 -68 116 -245 27 -51 44 -96 44 -117 0 -18 4 -36 9 -39 5 -3 11 -23 14 -43 3 -21 13 -69 22 -108 9 -38 21 -89 26 -112 35 -167 48 -215 61 -230 8 -10 4 -13 -20 -13 -21 0 -35 -7 -46 -25 -17 -26 -12 -75 29 -300 8 -44 14 -86 15 -92 0 -10 25 -13 90 -13 89 0 90 0 85 23 -3 12 -18 85 -35 162 -33 153 -49 222 -57 245 -3 8 -5 24 -4 35 1 12 -30 147 -70 300 -39 154 -72 294 -74 310 -1 17 -17 55 -34 85 -17 30 -31 61 -31 68 0 7 -11 29 -25 50 -13 20 -39 66 -57 102 -24 46 -45 73 -75 93 -42 28 -69 28 -59 1 4 -11 2 -12 -14 -4 -30 16 -33 30 -7 30 14 0 30 7 38 16 14 17 23 7 -197 212 -69 63 -130 112 -141 112 -10 0 -29 -13 -41 -30z" />
        <path d="M2491 1383 c-22 -27 -36 -65 -29 -81 7 -18 221 -211 341 -307 190 -153 201 -160 240 -149 24 7 33 16 35 35 2 20 -12 39 -70 94 -234 223 -472 435 -489 435 -4 0 -17 -12 -28 -27z" />
        <path d="M2015 1230 c-8 -16 -15 -38 -15 -49 0 -31 190 -209 420 -394 88 -71 98 -77 142 -77 37 0 50 4 58 20 10 18 0 32 -82 118 -102 106 -272 261 -386 353 -84 68 -114 74 -137 29z" />
      </g>
      <g id="layerbg5" fill="#fdfafe" stroke="none">
        <path d="M813 2047 c-4 -7 -35 -28 -68 -46 -33 -18 -65 -39 -71 -46 -6 -8 -13 -12 -16 -10 -3 3 -11 -1 -18 -10 -7 -8 -17 -15 -22 -15 -6 0 -24 -10 -41 -22 -18 -13 -36 -23 -40 -23 -4 0 -11 -4 -15 -10 -6 -9 -27 -23 -77 -49 -11 -6 -41 -26 -67 -44 -26 -17 -50 -32 -53 -32 -4 0 -28 -15 -54 -33 -25 -19 -63 -41 -83 -51 -21 -9 -38 -21 -38 -25 0 -5 -15 -16 -33 -24 -17 -9 -51 -29 -75 -46 l-42 -31 0 -118 c1 -126 4 -137 55 -162 17 -8 51 -29 76 -47 26 -18 50 -33 54 -33 4 0 28 -15 54 -33 25 -18 67 -43 94 -56 26 -13 47 -28 47 -32 0 -4 17 -16 38 -25 20 -10 58 -32 83 -51 26 -18 50 -33 54 -33 3 0 27 -15 53 -32 60 -42 102 -67 125 -74 9 -4 17 -10 17 -14 0 -13 101 -60 127 -60 14 0 26 8 30 17 3 10 16 32 27 48 20 27 46 79 46 90 0 2 -29 18 -64 35 -35 17 -67 38 -70 45 -3 8 -12 12 -21 9 -8 -3 -15 -1 -15 4 0 10 -19 22 -87 56 -13 6 -23 14 -23 18 0 3 -13 14 -30 22 -16 9 -43 23 -59 31 -16 9 -34 22 -41 30 -7 8 -36 26 -66 41 -30 14 -54 30 -54 34 0 5 -26 21 -57 37 -32 15 -65 35 -75 45 -9 9 -37 26 -60 37 -48 22 -91 61 -67 61 8 0 25 12 38 25 13 14 27 25 33 25 5 0 30 15 56 32 46 32 69 46 125 75 15 8 27 18 27 23 0 4 24 20 54 34 30 15 59 33 66 41 7 8 25 21 41 30 16 8 43 22 59 31 17 8 30 19 30 24 0 4 8 10 18 14 25 8 81 42 106 64 11 10 37 26 56 35 19 9 45 25 58 35 21 17 22 20 9 45 -8 16 -22 34 -31 40 -9 7 -22 24 -29 37 -15 29 -79 41 -94 17z" />
        <path d="M4215 2030 c-9 -17 -29 -42 -45 -57 -17 -14 -30 -32 -30 -38 0 -13 42 -55 54 -55 5 0 30 -16 56 -35 26 -19 51 -35 55 -35 3 0 27 -15 53 -32 26 -18 56 -38 67 -44 50 -27 70 -40 82 -54 7 -8 13 -11 13 -6 0 5 8 3 18 -5 9 -8 27 -20 40 -26 52 -30 66 -39 83 -55 10 -9 38 -26 62 -37 24 -11 49 -27 56 -35 7 -9 37 -27 66 -42 30 -14 56 -32 59 -40 3 -8 13 -14 22 -14 15 0 15 -2 -2 -21 -10 -11 -38 -29 -62 -40 -23 -11 -51 -28 -60 -37 -10 -9 -31 -24 -47 -32 -17 -8 -51 -29 -76 -47 -26 -18 -50 -33 -54 -33 -4 0 -30 -16 -56 -35 -27 -19 -52 -35 -55 -35 -3 0 -19 -9 -35 -21 -16 -11 -37 -19 -46 -18 -31 3 -36 -1 -24 -15 9 -11 1 -19 -44 -40 -30 -15 -55 -31 -55 -35 0 -4 -12 -14 -27 -21 -16 -8 -40 -22 -55 -32 -14 -10 -33 -18 -41 -18 -9 0 -22 -14 -31 -31 -14 -28 -14 -33 0 -55 9 -13 21 -24 26 -24 5 0 7 -4 3 -9 -3 -6 1 -13 8 -18 7 -4 16 -16 20 -25 11 -31 67 -21 120 21 9 8 17 10 17 6 0 -5 9 1 20 13 11 11 37 29 57 39 21 9 59 32 84 50 26 18 50 33 54 33 4 0 28 15 54 33 25 19 63 41 84 51 20 9 37 20 37 25 0 4 12 14 28 22 15 7 47 27 72 44 43 29 56 36 110 66 14 8 39 23 55 34 17 12 44 27 60 35 51 25 54 36 55 162 l0 118 -42 31 c-24 17 -58 37 -75 46 -18 8 -33 19 -33 24 0 4 -17 16 -37 25 -21 10 -59 32 -84 51 -26 18 -50 33 -54 33 -3 0 -27 15 -53 32 -26 18 -56 38 -67 44 -50 26 -71 40 -77 49 -4 6 -11 10 -15 10 -4 0 -22 10 -40 23 -17 12 -35 22 -41 22 -5 0 -15 7 -22 15 -7 9 -15 13 -18 10 -3 -2 -10 2 -16 10 -6 7 -38 28 -71 46 -33 18 -64 38 -68 46 -4 7 -23 13 -42 13 -28 0 -37 -6 -50 -30z" />
        <path d="M2596 1232 c-9 -15 21 -49 34 -36 7 7 5 15 -9 28 -10 9 -22 13 -25 8z" />
        <path d="M2636 1191 c-3 -4 5 -18 18 -30 28 -26 42 -14 20 17 -16 23 -29 28 -38 13z" />
        <path d="M2696 1142 c-8 -13 60 -75 74 -67 16 10 -29 57 -48 50 -7 -2 -10 -1 -7 4 3 5 1 12 -4 15 -5 4 -12 2 -15 -2z" />
        <path d="M2137 1094 c-6 -6 24 -38 109 -114 22 -19 49 -42 61 -50 17 -12 111 -84 200 -152 7 -5 14 -5 17 0 3 6 -33 43 -82 83 -48 40 -94 80 -102 89 -8 9 -33 29 -54 46 -22 16 -61 46 -88 67 -53 40 -52 40 -61 31z" />
      </g>
      <g id="layer101" fill="#eba816" stroke="none">
        <path d="M1640 2276 c22 -22 183 -104 214 -110 15 -3 49 -19 77 -35 27 -17 52 -31 56 -31 11 0 104 -60 183 -119 75 -56 220 -195 220 -212 0 -5 35 -55 78 -111 42 -57 84 -114 93 -128 13 -20 23 -24 58 -22 46 3 81 -5 81 -18 0 -4 -31 -7 -69 -6 -63 1 -72 -2 -107 -30 -45 -36 -93 -101 -94 -126 0 -34 11 -42 -280 210 -74 64 -182 160 -240 212 -58 53 -166 149 -240 214 -74 65 -163 146 -197 178 -53 51 -64 57 -70 43 -4 -13 2 -25 21 -42 26 -22 27 -23 4 -20 -28 3 -35 -29 -19 -84 12 -38 41 -65 165 -154 31 -22 56 -43 56 -48 0 -4 8 -11 18 -14 50 -19 300 -221 341 -276 41 -56 150 -157 168 -157 12 0 43 -21 70 -46 26 -26 41 -42 33 -37 -8 6 -36 16 -62 23 -67 18 -128 0 -168 -48 -30 -36 -31 -65 -2 -39 62 54 160 66 227 28 61 -35 136 -93 205 -160 35 -34 66 -59 69 -56 11 12 9 40 -4 55 -7 8 -17 27 -20 43 -4 15 -11 30 -16 33 -5 3 -9 10 -9 16 0 7 5 6 14 -1 27 -23 28 9 2 44 -53 69 -25 135 74 179 l61 27 46 -17 c56 -22 124 -73 219 -166 73 -71 93 -78 115 -37 11 21 3 43 -12 34 -5 -4 -9 9 -9 28 0 19 -8 45 -18 58 -10 13 -21 32 -25 41 -3 9 -39 42 -79 71 -40 30 -106 90 -147 133 -41 44 -133 140 -205 214 -150 154 -134 147 31 -13 108 -105 140 -123 127 -71 -15 58 25 111 102 133 43 13 53 13 98 -4 65 -23 122 -62 215 -148 69 -64 76 -68 88 -51 8 11 10 21 4 27 -7 7 -6 18 1 31 17 30 18 97 3 102 -7 3 -10 14 -8 25 3 11 -1 27 -9 37 -16 18 -58 103 -58 117 0 4 -10 18 -22 31 -41 42 -58 64 -51 64 4 0 -37 45 -92 100 l-100 100 -57 0 c-32 0 -58 -3 -58 -6 0 -3 19 -27 43 -53 23 -26 55 -73 71 -104 18 -37 47 -72 81 -99 52 -43 76 -84 80 -141 2 -15 -1 -30 -6 -33 -5 -3 -7 -10 -4 -15 9 -14 -4 -11 -33 9 -26 17 -48 28 -105 51 -65 26 -121 7 -186 -64 -23 -25 -46 -45 -53 -45 -6 0 -34 22 -62 49 -122 118 -326 304 -460 419 -23 19 -49 32 -64 32 -26 0 -26 -2 19 -43 25 -23 98 -87 161 -142 64 -55 114 -102 112 -104 -2 -2 -42 30 -90 70 -48 41 -110 92 -138 115 -28 22 -66 55 -85 72 l-33 32 -176 0 c-153 0 -174 -2 -162 -14z m725 -306 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z" />
        <path d="M1406 1971 c-4 -5 -2 -12 3 -15 5 -4 12 -2 15 3 4 5 2 12 -3 15 -5 4 -12 2 -15 -3z" />
        <path d="M1406 1621 c-7 -10 21 -81 32 -81 4 0 30 -21 57 -47 28 -26 53 -47 57 -48 3 0 28 -20 55 -44 26 -25 68 -60 93 -79 25 -19 72 -60 105 -92 33 -31 70 -64 82 -72 12 -8 47 -38 78 -66 31 -29 60 -50 65 -47 4 3 27 -16 49 -42 29 -32 45 -43 52 -36 10 10 -6 34 -92 143 -15 19 -29 51 -31 70 -4 44 -20 45 -33 2 -5 -18 -13 -31 -17 -30 -8 3 -257 222 -387 341 -116 106 -158 139 -165 128z" />
        <path d="M2515 1190 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6 -12 6 -17 -2z" />
        <path d="M2535 1160 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6 -12 6 -17 -2z" />
      </g>
      <g id="layer102" fill="#a55071" stroke="none">
        <path d="M1970 2281 c0 -5 82 -78 183 -162 114 -95 273 -243 427 -399 135 -136 269 -264 298 -284 28 -21 52 -42 52 -48 0 -5 9 -21 20 -35 11 -14 20 -40 20 -58 0 -18 9 -43 20 -56 10 -13 20 -22 21 -19 0 3 3 28 6 55 6 59 8 63 33 47 11 -7 26 -12 34 -12 18 0 26 -22 26 -70 0 -36 19 -86 37 -99 17 -12 84 -113 79 -118 -3 -3 1 -15 9 -27 8 -11 15 -37 15 -58 0 -20 5 -40 10 -43 6 -3 10 -24 10 -45 0 -21 5 -42 10 -45 6 -3 10 -28 10 -54 0 -29 6 -53 14 -60 13 -11 18 -33 32 -134 3 -23 9 -51 14 -62 14 -34 19 -65 9 -65 -21 0 -80 -48 -109 -88 -24 -34 -30 -52 -30 -94 1 -31 7 -61 15 -72 9 -12 15 -42 15 -72 0 -29 5 -56 10 -59 6 -3 10 -15 10 -26 0 -17 7 -19 70 -19 50 0 70 4 70 13 -1 6 -7 48 -15 92 -8 44 -22 124 -30 179 -17 110 -13 126 36 126 32 0 46 15 30 33 -12 15 -25 64 -60 230 -5 23 -17 74 -26 112 -9 39 -19 87 -22 108 -3 20 -9 40 -14 43 -5 3 -9 21 -9 39 0 21 -17 66 -44 117 -84 160 -102 198 -120 251 -3 9 -16 21 -29 26 -33 13 -153 111 -279 229 -127 119 -164 147 -173 132 -4 -7 -62 43 -138 117 -73 71 -213 199 -312 286 -139 121 -186 157 -207 157 -16 0 -28 -4 -28 -9z" />
        <path d="M2875 2240 c55 -56 85 -57 33 -2 -41 45 -49 52 -68 52 -8 0 7 -22 35 -50z" />
        <path d="M2935 2181 c-7 -12 12 -24 25 -16 6 4 9 3 8 -2 -2 -4 0 -9 5 -10 18 -7 19 -2 2 17 -20 22 -31 25 -40 11z" />
        <path d="M1406 2163 c-15 -15 -4 -44 24 -58 16 -8 69 -50 117 -92 49 -43 124 -109 169 -148 167 -145 300 -262 378 -333 45 -40 87 -70 94 -67 7 3 10 1 7 -4 -3 -5 -1 -12 4 -15 5 -4 12 -2 16 3 7 13 -206 202 -222 197 -7 -3 -10 1 -7 8 3 8 -27 41 -74 82 -44 38 -169 148 -279 245 -216 190 -217 191 -227 182z" />
        <path d="M2988 2134 c-4 -5 6 -25 21 -43 16 -19 31 -30 34 -24 3 5 -6 25 -22 43 -15 19 -30 29 -33 24z" />
        <path d="M3055 2050 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6 -12 6 -17 -2z" />
        <path d="M3076 2011 c-4 -5 -2 -12 3 -15 5 -4 12 -2 15 3 4 5 2 12 -3 15 -5 4 -12 2 -15 -3z" />
        <path d="M3094 1975 c-9 -23 10 -40 25 -22 9 11 8 17 -4 27 -12 10 -16 9 -21 -5z" />
        <path d="M3125 1930 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6 -12 6 -17 -2z" />
        <path d="M2751 1867 c-46 -23 -55 -40 -24 -45 10 -2 30 -16 44 -30 34 -37 221 -208 253 -233 32 -25 33 -39 3 -39 -52 0 -55 -40 -5 -66 31 -16 53 -10 44 11 -3 9 8 6 30 -7 22 -13 43 -37 56 -67 12 -25 37 -70 55 -99 18 -29 33 -58 33 -65 0 -6 14 -37 31 -67 17 -30 33 -68 34 -85 2 -16 35 -156 74 -310 40 -153 71 -288 70 -300 -1 -11 1 -27 4 -35 8 -23 24 -92 57 -245 17 -77 32 -150 35 -162 5 -22 10 -23 90 -23 l85 0 0 228 c0 164 -3 231 -12 240 -7 7 -39 12 -75 12 l-62 0 -6 38 c-3 20 -10 45 -16 54 -5 10 -9 31 -9 47 0 17 -4 33 -10 36 -5 3 -10 24 -10 45 0 21 -4 42 -10 45 -5 3 -10 21 -10 39 0 19 -7 39 -15 46 -8 7 -15 25 -15 41 0 15 -4 31 -10 34 -5 3 -10 24 -10 45 0 21 -4 42 -10 45 -5 3 -10 17 -10 31 0 14 -6 27 -13 29 -9 4 -13 33 -13 100 0 59 -5 100 -12 107 -7 7 -12 20 -12 29 0 10 -10 26 -23 36 -42 33 -67 79 -67 121 0 23 -4 44 -10 47 -5 3 -10 15 -10 25 0 10 -6 23 -13 27 -6 4 -19 21 -27 37 -8 17 -30 44 -49 60 -19 17 -57 53 -84 79 -98 94 -222 168 -280 167 -12 -1 -42 -11 -66 -23z" />
        <path d="M3156 1881 c-4 -5 -2 -12 3 -15 5 -4 12 -2 15 3 4 5 2 12 -3 15 -5 4 -12 2 -15 -3z" />
        <path d="M3157 1833 c-8 -14 12 -55 25 -51 19 7 16 52 -4 56 -9 2 -18 -1 -21 -5z" />
        <path d="M3170 1755 c-7 -8 -10 -15 -6 -15 3 0 1 -11 -4 -24 -7 -19 -5 -28 10 -41 23 -21 30 -9 28 44 -2 47 -10 58 -28 36z" />
        <path d="M1405 1598 c-10 -24 10 -54 59 -91 49 -36 186 -153 261 -221 28 -25 56 -44 63 -41 7 3 11 1 8 -4 -8 -12 31 -44 43 -36 6 3 9 0 5 -8 -6 -16 72 -72 81 -58 8 13 -92 94 -106 85 -7 -4 -9 -3 -6 4 10 14 -139 146 -154 136 -7 -4 -9 -3 -6 4 4 5 -30 42 -75 81 -119 102 -142 124 -155 146 -10 18 -12 18 -18 3z" />
        <path d="M2574 1446 c-28 -13 -54 -28 -57 -33 -4 -5 44 -53 106 -106 62 -53 157 -139 211 -192 55 -52 102 -95 105 -95 3 0 31 -26 63 -57 36 -36 58 -66 58 -80 0 -30 20 -40 37 -18 19 28 18 184 -2 191 -8 4 -15 14 -15 23 0 9 -9 27 -20 41 -11 14 -20 30 -20 35 0 6 -14 22 -31 35 -18 14 -71 61 -118 106 -104 97 -146 128 -206 154 -57 24 -49 25 -111 -4z" />
        <path d="M2121 1310 c-35 -8 -82 -39 -85 -54 0 -5 12 -13 27 -17 35 -9 136 -90 228 -181 21 -21 42 -38 46 -38 4 0 70 -62 145 -139 158 -160 168 -163 168 -51 0 38 -4 72 -10 75 -5 3 -10 12 -10 20 0 29 -84 133 -173 214 -160 146 -250 192 -336 171z" />
        <path d="M2463 1284 c-3 -8 -1 -20 6 -27 22 -22 10 -30 -13 -8 -37 34 -42 7 -7 -36 16 -21 33 -50 36 -65 4 -14 16 -35 26 -44 16 -15 24 -16 39 -6 17 10 30 3 102 -56 157 -129 251 -192 288 -192 10 0 22 -4 25 -10 10 -15 71 -12 85 5 13 16 9 18 -26 16 -33 -3 -294 203 -460 361 -45 43 -84 78 -88 78 -4 0 -9 -7 -13 -16z" />
        <path d="M2004 1149 c-3 -6 7 -28 22 -49 16 -21 45 -60 64 -86 59 -81 200 -206 270 -240 19 -10 49 -27 66 -40 19 -14 49 -23 80 -26 50 -3 79 14 34 21 -31 4 -276 201 -413 331 -58 55 -107 100 -110 100 -3 0 -9 -5 -13 -11z" />
        <path d="M1936 1121 c-8 -12 55 -62 67 -54 9 6 -19 40 -45 54 -10 5 -19 5 -22 0z" />
        <path d="M2166 901 c-4 -5 -2 -12 3 -15 5 -4 12 -2 15 3 4 5 2 12 -3 15 -5 4 -12 2 -15 -3z" />
      </g>
      <g id="layer103" fill="#fece49" stroke="none">
        <path d="M1400 2239 c0 -59 -7 -51 249 -277 79 -70 192 -170 250 -222 58 -53 160 -143 226 -200 249 -218 304 -262 315 -255 5 3 11 -2 14 -11 9 -25 26 -6 26 30 0 19 11 49 25 70 23 34 23 41 1 37 -19 -4 16 28 48 43 24 11 54 15 105 12 39 -2 73 -1 75 3 3 3 -4 18 -14 31 -16 21 -28 25 -77 27 l-57 1 -71 94 c-38 51 -85 117 -104 146 -18 29 -44 61 -58 72 -13 10 -37 35 -53 55 -15 19 -56 55 -91 80 -94 67 -200 136 -218 143 -9 3 -37 19 -63 34 -26 16 -59 31 -74 34 -14 3 -69 27 -122 54 l-95 50 -119 0 -118 0 0 -51z" />
        <path d="M2050 2281 c0 -5 24 -28 53 -51 28 -23 150 -132 271 -243 120 -111 226 -206 234 -211 9 -5 22 -5 31 1 13 8 16 5 16 -16 0 -37 19 -42 23 -6 2 16 12 40 23 53 10 13 17 28 14 32 -3 4 3 7 12 6 10 0 18 4 18 9 2 49 82 46 183 -8 17 -9 45 -24 60 -32 16 -8 37 -24 47 -35 17 -19 41 -18 29 1 -3 5 -10 6 -16 3 -7 -5 -8 -2 -3 5 4 7 -2 22 -15 36 -12 13 -17 26 -12 29 13 8 7 63 -12 106 -16 36 -42 64 -87 94 -14 9 -35 33 -45 54 -30 59 -76 125 -107 155 l-29 27 -344 0 c-201 0 -344 -4 -344 -9z" />
        <path d="M2926 2201 c-4 -5 -2 -12 3 -15 5 -4 12 -2 15 3 4 5 2 12 -3 15 -5 4 -12 2 -15 -3z" />
        <path d="M1407 2054 c-3 -3 -6 -102 -7 -218 l0 -213 35 -31 c65 -60 321 -286 423 -376 90 -78 104 -87 119 -75 15 12 16 12 11 -3 -9 -21 2 -31 19 -17 7 6 13 27 13 46 0 19 8 48 17 66 9 18 17 38 17 46 1 8 21 21 45 29 57 19 121 4 198 -44 63 -41 84 -27 27 19 -22 17 -52 45 -69 62 -37 37 -72 60 -107 68 -14 4 -37 21 -50 37 -46 60 -221 230 -291 283 -40 29 -108 80 -152 113 -44 32 -84 64 -90 70 -5 5 -36 27 -67 48 -35 24 -61 49 -67 67 -10 30 -14 33 -24 23z" />
        <path d="M2985 1226 c-11 -8 -14 -16 -8 -19 6 -4 18 0 28 7 11 8 14 16 8 19 -6 4 -18 0 -28 -7z" />
        <path d="M1776 1221 c-4 -5 -2 -12 3 -15 5 -4 12 -2 15 3 4 5 2 12 -3 15 -5 4 -12 2 -15 -3z" />
        <path d="M1916 1111 c-4 -5 -2 -12 3 -15 5 -4 12 -2 15 3 4 5 2 12 -3 15 -5 4 -12 2 -15 -3z" />
        <path d="M2005 1090 c-4 -6 -1 -18 7 -27 7 -9 12 -18 10 -20 -2 -3 16 -20 40 -38 53 -42 75 -30 29 16 -18 18 -39 43 -48 56 -17 25 -28 29 -38 13z" />
        <path d="M2516 1081 c-4 -5 -2 -12 3 -15 5 -4 12 -2 15 3 4 5 2 12 -3 15 -5 4 -12 2 -15 -3z" />
      </g>
      <g id="layer104" fill="#eba0df" stroke="none">
        <path d="M2682 1810 c-34 -45 -29 -82 16 -116 20 -16 82 -71 137 -122 118 -112 254 -222 272 -222 7 0 13 -6 13 -13 0 -20 23 -68 116 -245 27 -51 44 -96 44 -117 0 -18 4 -36 9 -39 5 -3 11 -23 14 -43 3 -21 13 -69 22 -108 9 -38 21 -89 26 -112 35 -167 48 -215 61 -230 8 -10 4 -13 -20 -13 -21 0 -35 -7 -46 -25 -17 -26 -12 -75 29 -300 8 -44 14 -86 15 -92 0 -10 25 -13 90 -13 89 0 90 0 85 23 -3 12 -18 85 -35 162 -33 153 -49 222 -57 245 -3 8 -5 24 -4 35 1 12 -30 147 -70 300 -39 154 -72 294 -74 310 -1 17 -17 55 -34 85 -17 30 -31 61 -31 68 0 7 -11 29 -25 50 -13 20 -39 66 -57 102 -24 46 -45 73 -75 93 -42 28 -69 28 -59 1 4 -11 2 -12 -14 -4 -30 16 -33 30 -7 30 14 0 30 7 38 16 14 17 23 7 -197 212 -69 63 -130 112 -141 112 -10 0 -29 -13 -41 -30z" />
        <path d="M2491 1383 c-22 -27 -36 -65 -29 -81 7 -18 221 -211 341 -307 190 -153 201 -160 240 -149 24 7 33 16 35 35 2 20 -12 39 -70 94 -234 223 -472 435 -489 435 -4 0 -17 -12 -28 -27z" />
        <path d="M2015 1230 c-8 -16 -15 -38 -15 -49 0 -31 190 -209 420 -394 88 -71 98 -77 142 -77 37 0 50 4 58 20 10 18 0 32 -82 118 -102 106 -272 261 -386 353 -84 68 -114 74 -137 29z m290 -270 c3 -5 4 -10 1 -10 -3 0 -8 5 -11 10 -3 6 -4 10 -1 10 3 0 8 -4 11 -10z" />
        <path d="M3236 191 c-4 -5 -2 -12 3 -15 5 -4 12 -2 15 3 4 5 2 12 -3 15 -5 4 -12 2 -15 -3z" />
        <path d="M3266 51 c-4 -5 -2 -12 3 -15 5 -4 12 -2 15 3 4 5 2 12 -3 15 -5 4 -12 2 -15 -3z" />
      </g>
      <g id="layer105" fill="#fdfafe" stroke="none">
        <path d="M790 2050 c-14 -10 -128 -82 -255 -160 -126 -78 -248 -155 -270 -170 -22 -15 -58 -37 -80 -49 -22 -12 -60 -37 -85 -56 -25 -18 -50 -34 -56 -34 -6 -1 -18 -9 -27 -19 -14 -15 -17 -42 -17 -153 1 -134 9 -169 40 -169 6 0 37 -17 68 -38 32 -22 89 -58 127 -82 39 -24 93 -57 120 -75 28 -17 79 -50 115 -72 36 -22 111 -69 167 -104 246 -154 264 -155 329 -22 29 59 26 84 -14 102 -25 12 -311 189 -440 273 -31 20 -77 48 -102 62 -107 61 -185 114 -185 127 0 12 129 99 310 210 28 17 109 68 180 114 72 47 160 103 197 125 94 57 96 75 19 168 -31 37 -40 42 -76 41 -23 0 -51 -8 -65 -19z" />
        <path d="M4212 2048 c-9 -11 -32 -38 -49 -58 -64 -73 -61 -76 257 -270 132 -81 245 -152 345 -215 28 -17 69 -44 93 -58 48 -31 51 -41 20 -60 -61 -37 -168 -103 -213 -132 -163 -106 -199 -128 -224 -138 -15 -7 -40 -22 -57 -35 -40 -32 -162 -110 -201 -128 -17 -9 -37 -28 -43 -44 -11 -26 -9 -35 13 -72 39 -66 64 -88 100 -88 33 0 54 11 197 100 47 29 111 69 143 89 133 82 163 100 318 199 89 56 166 102 172 102 28 0 36 37 37 171 0 119 -2 138 -18 152 -10 10 -23 17 -28 18 -5 0 -29 16 -54 34 -25 19 -63 44 -85 56 -22 12 -58 34 -80 49 -22 15 -143 92 -270 170 -126 78 -242 150 -257 161 -37 26 -93 25 -116 -3z" />
        <path d="M2600 1218 c0 -19 52 -68 72 -68 29 0 21 27 -15 54 -37 28 -57 33 -57 14z" />
        <path d="M2712 1114 c2 -9 13 -23 25 -31 20 -14 23 -14 29 1 8 21 -13 46 -38 46 -13 0 -18 -6 -16 -16z" />
        <path d="M2140 1081 c0 -20 109 -127 197 -192 128 -96 161 -115 176 -100 9 9 -8 31 -73 92 -83 79 -204 175 -247 198 -26 13 -53 14 -53 2z" />
      </g>
    </svg>
  );
};

export default StyledComponentsIcon;
