import React, { useContext, useEffect, useRef } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Link } from 'react-router-dom';

import FlightSimulatorCanvas from '../../../Libs/FlightSimulator/FlightSimulatorCanvas';

import { toVector, getRGBHeightVectors } from '../../../Constants/Colors';
import { WorkRoute } from '../../../Constants/Routes';
import { DisplayModeContext, MOBILE } from '../../../Constants/DisplayModes';

import useI18nConsumer from '../../../Hooks/useI18nConsumer';

import PageHeader from '../Generic/PageHeader';
import PaddedPage from '../Generic/PaddedPage';
import Blurb from '../Generic/Blurb';
import Portlet, { PortletTitle, PortletContent } from '../../Portlets/Generic/Portlet';
import UserControl from '../../Inputs/Generic/UserControl';
import FlatButton from '../../Inputs/Generic/FlatButton';
import PreviewWindow from '../../Inputs/Generic/PreviewWindow';

const StyledFlightSimulatorPage = styled(PaddedPage)`
  color: ${props => props.theme.foregroundWork.string()};
`;

const CanvasRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
`;

const StyledPreviewWindow = styled(PreviewWindow)`
  max-width: 500px;
`;

const Canvas = styled.canvas`
  height: 100%;
  width: 100%;
  border-radius: 40px;
`;

const StyledBlurb = styled(Blurb)`
  margin: 20px 0px;
`;

const StyledButtonWrapper = styled(Link)`
  align-self: flex-start;
`;

const StyledPortlet = styled(Portlet)`
  ${PortletTitle} {
    color: ${props => props.theme.foregroundWork.string()};
  }
  ${PortletContent} {
    color: ${props => props.theme.foregroundIdle.string()};
  }
`;

const FlightSimulatorPage = () => {
  const { t } = useI18nConsumer();
  const theme = useContext(ThemeContext);
  const displayMode = useContext(DisplayModeContext);

  const canvasRef = useRef();
  const flightSimulatorCanvasRef = useRef();

  useEffect(() => {
    const initialize = async () => {
      const { r, g, b } = getRGBHeightVectors(theme);

      flightSimulatorCanvasRef.current = new FlightSimulatorCanvas({
        canvas: canvasRef.current,
        clearColor: [...toVector(theme.background), 1],
        heightR: r,
        heightG: g,
        heightB: b,
      });

      const flightSimulatorCanvas = flightSimulatorCanvasRef.current;

      await flightSimulatorCanvas.initialize();

      flightSimulatorCanvas.tick();
    };

    initialize();

    return () => {
      const flightSimulatorCanvas = flightSimulatorCanvasRef.current;
      flightSimulatorCanvas.teardown();
    };
  }, [theme]);

  const renderBackButton = () => {
    const primaryColor = theme.foregroundWork.string();
    return (
      <StyledButtonWrapper to={WorkRoute}>
        <FlatButton primaryColor={primaryColor}>{t('back')}</FlatButton>
      </StyledButtonWrapper>
    );
  };

  const renderCanvas = () => {
    return (
      <CanvasRow>
        <StyledPreviewWindow showBorder={false} supportHover={false}>
          <Canvas ref={canvasRef} width="1000" height="750" />
        </StyledPreviewWindow>
      </CanvasRow>
    );
  };

  const renderDescription = () => {
    return <StyledBlurb>{t('FlightSimulatorPage.blurb')}</StyledBlurb>;
  };

  const renderControls = () => {
    const controls = [
      {
        id: 'rollKeys',
        contentLeft: 'a, d',
        contentRight: t('FlightSimulatorPage.controlDesc.rollPlane'),
      },
      {
        id: 'pitchKeys',
        contentLeft: 'w, s',
        contentRight: t('FlightSimulatorPage.controlDesc.pitchPlane'),
      },
      {
        id: 'speedKeys',
        contentLeft: '+, -',
        contentRight: t('FlightSimulatorPage.controlDesc.increaseDecreaseSpeed'),
      },
      {
        id: 'generateKeys',
        contentLeft: 'n',
        contentRight: t('FlightSimulatorPage.controlDesc.generateTerrain'),
      },
      {
        id: 'restartKeys',
        contentLeft: 'r',
        contentRight: t('FlightSimulatorPage.controlDesc.restartFlight'),
      },
    ];

    const title =
      displayMode !== MOBILE
        ? t('FlightSimulatorPage.controls')
        : t('FlightSimulatorPage.controlsMobileComingSoon');
    return (
      <StyledPortlet title={title}>
        {controls.map(control => {
          const { id, contentLeft, contentRight } = control;
          return <UserControl key={id} contentLeft={contentLeft} contentRight={contentRight} />;
        })}
      </StyledPortlet>
    );
  };

  return (
    <StyledFlightSimulatorPage>
      <PageHeader title={t('FlightSimulatorPage.flightSimulator')} />
      {renderBackButton()}
      {renderCanvas()}
      {renderDescription()}
      {renderControls()}
    </StyledFlightSimulatorPage>
  );
};

export default FlightSimulatorPage;
