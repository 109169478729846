import { mat3, mat4, vec3 } from 'gl-matrix';

import posX from './canary/pos-x.jpg';
import negX from './canary/neg-x.jpg';
import posY from './canary/pos-y.jpg';
import negY from './canary/neg-y.jpg';
import posZ from './canary/pos-z.jpg';
import negZ from './canary/neg-z.jpg';

import { degToRad, getNormals } from '../webGLUtils';
import teapotObjSrc from './teapot_0.obj';
import { vertexShaderSrc, fragmentShaderSrc } from './TeapotShaders';

import GLCanvas from '../GLCanvas';

const getCubeImages = () => {
  const cubeImageSrcs = [posX, negX, posY, negY, posZ, negZ];
  const cubeImagePromises = cubeImageSrcs.map(cubeImageSrc => {
    return new Promise(resolve => {
      const newImage = new Image();
      newImage.onload = () => resolve(newImage);
      newImage.src = cubeImageSrc;
    });
  });

  return Promise.all(cubeImagePromises);
};

const readTextFile = file => {
  return new Promise(resolve => {
    const rawFile = new XMLHttpRequest();
    rawFile.open('GET', file, true);

    rawFile.onreadystatechange = () => {
      if (rawFile.readyState === 4) {
        if (rawFile.status === 200 || rawFile.status === 0) {
          resolve(rawFile.responseText);
        }
      }
    };
    rawFile.send(null);
  });
};

export default class TeapotCanvas extends GLCanvas {
  constructor({ canvas }) {
    super({ canvas });

    // cube
    this.cubeVertexBuffer = null;
    this.cubeNormalBuffer = null;
    this.cubeTriIndexBuffer = null;
    this.cubeTexture = null;

    // teapot
    this.teaVertexBuffer = null;
    this.teaNormalBuffer = null;
    this.teaIndexBuffer = null;
    this.teaFaceN = 0;

    // state
    this.setState({
      reflect: true,
      blinnPhong: false,
      teaYRotation: 0,
    });
  }

  initialize = async () => {
    this.setupShaders();
    await this.setupBuffers();
    await this.setupTextures();

    super.initialize();
  };

  setupShaders = () => {
    super.setupShaders({ vertexShaderSrc, fragmentShaderSrc });

    this.shaderProgram.vertexPositionAttribute = this.gl.getAttribLocation(
      this.shaderProgram,
      'aPosition'
    );
    this.gl.enableVertexAttribArray(this.shaderProgram.vertexPositionAttribute);

    this.shaderProgram.vertexNormalAttribute = this.gl.getAttribLocation(
      this.shaderProgram,
      'aNormal'
    );
    this.gl.enableVertexAttribArray(this.shaderProgram.vertexNormalAttribute);

    this.shaderProgram.uCubeSampler = this.gl.getUniformLocation(
      this.shaderProgram,
      'uCubeSampler'
    );
    this.shaderProgram.uIsCube = this.gl.getUniformLocation(this.shaderProgram, 'uIsCube');
    this.shaderProgram.uToReflect = this.gl.getUniformLocation(this.shaderProgram, 'uToReflect');
    this.shaderProgram.uToBP = this.gl.getUniformLocation(this.shaderProgram, 'uToBP');

    this.shaderProgram.mvMatrixUniform = this.gl.getUniformLocation(
      this.shaderProgram,
      'uMVMatrix'
    );
    this.shaderProgram.pMatrixUniform = this.gl.getUniformLocation(this.shaderProgram, 'uPMatrix');
    this.shaderProgram.nMatrixUniform = this.gl.getUniformLocation(this.shaderProgram, 'uNMatrix');
    this.shaderProgram.inverseViewTransform = this.gl.getUniformLocation(
      this.shaderProgram,
      'inverseViewTransform'
    );
    this.shaderProgram.uniformLightPositionLoc = this.gl.getUniformLocation(
      this.shaderProgram,
      'uLightPosition'
    );
    this.shaderProgram.uniformAmbientLightColorLoc = this.gl.getUniformLocation(
      this.shaderProgram,
      'uAmbientLightColor'
    );
    this.shaderProgram.uniformDiffuseLightColorLoc = this.gl.getUniformLocation(
      this.shaderProgram,
      'uDiffuseLightColor'
    );
    this.shaderProgram.uniformSpecularLightColorLoc = this.gl.getUniformLocation(
      this.shaderProgram,
      'uSpecularLightColor'
    );
    this.shaderProgram.uniformDiffuseMaterialColor = this.gl.getUniformLocation(
      this.shaderProgram,
      'uDiffuseMaterialColor'
    );
    this.shaderProgram.uniformAmbientMaterialColor = this.gl.getUniformLocation(
      this.shaderProgram,
      'uAmbientMaterialColor'
    );
    this.shaderProgram.uniformSpecularMaterialColor = this.gl.getUniformLocation(
      this.shaderProgram,
      'uSpecularMaterialColor'
    );
    this.shaderProgram.uniformShininess = this.gl.getUniformLocation(
      this.shaderProgram,
      'uShininess'
    );
    this.shaderProgram.uViewPt = this.gl.getUniformLocation(this.shaderProgram, 'uViewPt');
  };

  setupTeapotBuffers = async () => {
    const text = await readTextFile(teapotObjSrc);

    const lines = text.split('\n');
    const vertices = [];
    const faces = [];

    this.teaFaceN = 0;

    for (let i = 0; i < lines.length; i += 1) {
      const line = lines[i];
      const vals = line.split(/ +/);
      if (!(line[0] === '#' || line[0] === 'g')) {
        if (line[0] === 'v') {
          vertices.push(parseFloat(vals[1]));
          vertices.push(parseFloat(vals[2]));
          vertices.push(parseFloat(vals[3]));
        } else if (line[0] === 'f') {
          faces.push(parseInt(vals[1], 10) - 1);
          faces.push(parseInt(vals[2], 10) - 1);
          faces.push(parseInt(vals[3], 10) - 1);
          this.teaFaceN += 3;
        }
      }
    }

    this.teaVertexBuffer = this.gl.createBuffer();
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.teaVertexBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(vertices), this.gl.STATIC_DRAW);

    this.teaIndexBuffer = this.gl.createBuffer();
    this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this.teaIndexBuffer);
    this.gl.bufferData(this.gl.ELEMENT_ARRAY_BUFFER, new Uint16Array(faces), this.gl.STATIC_DRAW);

    this.teaNormalBuffer = this.gl.createBuffer();
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.teaNormalBuffer);
    this.teaNorms = getNormals({ faceArray: faces, vertexArray: vertices });
    // Now send the element array to GL

    this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(this.teaNorms), this.gl.STATIC_DRAW);
  };

  setupBuffers = async () => {
    await this.setupTeapotBuffers();
    // Create a buffer for the cube's vertices.

    this.cubeVertexBuffer = this.gl.createBuffer();

    // Select the cubeVerticesBuffer as the one to apply vertex
    // operations to from here out.

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.cubeVertexBuffer);

    // Now create an array of vertices for the cube.

    const vertices = [
      // Right face
      1.0,
      -1.0,
      -1.0,
      1.0,
      1.0,
      -1.0,
      1.0,
      1.0,
      1.0,
      1.0,
      -1.0,
      1.0,

      // Left face
      -1.0,
      -1.0,
      -1.0,
      -1.0,
      -1.0,
      1.0,
      -1.0,
      1.0,
      1.0,
      -1.0,
      1.0,
      -1.0,

      // Top face
      -1.0,
      1.0,
      -1.0,
      -1.0,
      1.0,
      1.0,
      1.0,
      1.0,
      1.0,
      1.0,
      1.0,
      -1.0,

      // Bottom face
      -1.0,
      -1.0,
      -1.0,
      1.0,
      -1.0,
      -1.0,
      1.0,
      -1.0,
      1.0,
      -1.0,
      -1.0,
      1.0,

      // Front face
      -1.0,
      -1.0,
      1.0,
      1.0,
      -1.0,
      1.0,
      1.0,
      1.0,
      1.0,
      -1.0,
      1.0,
      1.0,

      // Back face
      -1.0,
      -1.0,
      -1.0,
      -1.0,
      1.0,
      -1.0,
      1.0,
      1.0,
      -1.0,
      1.0,
      -1.0,
      -1.0,
    ];

    // Now pass the list of vertices into WebGL to build the shape. We
    // do this by creating a Float32Array from the JavaScript array,
    // then use it to fill the current vertex buffer.

    this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(vertices), this.gl.STATIC_DRAW);

    // Map the texture onto the cube's faces.

    const cubeTCoordBuffer = this.gl.createBuffer();
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, cubeTCoordBuffer);

    const textureCoordinates = [
      // Front
      0.0,
      0.0,
      1.0,
      0.0,
      1.0,
      1.0,
      0.0,
      1.0,
      // Back
      0.0,
      0.0,
      1.0,
      0.0,
      1.0,
      1.0,
      0.0,
      1.0,
      // Top
      0.0,
      0.0,
      1.0,
      0.0,
      1.0,
      1.0,
      0.0,
      1.0,
      // Bottom
      0.0,
      0.0,
      1.0,
      0.0,
      1.0,
      1.0,
      0.0,
      1.0,
      // Right
      0.0,
      0.0,
      1.0,
      0.0,
      1.0,
      1.0,
      0.0,
      1.0,
      // Left
      0.0,
      0.0,
      1.0,
      0.0,
      1.0,
      1.0,
      0.0,
      1.0,
    ];

    this.gl.bufferData(
      this.gl.ARRAY_BUFFER,
      new Float32Array(textureCoordinates),
      this.gl.STATIC_DRAW
    );

    // Build the element array buffer; this specifies the indices
    // into the vertex array for each face's vertices.

    this.cubeTriIndexBuffer = this.gl.createBuffer();
    this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this.cubeTriIndexBuffer);

    // This array defines each face as two triangles, using the
    // indices into the vertex array to specify each triangle's
    // position.

    const cubeVertexIndices = [
      0,
      1,
      2,
      0,
      2,
      3, // front
      4,
      5,
      6,
      4,
      6,
      7, // back
      8,
      9,
      10,
      8,
      10,
      11, // top
      12,
      13,
      14,
      12,
      14,
      15, // bottom
      16,
      17,
      18,
      16,
      18,
      19, // right
      20,
      21,
      22,
      20,
      22,
      23, // left
    ];

    // Now send the element array to GL

    this.gl.bufferData(
      this.gl.ELEMENT_ARRAY_BUFFER,
      new Uint16Array(cubeVertexIndices),
      this.gl.STATIC_DRAW
    );

    this.cubeNormalBuffer = this.gl.createBuffer();
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.cubeNormalBuffer);

    const cubeNorms = getNormals({ faceArray: cubeVertexIndices, vertexArray: vertices });
    // Now send the element array to GL

    this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(cubeNorms), this.gl.STATIC_DRAW);
  };

  setupTextures = async () => {
    const isPowerOf2 = value => {
      return (value & (value - 1)) === 0; // eslint-disable-line no-bitwise
    };

    const cubeImages = await getCubeImages();

    this.cubeTexture = this.gl.createTexture();
    this.gl.bindTexture(this.gl.TEXTURE_CUBE_MAP, this.cubeTexture);

    for (let i = 0; i < 6; i += 1) {
      this.gl.pixelStorei(this.gl.UNPACK_FLIP_Y_WEBGL, false);
      this.gl.texImage2D(
        this.gl.TEXTURE_CUBE_MAP_POSITIVE_X + i,
        0,
        this.gl.RGBA,
        this.gl.RGBA,
        this.gl.UNSIGNED_BYTE,
        cubeImages[i]
      );
      // Check if the image is a power of 2 in both dimensions.
      if (isPowerOf2(cubeImages[i].width) && isPowerOf2(cubeImages[i].height)) {
        // Yes, it's a power of 2. Generate mips.
      } else {
        // No, it's not a power of 2. Turn of mips and set wrapping to clamp to edge
        this.gl.texParameteri(
          this.gl.TEXTURE_CUBE_MAP,
          this.gl.TEXTURE_WRAP_S,
          this.gl.CLAMP_TO_EDGE
        );
        this.gl.texParameteri(
          this.gl.TEXTURE_CUBE_MAP,
          this.gl.TEXTURE_WRAP_T,
          this.gl.CLAMP_TO_EDGE
        );
        this.gl.texParameteri(this.gl.TEXTURE_CUBE_MAP, this.gl.TEXTURE_MIN_FILTER, this.gl.LINEAR);
      }
      this.gl.texParameteri(this.gl.TEXTURE_CUBE_MAP, this.gl.TEXTURE_MAG_FILTER, this.gl.LINEAR);
      this.gl.texParameteri(
        this.gl.TEXTURE_CUBE_MAP,
        this.gl.TEXTURE_MIN_FILTER,
        this.gl.LINEAR_MIPMAP_NEAREST
      );
    }
    this.gl.generateMipmap(this.gl.TEXTURE_CUBE_MAP);
  };

  handleKeys = () => {
    const { currentlyPressedKeys } = this.state;

    if (currentlyPressedKeys.KeyA) {
      this.orbit({ dir: 'left' });
    }
    if (currentlyPressedKeys.KeyD) {
      this.orbit({ dir: 'right' });
    }
    if (currentlyPressedKeys.KeyW) {
      this.orbit({ dir: 'up' });
    }
    if (currentlyPressedKeys.KeyS) {
      this.orbit({ dir: 'down' });
    }
    if (currentlyPressedKeys.ArrowRight) {
      this.setState(prevState => ({
        teaYRotation: prevState.teaYRotation + 1,
      }));
    }
    if (currentlyPressedKeys.ArrowLeft) {
      this.setState(prevState => ({
        teaYRotation: prevState.teaYRotation - 1,
      }));
    }
    if (currentlyPressedKeys.KeyR) {
      this.resetCamera();
    }
  };

  resetCamera = () => {
    super.resetCamera();

    this.setState({
      teaYRotation: 0,
    });
  };

  drawTeapot = () => {
    const { reflect, blinnPhong } = this.state;
    // Draw the cube by binding the array buffer to the cube's vertices
    // array, setting attributes, and pushing it to GL.
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.teaVertexBuffer);
    this.gl.vertexAttribPointer(
      this.shaderProgram.vertexPositionAttribute,
      3,
      this.gl.FLOAT,
      false,
      0,
      0
    );

    // Bind normal buffer
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.teaNormalBuffer);
    this.gl.vertexAttribPointer(
      this.shaderProgram.vertexNormalAttribute,
      3,
      this.gl.FLOAT,
      false,
      0,
      0
    );

    this.gl.uniform1i(this.shaderProgram.uIsCube, false);

    this.gl.uniform1i(this.shaderProgram.uToReflect, reflect);

    this.gl.uniform1i(this.shaderProgram.uToBP, blinnPhong);

    // Draw the cube.
    this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this.teaIndexBuffer);
    this.setMatrixUniforms();
    this.gl.drawElements(this.gl.TRIANGLES, this.teaFaceN, this.gl.UNSIGNED_SHORT, 0);
  };

  drawCube = () => {
    // Draw the cube by binding the array buffer to the cube's vertices
    // array, setting attributes, and pushing it to GL.

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.cubeVertexBuffer);
    this.gl.vertexAttribPointer(
      this.shaderProgram.vertexPositionAttribute,
      3,
      this.gl.FLOAT,
      false,
      0,
      0
    );

    // Specify the texture to map onto the faces.
    this.gl.activeTexture(this.gl.TEXTURE1);
    this.gl.bindTexture(this.gl.TEXTURE_CUBE_MAP, this.cubeTexture);
    this.gl.uniform1i(this.shaderProgram.uCubeSampler, 1);

    this.gl.uniform1i(this.shaderProgram.uIsCube, true);
    this.gl.uniform1i(this.shaderProgram.uToReflect, false);

    // Bind normal buffer
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.cubeNormalBuffer);
    this.gl.vertexAttribPointer(
      this.shaderProgram.vertexNormalAttribute,
      3,
      this.gl.FLOAT,
      false,
      0,
      0
    );

    // Draw the cube.
    this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this.cubeTriIndexBuffer);
    this.setMatrixUniforms();
    this.gl.drawElements(this.gl.TRIANGLES, 36, this.gl.UNSIGNED_SHORT, 0);

    this.setMatrixUniforms();
  };

  draw = () => {
    const { teaYRotation } = this.state;
    const transformVec = vec3.create();

    this.gl.viewport(0, 0, this.gl.viewportWidth, this.gl.viewportHeight);
    this.gl.clear(this.gl.COLOR_BUFFER_BIT | this.gl.DEPTH_BUFFER_BIT); // eslint-disable-line no-bitwise

    // We'll use perspective
    mat4.perspective(
      this.pMatrix,
      degToRad(45),
      this.gl.viewportWidth / this.gl.viewportHeight,
      0.1,
      200.0
    );

    // We want to look down -z, so create a lookat point in that direction
    vec3.add(this.viewPt, this.eyePt, this.viewDir);
    // Then generate the lookat matrix and initialize the MV matrix to that view
    mat4.lookAt(this.mvMatrix, this.eyePt, this.viewPt, this.up);

    // Draw Terrain
    this.mvPushMatrix();
    this.mvPushMatrix();

    mat4.translate(this.mvMatrix, this.mvMatrix, transformVec);

    const yRotation = 0;
    this.gl.uniform1i(this.shaderProgram.uTheta, degToRad(yRotation));

    mat3.fromMat4(this.inverseViewTransform, this.mvMatrix);
    mat3.invert(this.inverseViewTransform, this.inverseViewTransform);

    this.uploadLightsToShader({
      loc: [this.lightPt[0], this.lightPt[1], this.lightPt[2]],
      a: [0.5, 0.4, 0.2],
      d: [0.7, 0.7, 0.6],
      s: [0.5, 0.7, 0.9],
    });

    const R = 255.0 / 255.0;
    const G = 200.0 / 255.0;
    const B = 100.0 / 255.0;
    const dcolor = [R, G, B];
    const acolor = [R, G, B];
    const scolor = [0.5, 0.5, 0.7];
    const shiny = 60;

    this.uploadMaterialToShader({ dcolor, acolor, scolor, shiny });

    this.drawCube();
    this.mvPopMatrix();
    mat4.rotateY(this.mvMatrix, this.mvMatrix, degToRad(teaYRotation));

    mat4.scale(this.mvMatrix, this.mvMatrix, vec3.fromValues(0.1, 0.1, 0.1));

    this.drawTeapot();
    this.mvPopMatrix();
  };
}
