import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledPreactIcon = styled.svg`
  display: inline-block;
  margin: -0.25em 0 0;
  vertical-align: middle;
`;

const PreactIcon = ({ className = '' }) => {
  return (
    <StyledPreactIcon className={className} viewBox="-256 -256 512 512" title="Preact">
      <path
        d="M0,-256 221.7025033688164,-128 221.7025033688164,128 0,256 -221.7025033688164,128 -221.7025033688164,-128z"
        fill="white"
      />
      <ellipse
        cx="0"
        cy="0"
        rx="75px"
        ry="196px"
        strokeWidth="16px"
        strokeDasharray="372.87888203504 74.12111796495998"
        strokeDashoffset="4347.484797949738"
        fill="none"
        stroke="#673ab8"
        transform="rotate(52)"
      />
      <ellipse
        cx="0"
        cy="0"
        rx="75px"
        ry="196px"
        strokeWidth="16px"
        strokeDasharray="415.78768417258146 31.212315827418546"
        strokeDashoffset="-2982.802156510451"
        fill="none"
        stroke="#673ab8"
        transform="rotate(-52)"
      />
      <circle cx="0" cy="0" r="34" fill="#673ab8" />
    </StyledPreactIcon>
  );
};

PreactIcon.propTypes = {
  className: PropTypes.string,
};

export default PreactIcon;
