import { useCallback, useEffect, useState, useRef } from 'react';
import uuid from 'uuid';

const useImageLoaded = (src = '') => {
  const elementId = useRef('');
  const imgNode = useRef(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoaded = useCallback(() => setImageLoaded(true), []);

  useEffect(() => {
    elementId.current = uuid.v4();
    imgNode.current = window.document.createElement('img');
    imgNode.current.setAttribute('id', elementId.current);
    imgNode.current.style.display = 'none';

    const rootNode = window.document.getElementById('root');

    if (rootNode !== null) {
      rootNode.appendChild(imgNode.current);
    }

    return () => {
      try {
        const removeNode = window.document.getElementById(elementId.current);

        if (rootNode !== null) {
          rootNode.removeChild(removeNode);
        }
      } catch (err) {
        console.error(err);
      }
    };
  }, []);

  useEffect(() => {
    imgNode.current = new Image();
    imgNode.current.src = src;

    if (imgNode.current.complete) {
      handleImageLoaded();
      return () => {};
    }

    imgNode.current.addEventListener('load', handleImageLoaded);
    return () => imgNode.current.removeEventListener('load', handleImageLoaded);
  }, [handleImageLoaded, src]);

  return imageLoaded;
};

export default useImageLoaded;
