import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';

import {
  PALETTE_EASE_IN,
  HOVER_COLOR_EASE_IN,
  HOVER_SIZE_EASE_IN,
} from '../../Constants/TransitionCurves';

import LabeledIconButton from '../Icons/Generic/LabeledIconButton';

const StyledMainLinks = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledLabeledIconButton = styled(LabeledIconButton)`
  transition: ${PALETTE_EASE_IN}, ${HOVER_COLOR_EASE_IN};
  color: ${props =>
    props.highlighted ? props.highlightColor : props.theme.foregroundIdle.string()};

  svg {
    transition: ${HOVER_SIZE_EASE_IN};
  }

  :hover {
    color: ${props => props.highlightColor};

    svg {
      height: 35px;
      width: 35px;
    }
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const MainLinks = ({ className = '', buttons = [] }) => {
  const location = useLocation();
  const primaryPath = `/${location.pathname.split('/')[1]}`;

  const renderLinks = () => {
    return buttons.map(button => {
      const { id, renderIcon, highlightColor, route, label, onClick, showLabel } = button;

      const isCurrentRoute = primaryPath === route;

      const ButtonComponent = (
        <StyledLabeledIconButton
          key={id}
          renderIcon={renderIcon}
          label={label}
          highlighted={isCurrentRoute}
          highlightColor={highlightColor}
          onClick={onClick}
          showLabel={showLabel}
        />
      );

      if (route) {
        return (
          <StyledLink key={id} to={route} aria-label={label}>
            {ButtonComponent}
          </StyledLink>
        );
      }
      return ButtonComponent;
    });
  };

  return <StyledMainLinks className={className}>{renderLinks()}</StyledMainLinks>;
};

MainLinks.propTypes = {
  className: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      renderIcon: PropTypes.func,
      label: PropTypes.string,
      highlightColor: PropTypes.string,
      route: PropTypes.string,
      showLabel: PropTypes.bool,
    })
  ),
};

export default MainLinks;
