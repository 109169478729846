import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Switch, Route, Link, useRouteMatch } from 'react-router-dom';

import { AboutRoute, CVRoute } from '../../Constants/Routes';

import useI18nConsumer, { Trans } from '../../Hooks/useI18nConsumer';

import CVPage from './About/CVPage';

import PageHeader from './Generic/PageHeader';
import PaddedPage from './Generic/PaddedPage';
import PageShadow from './Generic/PageShadow';
import Blurb from './Generic/Blurb';
import FlatButton from '../Inputs/Generic/FlatButton';

const DefaultAboutPage = styled(PaddedPage)`
  color: ${props => props.theme.foregroundAbout.string()};
`;

const StyledBlurb = styled(Blurb)`
  font-size: 1.1em;
`;

const UnorderedList = styled.ul`
  color: ${props => props.theme.foreground.string()};
  list-style-type: none;
  margin: 10px 0;
`;

const Date = styled.strong`
  color: ${props => props.theme.foregroundAbout.string()};
  font-weight: bold;
`;

const Li = styled.li`
  margin: 7.5px 0;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const StyledFlatButton = styled(FlatButton)`
  font-size: 1.5em;
`;

const Footer = styled.div`
  margin-bottom: 20px;
`;

const AboutPage = () => {
  const { t } = useI18nConsumer();
  const theme = useContext(ThemeContext);
  const match = useRouteMatch();

  const renderBlurbs = () => {
    return (
      <>
        <StyledBlurb>{t('AboutPage.blurb.1')}</StyledBlurb>
        <StyledBlurb>{t('AboutPage.blurb.2')}</StyledBlurb>
        <StyledBlurb>{t('AboutPage.blurb.3')}</StyledBlurb>
      </>
    );
  };

  const renderHistory = () => {
    const history = [
      {
        id: 'university',
        i18nKey: 'AboutPage.history.university',
      },
      {
        id: 'infiniteConvergence',
        i18nKey: 'AboutPage.history.infiniteConvergence',
      },
      {
        id: 'qualia',
        i18nKey: 'AboutPage.history.qualia',
      },
    ];

    return (
      <UnorderedList>
        {history.map(historyItem => {
          const { id, i18nKey } = historyItem;

          return (
            <Li key={id}>
              <Trans
                i18nKey={i18nKey}
                defaults="<0>Some Year</0> - Some Description"
                components={[<Date />]}
              />
            </Li>
          );
        })}
      </UnorderedList>
    );
  };

  const renderFooter = () => {
    return (
      <Footer>
        <StyledBlurb>{t('AboutPage.history.etc')}</StyledBlurb>
      </Footer>
    );
  };

  const renderCVButton = () => {
    const primaryColor = theme.foregroundAbout.string();
    return (
      <ButtonRow>
        <StyledLink to={`${AboutRoute}${CVRoute}`}>
          <StyledFlatButton primaryColor={primaryColor}>{t('AboutPage.myCV')}</StyledFlatButton>
        </StyledLink>
      </ButtonRow>
    );
  };

  const renderDefaultAboutPage = () => {
    return (
      <DefaultAboutPage>
        <PageHeader title={t('AboutPage.about')} />
        {renderBlurbs()}
        {renderHistory()}
        {renderFooter()}
        {renderCVButton()}
      </DefaultAboutPage>
    );
  };

  return (
    <div>
      <PaddedPage>
        <PageShadow>
          <Trans i18nKey="AboutPage.pageShadow" defaults="Abo<0/>ut Me" components={[<br />]} />
        </PageShadow>
      </PaddedPage>
      <Switch>
        <Route path={`${match.path}${CVRoute}`}>
          <CVPage />
        </Route>
        <Route>{renderDefaultAboutPage()}</Route>
      </Switch>
    </div>
  );
};

export default AboutPage;
