import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Z_PAGE_SHADOW } from '../../../Constants/ZIndicies';

const StyledPageShadow = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  z-index: ${Z_PAGE_SHADOW};
  font-size: 16.24em;
  font-family: 'Open Sans';
  color: ${props => props.theme.foregroundIdle.string()};
  opacity: 0.065;
  line-height: 0.8;
  white-space: nowrap;
  user-select: none;
`;

const PageShadow = ({ className = '', children = null }) => {
  return <StyledPageShadow className={className}>{children}</StyledPageShadow>;
};

PageShadow.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default PageShadow;
