import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { detect } from 'detect-browser';

import SkillButton from '../../Inputs/SkillButton';

import Portlet, { PortletContent } from './Portlet';

const browser = detect() || {};

const StyledSkillGroupPortlet = styled(Portlet)`
  ${PortletContent} {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: ${['edge', 'ie'].includes(browser.name) ? 'space-around' : 'space-evenly'};
  }
`;

const SkillGroupPortlet = ({ className = '', title = '', buttons = [] }) => {
  return (
    <StyledSkillGroupPortlet className={className} title={title}>
      {buttons.map(button => {
        const { id, icon, href, hoverText } = button;

        return <SkillButton key={id} icon={icon} href={href} hoverText={hoverText} />;
      })}
    </StyledSkillGroupPortlet>
  );
};

SkillGroupPortlet.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,

  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      icon: PropTypes.element,
      href: PropTypes.string,
      hoverText: PropTypes.string,
    })
  ),
};

export default SkillGroupPortlet;
