import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  PALETTE_EASE_IN,
  HOVER_COLOR_EASE_IN,
  HOVER_SIZE_EASE_IN,
} from '../../Constants/TransitionCurves';

import ExpandHamburgerIcon from '../Icons/ExpandHamburgerIcon';

const StyledMenuChevron = styled(ExpandHamburgerIcon)`
  transition: ${PALETTE_EASE_IN}, ${HOVER_COLOR_EASE_IN};
  color: ${props => props.theme.foreground.string()};

  svg {
    transition: ${HOVER_SIZE_EASE_IN};
  }

  :hover {
    color: ${props => props.theme.foreground.string()};

    svg {
      height: 35px;
      width: 35px;
    }
  }
`;

const MenuExpandHamburger = ({ className = '', direction = 'right', onClick = () => {} }) => {
  const spring = { transform: direction === 'right' ? 'rotateY(0turn)' : 'rotateY(0.5turn)' };

  return <StyledMenuChevron className={className} spring={spring} onClick={onClick} />;
};

MenuExpandHamburger.propTypes = {
  className: PropTypes.string,

  direction: PropTypes.oneOf(['right', 'left']),
  onClick: PropTypes.func,
};

export default MenuExpandHamburger;

