import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring';

const StyledIconButton = styled(({ buttonSize, ...otherProps }) => (
  <animated.div {...otherProps} />
))`
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: ${props => props.buttonSize};
  min-width: ${props => props.buttonSize};
`;

const IconButton = ({
  baseRef = () => {},
  className = '',
  spring = {},
  buttonSize = '60px',
  renderIcon = () => {},
  onClick = () => {},
}) => {
  const springProps = useSpring(spring);

  return (
    <StyledIconButton
      className={className}
      ref={baseRef}
      style={springProps}
      buttonSize={buttonSize}
      onClick={onClick}
    >
      {renderIcon()}
    </StyledIconButton>
  );
};

IconButton.propTypes = {
  baseRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  className: PropTypes.string,
  spring: PropTypes.objectOf(PropTypes.any),

  buttonSize: PropTypes.string,
  renderIcon: PropTypes.func,

  onClick: PropTypes.func,
};

export default forwardRef((props, ref) => <IconButton {...props} baseRef={ref} />);
