import { mat4, quat, vec3 } from 'gl-matrix';
import GLCanvas from '../GLCanvas';

import { degToRad, generateLinesFromIndexedTriangles, terrainFromIteration } from '../webGLUtils';
import { vertexShaderWithRGBHeights, fragmentShaderSrc } from './FlightSimulatorShaders';

export default class FlightSimulatorCanvas extends GLCanvas {
  constructor({
    canvas,
    initialSpeed = 0.001,
    initialPitchSpeed = 0.005,
    initialRotationSpeed = 0.005,
    initialAccel = 0.001 / 20,

    clearColor = [0.0, 0.0, 0.0, 1.0],
    heightR,
    heightG,
    heightB,
  }) {
    super({ canvas, eyePt: [0.0, 0.0, 0.0], clearColor });

    // terrain
    this.tVertexPositionBuffer = null;
    this.tVertexNormalBuffer = null;
    this.tIndexTriBuffer = null;
    this.tIndexEdgeBuffer = null;

    // colors
    this.heightR = heightR;
    this.heightG = heightG;
    this.heightB = heightB;

    this.resetState = () => {
      this.setState({
        speed: initialSpeed,
        pitchSpeed: initialPitchSpeed,
        rotationSpeed: initialRotationSpeed,
        accel: initialAccel,
      });
    };

    this.resetState();
  }

  setupShaders = () => {
    super.setupShaders({
      vertexShaderSrc: vertexShaderWithRGBHeights({
        r: this.heightR,
        g: this.heightG,
        b: this.heightB,
      }),
      fragmentShaderSrc,
    });

    this.shaderProgram.vertexPositionAttribute = this.gl.getAttribLocation(
      this.shaderProgram,
      'aVertexPosition'
    );
    this.gl.enableVertexAttribArray(this.shaderProgram.vertexPositionAttribute);

    this.shaderProgram.vertexNormalAttribute = this.gl.getAttribLocation(
      this.shaderProgram,
      'aVertexNormal'
    );
    this.gl.enableVertexAttribArray(this.shaderProgram.vertexNormalAttribute);

    this.shaderProgram.mvMatrixUniform = this.gl.getUniformLocation(
      this.shaderProgram,
      'uMVMatrix'
    );
    this.shaderProgram.pMatrixUniform = this.gl.getUniformLocation(this.shaderProgram, 'uPMatrix');
    this.shaderProgram.nMatrixUniform = this.gl.getUniformLocation(this.shaderProgram, 'uNMatrix');
    this.shaderProgram.uniformLightPositionLoc = this.gl.getUniformLocation(
      this.shaderProgram,
      'uLightPosition'
    );
    this.shaderProgram.uniformAmbientLightColorLoc = this.gl.getUniformLocation(
      this.shaderProgram,
      'uAmbientLightColor'
    );
    this.shaderProgram.uniformDiffuseLightColorLoc = this.gl.getUniformLocation(
      this.shaderProgram,
      'uDiffuseLightColor'
    );
    this.shaderProgram.uniformSpecularLightColorLoc = this.gl.getUniformLocation(
      this.shaderProgram,
      'uSpecularLightColor'
    );
    this.shaderProgram.uniformDiffuseMaterialColor = this.gl.getUniformLocation(
      this.shaderProgram,
      'uDiffuseMaterialColor'
    );
    this.shaderProgram.uniformAmbientMaterialColor = this.gl.getUniformLocation(
      this.shaderProgram,
      'uAmbientMaterialColor'
    );
    this.shaderProgram.uniformSpecularMaterialColor = this.gl.getUniformLocation(
      this.shaderProgram,
      'uSpecularMaterialColor'
    );

    this.shaderProgram.uniformShininess = this.gl.getUniformLocation(
      this.shaderProgram,
      'uShininess'
    );
  };

  setupBuffers = () => {
    this.setupTerrainBuffers();
  };

  setupTerrainBuffers = () => {
    const eTerrain = [];
    const n = 7;
    const gridN = 2 ** n;

    const {
      numT,
      vertexArray: vTerrain,
      faceArray: fTerrain,
      normalArray: nTerrain,
    } = terrainFromIteration({ n: gridN, minX: -1, maxX: 1, minY: -1, maxY: 1 });

    this.tVertexPositionBuffer = this.gl.createBuffer();
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.tVertexPositionBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(vTerrain), this.gl.STATIC_DRAW);
    this.tVertexPositionBuffer.itemSize = 3;
    this.tVertexPositionBuffer.numItems = (gridN + 1) * (gridN + 1);

    // Specify normals to be able to do lighting calculations
    this.tVertexNormalBuffer = this.gl.createBuffer();
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.tVertexNormalBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(nTerrain), this.gl.STATIC_DRAW);
    this.tVertexNormalBuffer.itemSize = 3;
    this.tVertexNormalBuffer.numItems = (gridN + 1) * (gridN + 1);

    // Specify faces of the terrain
    this.tIndexTriBuffer = this.gl.createBuffer();
    this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this.tIndexTriBuffer);
    this.gl.bufferData(
      this.gl.ELEMENT_ARRAY_BUFFER,
      new Uint16Array(fTerrain),
      this.gl.STATIC_DRAW
    );
    this.tIndexTriBuffer.itemSize = 1;
    this.tIndexTriBuffer.numItems = numT * 3;

    // Setup Edges
    generateLinesFromIndexedTriangles({ faceArray: fTerrain, lineArray: eTerrain });
    this.tIndexEdgeBuffer = this.gl.createBuffer();
    this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this.tIndexEdgeBuffer);
    this.gl.bufferData(
      this.gl.ELEMENT_ARRAY_BUFFER,
      new Uint16Array(eTerrain),
      this.gl.STATIC_DRAW
    );
    this.tIndexEdgeBuffer.itemSize = 1;
    this.tIndexEdgeBuffer.numItems = eTerrain.length;
  };

  drawTerrain = () => {
    this.gl.polygonOffset(0, 0);
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.tVertexPositionBuffer);
    this.gl.vertexAttribPointer(
      this.shaderProgram.vertexPositionAttribute,
      this.tVertexPositionBuffer.itemSize,
      this.gl.FLOAT,
      false,
      0,
      0
    );

    // Bind normal buffer
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.tVertexNormalBuffer);
    this.gl.vertexAttribPointer(
      this.shaderProgram.vertexNormalAttribute,
      this.tVertexNormalBuffer.itemSize,
      this.gl.FLOAT,
      false,
      0,
      0
    );

    // Draw
    this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this.tIndexTriBuffer);
    this.gl.drawElements(
      this.gl.TRIANGLES,
      this.tIndexTriBuffer.numItems,
      this.gl.UNSIGNED_SHORT,
      0
    );
  };

  drawTerrainEdges = () => {
    this.gl.polygonOffset(1, 1);
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.tVertexPositionBuffer);
    this.gl.vertexAttribPointer(
      this.shaderProgram.vertexPositionAttribute,
      this.tVertexPositionBuffer.itemSize,
      this.gl.FLOAT,
      false,
      0,
      0
    );

    // Bind normal buffer
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.tVertexNormalBuffer);
    this.gl.vertexAttribPointer(
      this.shaderProgram.vertexNormalAttribute,
      this.tVertexNormalBuffer.itemSize,
      this.gl.FLOAT,
      false,
      0,
      0
    );

    // Draw
    this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this.tIndexEdgeBuffer);
    this.gl.drawElements(this.gl.LINES, this.tIndexEdgeBuffer.numItems, this.gl.UNSIGNED_SHORT, 0);
  };

  draw = () => {
    const transformVec = vec3.create();

    this.gl.viewport(0, 0, this.gl.viewportWidth, this.gl.viewportHeight);
    this.gl.clear(this.gl.COLOR_BUFFER_BIT | this.gl.DEPTH_BUFFER_BIT); // eslint-disable-line no-bitwise

    // We'll use perspective
    mat4.perspective(
      this.pMatrix,
      degToRad(45),
      this.gl.viewportWidth / this.gl.viewportHeight,
      0.1,
      200.0
    );

    // We want to look down -z, so create a lookat point in that direction
    vec3.add(this.viewPt, this.eyePt, this.viewDir);
    // Then generate the lookat matrix and initialize the MV matrix to that view
    mat4.lookAt(this.mvMatrix, this.eyePt, this.viewPt, this.up);

    // Draw Terrain
    this.mvPushMatrix();
    vec3.set(transformVec, 0.0, -0.25, -3.0);
    mat4.translate(this.mvMatrix, this.mvMatrix, transformVec);
    mat4.rotateX(this.mvMatrix, this.mvMatrix, degToRad(-75));
    mat4.rotateZ(this.mvMatrix, this.mvMatrix, degToRad(25));
    this.setMatrixUniforms();

    const R = 255.0 / 255.0;
    const G = 255.0 / 255.0;
    const B = 255.0 / 255.0;
    const shiny = 88;

    this.uploadLightsToShader({
      loc: [0.3, 1, 1],
      a: [0.05, 0.05, 0.05],
      d: [0.5, 0.5, 0.6],
      s: [0.1, 0.9, 0.9],
    });

    this.uploadMaterialToShader({
      dcolor: [R, G, B],
      acolor: [R, G, B],
      scolor: [1.0, 1.0, 1.0],
      shiny,
    });
    this.drawTerrain();

    this.mvPopMatrix();
  };

  initialize = () => {
    this.setupShaders();
    this.setupBuffers();

    document.addEventListener('keypress', this.handleKeyPress);

    super.initialize();
  };

  teardown = () => {
    document.removeEventListener('keypress', this.handleKeyPress);
    super.teardown();
  };

  handleKeyPress = event => {
    if (event.code === 'KeyN') {
      this.setupBuffers();
      this.resetCamera();
      this.resetState();
    }
  };

  animate = () => {
    const { speed } = this.state;

    // move eye forward
    const tempVec = vec3.create();
    vec3.scale(tempVec, this.viewDir, speed);
    vec3.add(this.eyePt, this.eyePt, tempVec);
  };

  handleKeys = () => {
    const { currentlyPressedKeys } = this.state;

    if (currentlyPressedKeys.KeyW) {
      this.pitch({ dir: 'up' });
    }
    if (currentlyPressedKeys.KeyS) {
      this.pitch({ dir: 'down' });
    }
    if (currentlyPressedKeys.KeyA) {
      this.rotate({ dir: 'left' });
    }
    if (currentlyPressedKeys.KeyD) {
      this.rotate({ dir: 'right' });
    }
    if (currentlyPressedKeys.Minus) {
      this.setState(prevState => ({
        speed: prevState.speed - prevState.accel,
      }));
    }
    if (currentlyPressedKeys.Equal) {
      this.setState(prevState => ({
        speed: prevState.speed + prevState.accel,
      }));
    }
    if (currentlyPressedKeys.KeyR) {
      this.resetCamera();
      this.resetState();
    }
  };

  rotate = ({ dir }) => {
    const { rotationSpeed } = this.state;

    const rotateOnView = scalar => {
      const rotationQuat = quat.create();
      quat.setAxisAngle(rotationQuat, this.viewDir, scalar * rotationSpeed);
      vec3.transformQuat(this.up, this.up, rotationQuat);
    };

    switch (dir) {
      case 'left':
        rotateOnView(-1);
        break;
      case 'right':
        rotateOnView(1);
        break;
      default:
        console.error('invalid dir');
    }
  };

  pitch = ({ dir }) => {
    const { pitchSpeed } = this.state;

    const pitchVer = scalar => {
      const rotationQuat = quat.create();
      const sidewaysVec = vec3.create();
      vec3.cross(sidewaysVec, this.up, this.viewDir);
      quat.setAxisAngle(rotationQuat, sidewaysVec, scalar * pitchSpeed);
      vec3.transformQuat(this.up, this.up, rotationQuat);
      vec3.transformQuat(this.viewDir, this.viewDir, rotationQuat);
    };

    switch (dir) {
      case 'up':
        pitchVer(-1);
        break;
      case 'down':
        pitchVer(1);
        break;
      default:
        console.error('invalid dir');
    }
  };
}
