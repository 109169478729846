import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledAccomplishmentList = styled.ul`
  color: ${props => props.theme.foreground.fade(0.2).string()};
  margin: 7.5px 0;
  padding-left: 35px;
  padding-right: 17.5px;
  list-style-type: circle;
`;

const ListItem = styled.li`
  margin: 0;
  line-height: 1.3em;
`;

const AccomplishmentList = ({ className = '', listItems = [], rootKey = 'u0' }) => {
  return (
    <StyledAccomplishmentList className={className} key={rootKey}>
      {listItems.map((listItem, idx) => {
        if (Array.isArray(listItem)) {
          const ulKey = `${rootKey}u${idx}`;
          return <AccomplishmentList key={ulKey} listItems={listItem} rootKey={ulKey} />;
        }

        const liKey = `${rootKey}l${idx}`;

        return <ListItem key={liKey}>{listItem}</ListItem>;
      })}
    </StyledAccomplishmentList>
  );
};

AccomplishmentList.propTypes = {
  className: PropTypes.string,
  listItems: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.node, PropTypes.array])),
  rootKey: PropTypes.string,
};

export default AccomplishmentList;
