import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { Spinner10 } from 'styled-icons/icomoon/Spinner10';

import IconButton from './Generic/IconButton';

// Create the keyframes
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const StyledLoadingIcon = styled(IconButton)`
  cursor: pointer;
  animation: ${rotate} 3s linear infinite;
`;

const LoadingIcon = ({ className = '', size = 30, onClick = () => {} }) => {
  const renderIcon = () => {
    return <Spinner10 size={size} />;
  };

  const buttonSize = typeof size === 'number' ? `${size}px` : size;

  return (
    <StyledLoadingIcon
      className={className}
      renderIcon={renderIcon}
      onClick={onClick}
      buttonSize={buttonSize}
    />
  );
};

LoadingIcon.propTypes = {
  className: PropTypes.string,

  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
};

export default forwardRef((props, ref) => <LoadingIcon {...props} baseRef={ref} />);
