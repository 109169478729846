import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { DisplayModeContext, MOBILE } from '../../Constants/DisplayModes';

import useI18nConsumer, { Trans } from '../../Hooks/useI18nConsumer';

import PaddedPage, { ContentWrapper } from './Generic/PaddedPage';
import PageShadow from './Generic/PageShadow';
import PageHeader from './Generic/PageHeader';

const StyledHomePage = styled(PaddedPage)`
  color: ${props => props.theme.foreground.string()};

  ${ContentWrapper} {
    padding-right: ${props => (props.displayMode === MOBILE ? '10px' : '25px')};
    padding-bottom: ${props => (props.displayMode === MOBILE ? '10px' : '25px')};
    padding-left: ${props => (props.displayMode === MOBILE ? '10px' : '25px')};
  }
`;

const EyeCatcher = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  ${props => (props.displayMode === MOBILE ? 'text-align: center;' : '')}
`;

const Hello = styled.div`
  font-size: ${props => (props.displayMode === MOBILE ? '6.7em' : '7.3em')};
  font-weight: bold;
  color: ${props => props.theme.foreground.string()};
`;

const Dash = styled.div`
  height: 5px;
  width: 4.5em;
  background-color: ${props => props.theme.foreground.string()};
  margin: 0 10px 0 5px;
`;

const NameLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  ${props => (props.displayMode === MOBILE ? 'justify-content: center;' : '')}
`;

const NameDesc = styled.div`
  font-size: 2.5em;
`;

const JobDesc = styled.div`
  font-size: 1.5em;
  font-weight: bold;
`;

const Emphasis = styled.strong`
  color: ${props => props.theme.foregroundHome.string()};
`;

const HomePage = ({ className = '' }) => {
  const { t } = useI18nConsumer();
  const displayMode = useContext(DisplayModeContext);

  const renderPageShadow = () => {
    return (
      <PageShadow>
        <Trans i18nKey="HomePage.pageShadow" defaults="Aa<0/>ron" components={[<br />]} />
      </PageShadow>
    );
  };

  const renderEyeCatcher = () => {
    return (
      <EyeCatcher displayMode={displayMode}>
        <div>
          <Hello displayMode={displayMode}>{t('HomePage.hello')}</Hello>
          <NameLine displayMode={displayMode}>
            <Dash />
            <NameDesc>
              <Trans
                i18nKey="HomePage.iAmAaron"
                defaults="I am <0>Aaron!</0>"
                components={[<Emphasis />]}
              />
            </NameDesc>
          </NameLine>
          <JobDesc>{t('HomePage.blurb')}</JobDesc>
        </div>
      </EyeCatcher>
    );
  };

  return (
    <StyledHomePage className={className} displayMode={displayMode}>
      {renderPageShadow()}
      <PageHeader />
      {renderEyeCatcher()}
    </StyledHomePage>
  );
};

HomePage.propTypes = {
  className: PropTypes.string,
};

export default HomePage;
