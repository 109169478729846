import React, { useCallback, useContext } from 'react';
import { Trans as I18nextTrans } from 'react-i18next';

import { i18n, LanguageContext } from './useI18nProvider';

const useI18nConsumer = () => {
  const currentLanguage = useContext(LanguageContext);

  const t = useCallback((...args) => i18n.getFixedT(currentLanguage)(...args), [currentLanguage]);

  return {
    t,
    currentLanguage,
  };
};

export const Trans = props => {
  const { t } = useI18nConsumer();

  return <I18nextTrans {...props} t={t} />;
};

export default useI18nConsumer;
