import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { OVERLAY_EASE_IN } from '../../Constants/TransitionCurves';
import { Z_DEFAULT_OVERLAY } from '../../Constants/ZIndicies';

const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${Z_DEFAULT_OVERLAY};
  background-color: ${props => (props.started ? props.overlayColor : 'rgba(0,0,0,0)')};
  transition: ${OVERLAY_EASE_IN};
`;

const Overlay = ({
  className = '',
  children = null,
  overlayColor = 'rgba(0,0,0,0.5)',
  onClick = () => {},
}) => {
  const [started, setStarted] = useState(false);

  useEffect(() => {
    setStarted(true);
  }, []);

  const handleChildrenClick = useCallback(event => {
    event.stopPropagation();
  }, []);

  return (
    <StyledOverlay
      className={className}
      started={started}
      overlayColor={overlayColor}
      onClick={onClick}
    >
      <div onClick={handleChildrenClick} role="presentation">
        {children}
      </div>
    </StyledOverlay>
  );
};

Overlay.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),

  overlayColor: PropTypes.string,
  onClick: PropTypes.func,
};

export default Overlay;
