export const vertexShaderSrc = `
	attribute vec3 aVertexNormal;
	attribute vec3 aVertexPosition;
   
	uniform mat4 uMVMatrix;
	uniform mat4 uPMatrix;
	uniform mat3 uNMatrix;
	varying vec3 vNormal;
	varying vec3 vPosition;

	varying vec3 heightcolor;
	
	void main(void) {
		// Get the vertex position in eye coordinates
		vec4 vertexPositionEye4 = uMVMatrix * vec4(aVertexPosition, 1.0);
		vPosition = vertexPositionEye4.xyz / vertexPositionEye4.w;
		//Calaulate the normal
		vNormal = normalize(uNMatrix * aVertexNormal);
	   
		gl_Position = uPMatrix*uMVMatrix*vec4(aVertexPosition, 1.0);
	}
`;

export const fragmentShaderSrc = `
  precision mediump float;
	varying vec3 vNormal;
	varying vec3 vPosition;

	uniform vec3 uLightPosition;
	uniform vec3 uAmbientLightColor;
	uniform vec3 uDiffuseLightColor;
	uniform vec3 uSpecularLightColor;
	uniform vec3 uAmbientMaterialColor;
	uniform vec3 uDiffuseMaterialColor;
	uniform vec3 uSpecularMaterialColor;
	uniform float uShininess;

	void main(void) {
		vec4 vColor;
		// Calculate the vector (l) to the light source
		vec3 vectorToLightSource = normalize(uLightPosition - vPosition);
		
		// Calculate n dot l for diffuse lighting
		float diffuseLightWeightning = max(dot(vNormal, 
										vectorToLightSource), 0.0);
		
		// The camera in eye coordinates is located in the origin and is pointing
		// along the negative z-axis. Calculate viewVector (v) 
		// in eye coordinates as:
		// (0.0, 0.0, 0.0) - vertexPositionEye3
		vec3 viewVectorEye = -normalize(vPosition);
										   
		// Calculate the reflection vector (r) that is needed for specular light
		vec3 halfwayVector = normalize(vectorToLightSource+viewVectorEye);

		float ndoth = max(dot(halfwayVector, viewVectorEye), 0.0);
		
		float specularLightWeightning = pow(ndoth, uShininess);

		// Sum up all three reflection components and send to the fragment shader
		vColor = vec4(((uAmbientLightColor*uAmbientMaterialColor)
					+ (uDiffuseLightColor*uDiffuseMaterialColor) * diffuseLightWeightning
					+ (uSpecularLightColor*uSpecularMaterialColor) * specularLightWeightning),1.0);


		gl_FragColor = vColor;
	}
`;
