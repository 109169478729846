import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledJapanFlagIcon = styled.svg`
  enable-background: new 0 0 512 512;
`;

const Path = styled.path`
  fill: #f5f5f5;
`;

const Circle = styled.circle`
  fill: #ff4b55;
`;

const JapanFlagIcon = ({ className = '' }) => {
  return (
    <StyledJapanFlagIcon
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <Path d="M400,0H112C50.144,0,0,50.144,0,112v288c0,61.856,50.144,112,112,112h288  c61.856,0,112-50.144,112-112V112C512,50.144,461.856,0,400,0z" />
      <Circle cx="256" cy="256" r="97.1" />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </StyledJapanFlagIcon>
  );
};

JapanFlagIcon.propTypes = {
  className: PropTypes.string,
};

export default JapanFlagIcon;
