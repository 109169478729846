export const vertexShaderSrc = `
	attribute vec3 aVertexNormal;
	attribute vec3 aVertexPosition;
   
	uniform mat4 uMVMatrix;
	uniform mat4 uPMatrix;
	uniform mat3 uNMatrix;
	varying vec3 vNormal;
	varying vec3 vPosition;

	varying vec3 heightcolor;

	varying vec4 fogColor;
	varying float fogDensity;
	varying float fogFactor;
	varying float fogFragCoord;
	
	void main(void) {
	
		// Get the vertex position in eye coordinates
		vec4 vertexPositionEye4 = uMVMatrix * vec4(aVertexPosition, 1.0);
		vPosition = vertexPositionEye4.xyz / vertexPositionEye4.w;
		//Calaulate the normal
		vNormal = normalize(uNMatrix * aVertexNormal);
	   
		gl_Position = uPMatrix*uMVMatrix*vec4(aVertexPosition, 1.0);

		if (aVertexPosition[2] < 0.25) {
      // blue
      heightcolor = BLUE_HEIGHT_COLOR;
		}
		else if (aVertexPosition[2] < 0.50) {
      // green
			heightcolor = GREEN_HEIGHT_COLOR;
		}
		else if (aVertexPosition[2] < 0.75) {
      // red
      heightcolor = RED_HEIGHT_COLOR;
		}
		else {
			heightcolor = vec3(1.0,1.0,1.0);
		}

		fogColor = vec4(1.0,1.0,1.0,1.0);
		fogDensity = 0.25;

		//calculate fog factor
		const float LOG2 = 1.442695;
		fogFragCoord = length(vertexPositionEye4);
		fogFactor = exp2(-fogDensity * fogDensity * fogFragCoord * fogFragCoord * LOG2);
		fogFactor = clamp(fogFactor, 0.0, 1.0);

	}
`;

export const fragmentShaderSrc = `
	precision mediump float;
	varying vec3 vNormal;
	varying vec3 vPosition;

	uniform vec3 uLightPosition;
	uniform vec3 uAmbientLightColor;
	uniform vec3 uDiffuseLightColor;
	uniform vec3 uSpecularLightColor;
	uniform vec3 uAmbientMaterialColor;
	uniform vec3 uDiffuseMaterialColor;
	uniform vec3 uSpecularMaterialColor;
	uniform float uShininess;

	varying vec3 heightcolor;

	varying vec4 fogColor;
	varying float fogFactor;

	void main(void) {
		vec4 vColor;
		// Calculate the vector (l) to the light source
		vec3 vectorToLightSource = normalize(uLightPosition - vPosition);
		
		// Calculate n dot l for diffuse lighting
		float diffuseLightWeightning = max(dot(vNormal, 
										vectorToLightSource), 0.0);
		
		// The camera in eye coordinates is located in the origin and is pointing
		// along the negative z-axis. Calculate viewVector (v) 
		// in eye coordinates as:
		// (0.0, 0.0, 0.0) - vertexPositionEye3
		vec3 viewVectorEye = -normalize(vPosition);
		
										   
		// Calculate the reflection vector (r) that is needed for specular light
		vec3 halfwayVector = normalize(vectorToLightSource+viewVectorEye);

		float ndoth = max(dot(halfwayVector, viewVectorEye), 0.0);
		
		float specularLightWeightning = pow(ndoth, uShininess);

		// Sum up all three reflection components and send to the fragment shader
		vColor = vec4(((uAmbientLightColor*uAmbientMaterialColor*heightcolor)
					+ (uDiffuseLightColor*uDiffuseMaterialColor*heightcolor) * diffuseLightWeightning
					+ (uSpecularLightColor*uSpecularMaterialColor*heightcolor) * specularLightWeightning),1.0);


		gl_FragColor = mix(fogColor, vColor, fogFactor);
	}
`;

export const vertexShaderWithRGBHeights = ({
  r = 'vec3(0.941176, 0.443137, 0.470588)',
  g = 'vec3(0.52549,0.701961,0.0)',
  b = 'vec3(0.211765, 0.639216, 0.85098)',
}) => {
  let newString = vertexShaderSrc;
  newString = newString.replace('RED_HEIGHT_COLOR', r);
  newString = newString.replace('GREEN_HEIGHT_COLOR', g);
  newString = newString.replace('BLUE_HEIGHT_COLOR', b);

  return newString;
};
