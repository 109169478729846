import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';

import { calculateForeground } from '../../Constants/Colors';
import { DisplayModeContext, MOBILE, TABLET } from '../../Constants/DisplayModes';
import { HOVER_SIZE_EASE_IN } from '../../Constants/TransitionCurves';

import HighlightButton from './Generic/HighlightButton';

const FlagIcon = styled.div`
  line-height: 0;

  border-radius: 40px;

  height: auto;
  width: ${props => {
    switch (props.displayMode) {
      case MOBILE:
        return '80px';
      case TABLET:
        return '120px';
      default:
        return '160px';
    }
  }};
  transition: ${HOVER_SIZE_EASE_IN};
`;

const LanguageButton = ({
  className = '',
  children = null,

  highlighted = false,
  hoverText = '',

  onClick = () => {},
}) => {
  const theme = useContext(ThemeContext);
  const displayMode = useContext(DisplayModeContext);

  const hoverBgColor = theme.foregroundSettings;
  const hoverFgColor = calculateForeground(theme, hoverBgColor);

  return (
    <HighlightButton
      className={className}
      highlighted={highlighted}
      hoverText={hoverText}
      hoverBgColor={hoverBgColor.toString()}
      hoverFgColor={hoverFgColor.toString()}
      onClick={onClick}
    >
      <FlagIcon displayMode={displayMode}>{children}</FlagIcon>
    </HighlightButton>
  );
};

LanguageButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),

  highlighted: PropTypes.bool,
  hoverText: PropTypes.string,

  onClick: PropTypes.func,
};

export default LanguageButton;
