import React from 'react';
import PropTypes from 'prop-types';

import useI18nConsumer from '../../Hooks/useI18nConsumer';

import SkillGroupPortlet from './Generic/SkillGroupPortlet';
import GitlabIcon from '../Icons/Skills/GitlabIcon';
import PythonIcon from '../Icons/Skills/PythonIcon';
// import RubyIcon from '../Icons/Skills/RubyIcon';
// import JavaIcon from '../Icons/Skills/JavaIcon';

const OtherSkillsPortlet = ({ className = '' }) => {
  const { t } = useI18nConsumer();

  const otherButtons = [
    {
      id: 'gitlab',
      icon: <GitlabIcon />,
      href: 'https://gitlab.com',
      hoverText: t('OtherSkillsPortlet.gitLab'),
    },
    {
      id: 'python',
      icon: <PythonIcon />,
      href: 'https://www.python.org/',
      hoverText: t('OtherSkillsPortlet.python'),
    },
    // {
    //   id: 'ruby',
    //   icon: <RubyIcon />,
    //   href: 'https://www.ruby-lang.org/',
    //   hoverText: t('OtherSkillsPortlet.ruby'),
    // },
    // {
    //   id: 'java',
    //   icon: <JavaIcon />,
    //   href: 'https://openjdk.java.net/',
    //   hoverText: t('OtherSkillsPortlet.java'),
    // },
  ];

  return (
    <SkillGroupPortlet
      className={className}
      title={t('OtherSkillsPortlet.other')}
      buttons={otherButtons}
    />
  );
};

OtherSkillsPortlet.propTypes = {
  className: PropTypes.string,
};

export default OtherSkillsPortlet;
