import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { DisplayModeContext, MOBILE } from '../../Constants/DisplayModes';

import useI18nConsumer from '../../Hooks/useI18nConsumer';

import WorkGroupPortlet from './Generic/WorkGroupPortlet';
import { PortletTitle } from './Generic/Portlet';
import QualiaIcon from '../Icons/Work/QualiaIcon';
import NetSfereIcon from '../Icons/Work/NetSfereIcon';

const StyledCompaniesPortletContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  column-gap: ${props => (props.displayMode === MOBILE ? '15px' : '25px')};
`;

const StyledWorkGroupPortlet = styled(WorkGroupPortlet)`
  ${PortletTitle} {
    display: none;
  }
`;

const CompaniesPortletContainer = ({ className = '' }) => {
  const { t } = useI18nConsumer();
  const displayMode = useContext(DisplayModeContext);

  const qualiaButtons = [
    {
      id: 'qualia',
      icon: <QualiaIcon />,
      hoverText: t('CompaniesPortletContainer.qualia'),
      href: 'https://qualia.com',
    },
  ];

  const icsButtons = [
    {
      id: 'netsfereACP',
      icon: <NetSfereIcon />,
      hoverText: t('CompaniesPortletContainer.netsfere'),
      href: 'https://netsfere.com',
    },
  ];

  return (
    <StyledCompaniesPortletContainer className={className} displayMode={displayMode}>
      <PortletTitle>{t('CompaniesPortletContainer.qualia')}</PortletTitle>
      <PortletTitle>{t('CompaniesPortletContainer.infiniteConvergenceSolutions')}</PortletTitle>
      <StyledWorkGroupPortlet className={className} buttons={qualiaButtons} />
      <StyledWorkGroupPortlet className={className} buttons={icsButtons} />
    </StyledCompaniesPortletContainer>
  );
};

CompaniesPortletContainer.propTypes = {
  className: PropTypes.string,
};

export default CompaniesPortletContainer;
