import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Home } from 'styled-icons/fa-solid/Home';

import IconButton from './Generic/IconButton';

const StyledHomeIcon = styled(IconButton)`
  cursor: pointer;
`;

const HomeIcon = ({ className = '', size = 30, onClick = () => {} }) => {
  const renderIcon = () => {
    return <Home size={size} />;
  };

  return <StyledHomeIcon className={className} renderIcon={renderIcon} onClick={onClick} />;
};

HomeIcon.propTypes = {
  className: PropTypes.string,

  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
};

export default HomeIcon;
