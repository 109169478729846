import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FireAlt } from 'styled-icons/fa-solid/FireAlt';

import IconButton from './Generic/IconButton';

const StyledFireIcon = styled(IconButton)`
  cursor: pointer;
`;

const FireIcon = ({ className = '', size = 30, onClick = () => {} }) => {
  const renderIcon = () => {
    return <FireAlt size={size} />;
  };

  return <StyledFireIcon className={className} renderIcon={renderIcon} onClick={onClick} />;
};

FireIcon.propTypes = {
  className: PropTypes.string,

  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
};

export default FireIcon;

