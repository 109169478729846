import { useState, useLayoutEffect } from 'react';

import { MOBILE, TABLET, DESKTOP } from '../Constants/DisplayModes';

const getDisplayMode = () => {
  const width = document.body.clientWidth;

  if (width > 960) {
    return DESKTOP;
  }
  if (width > 530) {
    return TABLET;
  }
  return MOBILE;
};

const useDisplayMode = () => {
  const [displayMode, setDisplayMode] = useState(getDisplayMode);

  // update the current displayMode synchronously after any
  // DOM mutations and also on resize
  useLayoutEffect(() => {
    const updateDisplayMode = () => {
      setDisplayMode(getDisplayMode());
    };

    updateDisplayMode();
    window.addEventListener('resize', updateDisplayMode);
    return () => {
      window.removeEventListener('resize', updateDisplayMode);
    };
  }, []);

  return displayMode;
};

export default useDisplayMode;
