import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import FontFamilies from '../../Constants/FontFamilies';

import Modal from './Modal';

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-overflow: ellipsis;
  width: 100%;
  flex-grow: 1;
`;

const Title = styled.h1`
  font-size: 1.5em;
  font-weight: bold;
  line-height: 20px;
  margin-top: 0px;
  margin-bottom: 20px;
`;

const Description = styled.p`
  font-size: 1em;
  line-height: 1.2em;
  margin-bottom: 30px;
  color: ${props => props.theme.foreground.string()};
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const DecisionButton = styled.button`
  padding: 0.25em 0.8em;
  min-width: 3.5em;
  cursor: pointer;

  font-family: ${FontFamilies};
  font-size: 1.2em;
  font-weight: bold;

  background-color: ${props => props.theme.panel.lighten(1).string()};
  color: ${props => props.theme.foregroundContact.string()};

  border-width: 0;
  border-radius: 5px;
`;

const InfoModal = ({
  className = '',

  title = '',
  description = '',

  confirmText = 'OK',

  hideConfirm = false,

  onConfirm = () => {},
}) => {
  const renderControls = () => {
    return (
      <ButtonRow>
        {!hideConfirm && (
          <DecisionButton type="button" onClick={onConfirm}>
            {confirmText}
          </DecisionButton>
        )}
      </ButtonRow>
    );
  };

  const spacer = <div style={{ flexGrow: 1 }} />;

  return (
    <Modal className={className}>
      <ContentContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
        {spacer}
        {renderControls()}
      </ContentContainer>
    </Modal>
  );
};

InfoModal.propTypes = {
  className: PropTypes.string,

  title: PropTypes.string,
  description: PropTypes.node,

  confirmText: PropTypes.string,
  hideConfirm: PropTypes.bool,

  onConfirm: PropTypes.func,
};

export default InfoModal;
