import React, { forwardRef, useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring';

import { HOVER_SIZE_EASE_IN, HOVER_COLOR_EASE_IN } from '../../Constants/TransitionCurves';
import { DisplayModeContext, MOBILE } from '../../Constants/DisplayModes';

import useI18nConsumer from '../../Hooks/useI18nConsumer';

import GitlabIcon from '../Icons/GitlabIcon';
import LinkedInIcon from '../Icons/LinkedInIcon';
import IconButton from '../Icons/Generic/IconButton';

const StyledSocialMediaMenu = styled(animated.nav)`
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: row;
  background-color: ${props => props.theme.guide.string()};
  pointer-events: auto;
`;

const StyledLink = styled.a`
  text-decoration: none;
`;

const StyledIconButton = styled(IconButton)`
  color: ${props => props.theme.foregroundIdle.string()};
  svg {
    transition: ${HOVER_SIZE_EASE_IN}, ${HOVER_COLOR_EASE_IN};
  }

  :hover {
    svg {
      color: ${props => props.theme.foreground.string()};
      height: 35px;
      width: 35px;
    }
  }
`;

const SocialMediaMenu = ({ className = '', baseRef = () => {} }) => {
  const { t } = useI18nConsumer();
  const displayMode = useContext(DisplayModeContext);

  const [topLevelDiv] = useState(() => window.document.createElement('div'));

  const [expanded, setExpanded] = useState(displayMode === MOBILE);

  useEffect(() => {
    const socialMediaMenuRoot = window.document.getElementById('socialmediamenu-root');
    if (socialMediaMenuRoot !== null) {
      socialMediaMenuRoot.appendChild(topLevelDiv);
    }
    return () => {
      if (socialMediaMenuRoot !== null) {
        socialMediaMenuRoot.removeChild(topLevelDiv);
      }
    };
  }, [topLevelDiv]);

  useEffect(() => {
    if (displayMode === MOBILE) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [displayMode]);

  const animatedMenuSpring = useSpring({
    bottom: expanded ? '0px' : '-60px',
  });

  const buttons = [
    {
      id: 'gitlab',
      label: t('SocialMediaMenu.gitlab'),
      href: 'https://gitlab.com/aaron.chen',
      renderIcon: () => <GitlabIcon />,
    },
    {
      id: 'linkedin',
      label: t('SocialMediaMenu.linkedIn'),
      href: 'https://www.linkedin.com/in/aaron-chen-100/',
      renderIcon: () => <LinkedInIcon />,
    },
  ];

  return createPortal(
    <StyledSocialMediaMenu ref={baseRef} className={className} style={animatedMenuSpring}>
      {buttons.map(button => {
        const { id, label, href, renderIcon } = button;

        const ButtonComponent = <StyledIconButton renderIcon={renderIcon} />;

        return (
          <StyledLink key={id} href={href} aria-label={label} target="_blank" rel="noreferrer">
            {ButtonComponent}
          </StyledLink>
        );
      })}
    </StyledSocialMediaMenu>,
    topLevelDiv
  );
};

SocialMediaMenu.propTypes = {
  className: PropTypes.string,
  baseRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export default forwardRef((props, ref) => <SocialMediaMenu {...props} baseRef={ref} />);
