import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MehBlank } from 'styled-icons/fa-solid/MehBlank';

import IconButton from './Generic/IconButton';

const StyledMehBlankIcon = styled(IconButton)`
  cursor: pointer;
`;

const MehBlankIcon = ({ className = '', size = 30, onClick = () => {} }) => {
  const renderIcon = () => {
    return <MehBlank size={size} />;
  };

  return <StyledMehBlankIcon className={className} renderIcon={renderIcon} onClick={onClick} />;
};

MehBlankIcon.propTypes = {
  className: PropTypes.string,

  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
};

export default MehBlankIcon;
