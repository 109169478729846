import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Envelope } from 'styled-icons/fa-regular/Envelope';

import IconButton from './Generic/IconButton';

const StyledMailIcon = styled(IconButton)`
  cursor: pointer;
`;

const MailIcon = ({ className = '', size = 30, onClick = () => {} }) => {
  const renderIcon = () => {
    return <Envelope size={size} />;
  };

  return <StyledMailIcon className={className} renderIcon={renderIcon} onClick={onClick} />;
};

MailIcon.propTypes = {
  className: PropTypes.string,

  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
};

export default MailIcon;
