import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Briefcase } from 'styled-icons/fa-solid/Briefcase';

import IconButton from './Generic/IconButton';

const StyledBriefcaseIcon = styled(IconButton)`
  cursor: pointer;
`;

const BriefcaseIcon = ({ className = '', size = 30, onClick = () => {} }) => {
  const renderIcon = () => {
    return <Briefcase size={size} />;
  };

  return <StyledBriefcaseIcon className={className} renderIcon={renderIcon} onClick={onClick} />;
};

BriefcaseIcon.propTypes = {
  className: PropTypes.string,

  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
};

export default BriefcaseIcon;

