import React from 'react';

const NetSfereIcon = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1530 1530"
      preserveAspectRatio="xMidYMid meet"
    >
      <g id="layer101" fill="#c93e4b" stroke="none">
        <path d="M278 1278 c-69 -67 -143 -168 -130 -179 4 -3 189 -77 412 -163 431 -166 457 -173 518 -136 91 56 97 197 9 254 -31 21 -707 286 -729 286 -9 0 -45 -28 -80 -62z" />
        <path d="M773 620 c-55 -24 -83 -70 -83 -134 0 -57 28 -109 69 -130 50 -26 415 -166 432 -166 40 0 225 219 202 240 -15 15 -532 210 -555 210 -13 0 -42 -9 -65 -20z" />
      </g>
      <g id="layer102" fill="#cfcece" stroke="none">
        <path d="M690 1520 c-100 -10 -179 -33 -270 -81 -126 -66 -132 -77 -60 -97 58 -16 701 -270 727 -287 88 -58 82 -199 -9 -255 -62 -37 -90 -30 -504 131 -214 83 -408 160 -431 170 -23 10 -44 19 -46 19 -10 0 -58 -122 -72 -185 -21 -88 -21 -249 0 -345 34 -159 151 -343 278 -437 143 -105 254 -143 435 -150 150 -6 228 7 342 59 72 32 160 86 160 98 0 4 -12 12 -27 18 -165 61 -416 159 -444 173 -75 37 -103 144 -59 217 21 34 87 71 128 72 22 0 507 -183 567 -214 40 -21 42 -20 59 12 61 118 84 337 52 488 -23 104 -91 243 -161 327 -115 139 -304 244 -472 262 -32 3 -71 7 -88 9 -16 2 -64 0 -105 -4z" />
      </g>
    </svg>
  );
};

export default NetSfereIcon;
