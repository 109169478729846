export const RootRoute = '/';

export const AboutRoute = '/about';
export const SkillsRoute = '/skills';
export const WorkRoute = '/work';
export const SettingsRoute = '/settings';
export const ContactRoute = '/contact';

export const CVRoute = '/cv';

export const FlightSimulatorRoute = '/flight-simulator';
export const ParticlesRoute = '/particles';
export const TeapotRoute = '/teapot';
