import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { PALETTE_EASE_IN, HOVER_COLOR_EASE_IN } from '../../../Constants/TransitionCurves';
import FontFamilies from '../../../Constants/FontFamilies';

import useHover from '../../../Hooks/useHover';

const StyledHighlightButton = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const ButtonLogo = styled.button`
  position: relative;

  flex-grow: 1;
  background-color: rgba(0, 0, 0, 0);
  overflow: hidden;

  border-color: ${props => (props.highlighted ? props.hoverBgColor : 'rgba(0, 0, 0, 0)')};
  border-radius: 40px;
  border-style: solid;
  border-width: 5px;

  padding: 0px;
  margin: 0px 10px;

  cursor: ${props => (props.isHover ? 'pointer' : 'auto')};

  transition: ${PALETTE_EASE_IN}, ${HOVER_COLOR_EASE_IN};
  background-color: ${props => (props.isHover ? props.hoverBgColor : 'rgba(0, 0, 0, 0)')};
`;

const Content = styled.div`
  ${props => {
    return props.centerContent
      ? `display: flex;
      align-items: center;
      justify-content: center;
      `
      : '';
  }}

  height: 100%;
  width: 100%;

  transition: ${HOVER_COLOR_EASE_IN};
  opacity: ${props => (props.isHover ? 0.05 : 0.9)};
`;

const Label = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  transform: rotate(-12.5deg);

  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: auto;

  height: 100%;
  width: 100%;

  transition: ${HOVER_COLOR_EASE_IN};
  color: ${props => (props.isHover ? props.hoverFgColor : 'rgba(0, 0, 0, 0)')};

  font-size: 1.7em;
  font-family: ${FontFamilies};
  font-weight: bold;

  word-break: break-word;
`;

const HighlightButton = ({
  className = '',
  children = null,

  centerContent = true,

  highlighted = false,
  hoverBgColor = 'rgb(0,0,0,1)',
  hoverFgColor = 'rgb(0,0,0,0)',
  hoverText = '',

  onClick = () => {},
}) => {
  const buttonLogoRef = useRef();
  const isHover = useHover(buttonLogoRef);

  return (
    <StyledHighlightButton className={className} onClick={onClick}>
      <ButtonLogo
        ref={buttonLogoRef}
        highlighted={highlighted}
        hoverBgColor={hoverBgColor}
        isHover={isHover}
      >
        <Content isHover={isHover} centerContent={centerContent}>
          {children}
        </Content>
        <Label isHover={isHover} hoverFgColor={hoverFgColor}>
          {hoverText}
        </Label>
      </ButtonLogo>
    </StyledHighlightButton>
  );
};

HighlightButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),

  centerContent: PropTypes.bool,

  highlighted: PropTypes.bool,
  hoverBgColor: PropTypes.string,
  hoverFgColor: PropTypes.string,
  hoverText: PropTypes.string,

  onClick: PropTypes.func,
};

export default HighlightButton;
