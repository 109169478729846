import { vec3, vec4 } from 'gl-matrix';

const pushVertex = (v, vArray) => {
  for (let i = 0; i < 3; i += 1) {
    vArray.push(v[i]);
  }
};

const sphDivideTriangle = (a, b, c, numSubDivs, vertexArray, normalArray) => {
  if (numSubDivs > 0) {
    let numT = 0;

    const ab = vec4.create();
    vec4.lerp(ab, a, b, 0.5);
    vec4.normalize(ab, ab);

    const ac = vec4.create();
    vec4.lerp(ac, a, c, 0.5);
    vec4.normalize(ac, ac);

    const bc = vec4.create();
    vec4.lerp(bc, b, c, 0.5);
    vec4.normalize(bc, bc);

    numT += sphDivideTriangle(a, ab, ac, numSubDivs - 1, vertexArray, normalArray);
    numT += sphDivideTriangle(ab, b, bc, numSubDivs - 1, vertexArray, normalArray);
    numT += sphDivideTriangle(bc, c, ac, numSubDivs - 1, vertexArray, normalArray);
    numT += sphDivideTriangle(ab, bc, ac, numSubDivs - 1, vertexArray, normalArray);
    return numT;
  }

  // Add 3 vertices to the array

  pushVertex(a, vertexArray);
  pushVertex(b, vertexArray);
  pushVertex(c, vertexArray);

  // normals are the same as the vertices for a sphere

  pushVertex(a, normalArray);
  pushVertex(b, normalArray);
  pushVertex(c, normalArray);

  return 1;
};

export const createGLContext = ({ canvas }) => {
  const names = ['webgl', 'experimental-webgl'];
  let context = null;
  for (let i = 0; i < names.length; i += 1) {
    try {
      context = canvas.getContext(names[i]);
    } catch (e) {
      console.error(e);
    }
    if (context) {
      break;
    }
  }
  if (context) {
    context.viewportWidth = canvas.width;
    context.viewportHeight = canvas.height;
  } else {
    console.error('Failed to create WebGL context!');
  }
  return context;
};

export const degToRad = degrees => {
  return (degrees * Math.PI) / 180;
};

// calculate normals of vertices and place into normalArray
export const getNormals = ({ faceArray, vertexArray }) => {
  const normalArray = vertexArray.map(() => 0);

  // for each triangle face
  for (let i = 0; i < faceArray.length; i += 3) {
    // get triangle vertices, contained as vec3 in faceverts
    const faceverts = [];
    for (let j = 0; j < 3; j += 1) {
      const facevert = [];
      facevert[0] = vertexArray[faceArray[i + j] * 3];
      facevert[1] = vertexArray[faceArray[i + j] * 3 + 1];
      facevert[2] = vertexArray[faceArray[i + j] * 3 + 2];
      faceverts[j] = vec3.fromValues(facevert[0], facevert[1], facevert[2]);
    }
    // get two edge vectors
    const v0v1 = vec3.create();
    const v0v2 = vec3.create();
    vec3.sub(v0v1, faceverts[1], faceverts[0]);
    vec3.sub(v0v2, faceverts[2], faceverts[0]);

    // normal vector = cross product
    const vnorm = vec3.create();
    vec3.cross(vnorm, v0v1, v0v2);

    // add norm to each assoc. vertex
    for (let j = 0; j < 3; j += 1) {
      normalArray[faceArray[i + j] * 3] += vnorm[0];
      normalArray[faceArray[i + j] * 3 + 1] += vnorm[1];
      normalArray[faceArray[i + j] * 3 + 2] += vnorm[2];
    }
  }

  // normalize vertex normals
  for (let i = 0; i < normalArray.length; i += 3) {
    const normalizedvert = vec3.fromValues(normalArray[i], normalArray[i + 1], normalArray[i + 2]);
    vec3.normalize(normalizedvert, normalizedvert);

    /* eslint-disable prefer-destructuring */
    normalArray[i] = normalizedvert[0];
    normalArray[i + 1] = normalizedvert[1];
    normalArray[i + 2] = normalizedvert[2];
    /* eslint-enable prefer-destructuring */
  }

  return normalArray;
};

export const sphereFromSubdivision = (numSubDivs, vertexArray, normalArray) => {
  let numT = 0;
  const a = vec4.fromValues(0.0, 0.0, -1.0, 0);
  const b = vec4.fromValues(0.0, 0.942809, 0.333333, 0);
  const c = vec4.fromValues(-0.816497, -0.471405, 0.333333, 0);
  const d = vec4.fromValues(0.816497, -0.471405, 0.333333, 0);

  numT += sphDivideTriangle(a, b, c, numSubDivs, vertexArray, normalArray);
  numT += sphDivideTriangle(d, c, b, numSubDivs, vertexArray, normalArray);
  numT += sphDivideTriangle(a, d, b, numSubDivs, vertexArray, normalArray);
  numT += sphDivideTriangle(a, c, d, numSubDivs, vertexArray, normalArray);
  return numT;
};

export const generateLinesFromIndexedTriangles = ({ faceArray, lineArray }) => {
  const numTris = faceArray.length / 3;
  for (let f = 0; f < numTris; f += 1) {
    const fid = f * 3;
    lineArray.push(faceArray[fid]);
    lineArray.push(faceArray[fid + 1]);

    lineArray.push(faceArray[fid + 1]);
    lineArray.push(faceArray[fid + 2]);

    lineArray.push(faceArray[fid + 2]);
    lineArray.push(faceArray[fid]);
  }
};

// recursive helper for diamond square
const diamondSquareHelper = ({ vertexArray, dim, n, randomscale, nw, ne, sw, se }) => {
  /* eslint-disable no-param-reassign */
  // base case
  if (n === 1) {
    return;
  }

  // diamond step
  const middle = nw + (n / 2) * 3 + dim * (n / 2) * 3; // nw move half right, then half down
  let middleval =
    (vertexArray[nw + 2] + vertexArray[ne + 2] + vertexArray[sw + 2] + vertexArray[se + 2]) / 4;
  middleval += (Math.random() - 0.5) * randomscale; // avg and add randomness
  vertexArray[middle + 2] = middleval; // set middleval

  // square step
  const north = nw + (n / 2) * 3; // nw move half right
  const south = sw + (n / 2) * 3; // sw move half right
  const west = nw + dim * (n / 2) * 3; // nw move half down
  const east = west + n * 3; // w move full right

  // north
  // add 3 values already have, also need to consider diamond part outside of square
  let northval = vertexArray[nw + 2] + vertexArray[middle + 2] + vertexArray[ne + 2];
  if (north < dim * 3) {
    // edge just average 3;
    northval /= 3;
  } else {
    northval += vertexArray[north - dim * (n / 2) * 3 + 2];
    northval /= 4;
  }
  // south
  // add 3 values already have, also need to consider diamond part outside of square
  let southval = vertexArray[nw + 2] + vertexArray[middle + 2] + vertexArray[ne + 2];
  if (south >= dim * (dim - 1) * 3) {
    // edge just average 3;
    southval /= 3;
  } else {
    southval += vertexArray[south + dim * (n / 2) * 3 + 2];
    southval /= 4;
  }
  // west
  // add 3 values already have, also need to consider diamond part outside of square
  let westval = vertexArray[nw + 2] + vertexArray[middle + 2] + vertexArray[sw + 2];
  if (west % (dim * 3) === 0) {
    // edge just average 3;
    westval /= 3;
  } else {
    westval += vertexArray[west - (n / 2) * 3 + 2];
    westval /= 4;
  }
  // east
  // add 3 values already have, also need to consider diamond part outside of square
  let eastval = vertexArray[ne + 2] + vertexArray[middle + 2] + vertexArray[se + 2];
  if (east % (dim * 3) === dim * 3 - 3) {
    // edge just average 3;
    eastval /= 3;
  } else {
    eastval += vertexArray[east + (n / 2) * 3 + 2];
    eastval /= 4;
  }

  // add randomfactors
  northval += (Math.random() - 0.5) * randomscale;
  southval += (Math.random() - 0.5) * randomscale;
  westval += (Math.random() - 0.5) * randomscale;
  eastval += (Math.random() - 0.5) * randomscale;
  vertexArray[north + 2] = northval; // set square vals
  vertexArray[south + 2] = southval; // set square vals
  vertexArray[west + 2] = westval; // set square vals
  vertexArray[east + 2] = eastval; // set square vals

  diamondSquareHelper({
    vertexArray,
    dim,
    n: n / 2,
    randomscale: randomscale / 2.5,
    nw,
    ne: north,
    sw: west,
    se: middle,
  });
  diamondSquareHelper({
    vertexArray,
    dim,
    n: n / 2,
    randomscale: randomscale / 2.5,
    nw: north,
    ne,
    sw: middle,
    se: east,
  });
  diamondSquareHelper({
    vertexArray,
    dim,
    n: n / 2,
    randomscale: randomscale / 2.5,
    nw: west,
    ne: middle,
    sw,
    se: south,
  });
  diamondSquareHelper({
    vertexArray,
    dim,
    n: n / 2,
    randomscale: randomscale / 2.5,
    nw: middle,
    ne: east,
    sw: south,
    se,
  });
  /* eslint-enable no-param-reassign */
};

// initialize four corners of diamond square (remember grid is actually n+1 by n+1)
const setVerticesDiamondSquare = ({ vertexArray, n }) => {
  // in terms of start of vertex index, calc corners
  const nw = 0;
  const ne = n * 3;
  const sw = (n + 1) * n * 3;
  const se = (n + 1) * n * 3 + n * 3;

  /* eslint-disable no-param-reassign */
  // set corners
  vertexArray[nw + 2] = 0; // nw
  vertexArray[ne + 2] = Math.random() / 2; // ne
  vertexArray[sw + 2] = Math.random() / 2; // sw
  vertexArray[se + 2] = 1; // se
  /* eslint-enable no-param-reassign */

  const randomscale = 2; // initial random scale

  diamondSquareHelper({ vertexArray, dim: n + 1, n, randomscale, nw, ne, sw, se });
};

export const terrainFromIteration = ({ n, minX, maxX, minY, maxY }) => {
  const vertexArray = [];
  const faceArray = [];

  const deltaX = (maxX - minX) / n;
  const deltaY = (maxY - minY) / n;
  for (let i = 0; i <= n; i += 1) {
    for (let j = 0; j <= n; j += 1) {
      vertexArray.push(minX + deltaX * j);
      vertexArray.push(minY + deltaY * i);
      vertexArray.push(0);
    }
  }

  let numT = 0;
  for (let i = 0; i < n; i += 1) {
    for (let j = 0; j < n; j += 1) {
      const vid = i * (n + 1) + j;
      faceArray.push(vid);
      faceArray.push(vid + 1);
      faceArray.push(vid + n + 1);

      faceArray.push(vid + 1);
      faceArray.push(vid + 1 + n + 1);
      faceArray.push(vid + n + 1);
      numT += 2;
    }
  }

  // calculate heights of vertices and put changed heights into vertexArray
  setVerticesDiamondSquare({ vertexArray, n });

  // calculate normals of vertices and place into normalArray
  const normalArray = getNormals({ faceArray, vertexArray });

  return { numT, vertexArray, faceArray, normalArray };
};
