export const vertexShaderSrc = `
	attribute vec3 aPosition;
	attribute vec3 aNormal;

	uniform mat4 uMVMatrix;
	uniform mat4 uPMatrix;
	uniform mat3 uNMatrix;
    uniform mat3 inverseViewTransform;

	varying vec3 vNormal;
	varying vec3 vPosition;
	varying vec3 inversePosition;

	varying vec3 T;

	uniform vec3 uViewPt;

	// varying vec3 vVertexPosition;
	
	void main(void) {
		vPosition = aPosition;
		// vec3 eyeDirection = normalize(-vPosition.xyz);
	
		// Get the vertex position in eye coordinates
		vec4 vertexPositionEye4 = uMVMatrix * vec4(aPosition, 1.0);
		// vec3 vEyePosition = vertexPositionEye4.xyz / vertexPositionEye4.w;
		//Calaulate the normal

		// vPosition = (uMVMatrix * vec4(aPosition, 1.0)).xyz;
		vNormal = normalize(uNMatrix * aNormal);
		// vNormal = vec3(uMVMatrix*vec4(normalize(uNMatrix * aNormal),1.0));
		// vNormal = (uMVMatrix * vec4(aNormal, 1.0)).xyz;

		inversePosition = inverseViewTransform * aPosition;

		// vec4 viewDir = vertexPositionEye4;

		vec3 V = -vertexPositionEye4.xyz;
		vec3 R = -reflect(V.xyz, vNormal.xyz);
        T = inverseViewTransform * R;
	
		gl_Position = uPMatrix*uMVMatrix*vec4(aPosition, 1.0);
	}
`;

export const fragmentShaderSrc = `
	precision mediump float;

	// Passed in from the vertex shader.
	varying vec3 vPosition;
	varying vec3 vNormal;
	varying vec3 T;
	// varying vec3 R;
	varying vec3 inversePosition;

	// The texture.
	uniform samplerCube uCubeSampler;

	uniform bool uIsCube;
	uniform bool uToReflect;
	uniform bool uToBP;

	uniform vec3 uLightPosition;
	uniform vec3 uAmbientLightColor;
	uniform vec3 uDiffuseLightColor;
	uniform vec3 uSpecularLightColor;
	uniform vec3 uAmbientMaterialColor;
	uniform vec3 uDiffuseMaterialColor;
	uniform vec3 uSpecularMaterialColor;
	uniform float uShininess;

	void main() {
		if (uIsCube){
			gl_FragColor = textureCube(uCubeSampler, vPosition);
		}
		else{
			vec4 texColor = vec4(1.0, 1.0, 1.0, 1.0);
			vec4 bpColor = vec4(1.0, 1.0, 1.0, 1.0);
			if (uToReflect){
				texColor = textureCube(uCubeSampler, T);
			}
			if (uToBP){
				// Calculate the vector (l) to the light source
				vec3 vectorToLightSource = normalize(uLightPosition - vPosition);

				// Calculate n dot l for diffuse lighting
				float diffuseLightWeightning = max(dot(vNormal, vectorToLightSource), 0.0);
				
				// The camera in eye coordinates is located in the origin and is pointing
				// along the negative z-axis. Calculate viewVector (v) 
				// in eye coordinates as:
				// (0.0, 0.0, 0.0) - vertexPositionEye3
				vec3 viewVectorEye = -normalize(vPosition);
												   
				// Calculate the reflection vector (r) that is needed for specular light
				vec3 halfwayVector = normalize(vectorToLightSource+viewVectorEye);

				float ndoth = max(dot(halfwayVector, viewVectorEye), 0.0);
				
				float specularLightWeightning = pow(ndoth, uShininess);

				// Sum up all three reflection components and send to the fragment shader
				bpColor = vec4(((uAmbientLightColor*uAmbientMaterialColor)
							+ (uDiffuseLightColor*uDiffuseMaterialColor) * diffuseLightWeightning
							+ (uSpecularLightColor*uSpecularMaterialColor) * specularLightWeightning),1.0);

			}
			gl_FragColor = texColor*bpColor;
		}
	}
`;
