import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { useSpring } from 'react-spring';
import { useLocation } from 'react-router-dom';
import { detect } from 'detect-browser';

import { LIGHT_PALETTE, MIRAGE_PALETTE, DARK_PALETTE } from '../../Constants/Colors';
import { PALETTE_EASE_IN } from '../../Constants/TransitionCurves';
import { DisplayModeContext, MOBILE, DESKTOP } from '../../Constants/DisplayModes';
import {
  Z_SPACE_FADER,
  Z_SPACE_BACKGROUND,
  Z_SIDEBAR_OVERLAY,
  Z_SIDEBAR,
} from '../../Constants/ZIndicies';
import FontFamilies from '../../Constants/FontFamilies';
import {
  RootRoute,
  AboutRoute,
  SkillsRoute,
  WorkRoute,
  SettingsRoute,
  ContactRoute,
} from '../../Constants/Routes';

import useDimensions from '../../Hooks/useDimensions';
import usePrevious from '../../Hooks/usePrevious';
import useLocalStorage from '../../Hooks/useLocalStorage';
import useI18nConsumer from '../../Hooks/useI18nConsumer';

import Overlay from '../Modals/Overlay';
import InfoModal from '../Modals/InfoModal';
import TopbarMenu from '../NavMenus/TopbarMenu';
import SidebarMenu from '../NavMenus/SidebarMenu';
import MainScreenContent from './MainScreenContent';
import SpaceBackground from './SpaceBackground';

const browser = detect() || {};
const { name: browserName } = browser;

const StyledMainScreen = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  color: ${props => props.theme.foreground.string()};
  transition: ${PALETTE_EASE_IN};
  font-family: ${FontFamilies};
  font-size: ${props => (props.displayMode === MOBILE ? '0.85em' : '1em')};
`;

const Background = styled.div`
  position: absolute;
  z-index: ${Z_SPACE_BACKGROUND};
  height: 100vh;
  width: 100vw;
  background-color: ${props => props.theme.background.string()};
`;

const Fader = styled.div`
  position: absolute;
  z-index: ${Z_SPACE_FADER};
  height: 100vh;
  width: 100vw;
  background-color: ${props => props.theme.background.mix(props.mixColor, 0.05).string()};
  opacity: ${props => {
    const { show, strong, theme } = props;

    if (show) {
      if (strong) {
        return 0.35;
      }

      if (theme.id === LIGHT_PALETTE) {
        return 0.65;
      }

      return 0.85;
    }
    return 0;
  }};
  transition: ${PALETTE_EASE_IN};
`;

const StyledOverlay = styled(Overlay)`
  z-index: ${Z_SIDEBAR_OVERLAY};
`;

const StyledSidebarMenu = styled(SidebarMenu)`
  position: absolute;
  z-index: ${Z_SIDEBAR};
`;

const StyledDummySidebarMenu = styled(SidebarMenu)`
  position: absolute;
  visibility: hidden;
  overflow: hidden;
  margin-left: -100vw;
`;

const StyledMainScreenContent = styled(MainScreenContent)`
  position: relative;
`;

const SHORT_SIDEBAR_WIDTH = 60;

const MainScreen = ({
  className = '',
  onPaletteIdChange = () => {},
  onLanguageChange = () => {},
}) => {
  const { t } = useI18nConsumer();
  const location = useLocation();
  const theme = useContext(ThemeContext);
  const displayMode = useContext(DisplayModeContext);
  const prevDisplayMode = usePrevious(displayMode);
  const [showBrowserWarning, setShowBrowserWarning] = useLocalStorage(
    'browserWarningAcknowledged',
    true
  );

  const [showSidebarInMobile, setShowSidebarInMobile] = useState(false);
  const [sidebarExpanded, setSidebarExpanded] = useState(displayMode === DESKTOP);

  const dummySidebarMenuRef = useRef();
  const dummySidebarWidth = useDimensions(dummySidebarMenuRef)[1];

  const [lightBgURL, setLightBgURL] = useState('');
  const [mirageBgURL, setMirageBgURL] = useState('');
  const [darkBgURL, setDarkBgURL] = useState('');

  const animatedMenuSpringParams = {
    width:
      sidebarExpanded && dummySidebarWidth ? `${dummySidebarWidth}px` : `${SHORT_SIDEBAR_WIDTH}px`,
    from: {
      marginLeft: `-${SHORT_SIDEBAR_WIDTH}px`,
    },
  };

  const animatedContentSpringParams = {
    from: {
      marginLeft: displayMode === MOBILE ? '0px' : `${SHORT_SIDEBAR_WIDTH}px`,
    },
  };

  if (displayMode === MOBILE) {
    if (showSidebarInMobile) {
      if (sidebarExpanded && dummySidebarWidth) {
        animatedMenuSpringParams.marginLeft = '0px';
        animatedContentSpringParams.marginLeft = '0px';
      } else if (!sidebarExpanded) {
        animatedMenuSpringParams.marginLeft = '0px';
        animatedContentSpringParams.marginLeft = '0px';
      }
    } else {
      animatedMenuSpringParams.marginLeft = `-${SHORT_SIDEBAR_WIDTH}px`;
      animatedContentSpringParams.marginLeft = '0px';
    }
  } else if (sidebarExpanded && dummySidebarWidth) {
    animatedMenuSpringParams.marginLeft = '0px';
    animatedContentSpringParams.marginLeft = `${dummySidebarWidth}px`;
  } else {
    animatedMenuSpringParams.marginLeft = '0px';
    animatedContentSpringParams.marginLeft = `${SHORT_SIDEBAR_WIDTH}px`;
  }

  const animatedMenuSpring = useSpring(animatedMenuSpringParams);

  const animatedContentSpring = useSpring(animatedContentSpringParams);
  useEffect(() => {
    if (displayMode === MOBILE) {
      setSidebarExpanded(false);
      setShowSidebarInMobile(false);
    }
  }, [displayMode, prevDisplayMode]);

  useEffect(() => {
    const getBg = async () => {
      const bgExport = await import('../../Images/Backgrounds/URIs/lightBg.json');
      setLightBgURL(bgExport.default.url);
    };
    getBg();
  }, []);

  useEffect(() => {
    const getBg = async () => {
      const bgExport = await import('../../Images/Backgrounds/URIs/mirageBg.json');
      setMirageBgURL(bgExport.default.url);
    };
    getBg();
  }, []);

  useEffect(() => {
    const getBg = async () => {
      const bgExport = await import('../../Images/Backgrounds/URIs/darkBg.json');
      setDarkBgURL(bgExport.default.url);
    };
    getBg();
  }, []);

  const handleSidebarArrowClick = useCallback(() => {
    setSidebarExpanded(prevSidebarExpanded => !prevSidebarExpanded);
  }, []);

  const handleHamburgerClick = useCallback(() => {
    setShowSidebarInMobile(prevShowSidebarInMobile => !prevShowSidebarInMobile);
  }, []);

  const handleOverlayClick = useCallback(() => {
    setSidebarExpanded(false);
    setShowSidebarInMobile(false);
  }, []);

  const renderTopbarMenu = () => {
    if (displayMode !== MOBILE) {
      return null;
    }

    return <TopbarMenu onHamburgerClick={handleHamburgerClick} />;
  };

  const renderSidebarMenu = () => {
    return (
      <>
        {displayMode === MOBILE && showSidebarInMobile && (
          <StyledOverlay onClick={handleOverlayClick} />
        )}

        <StyledSidebarMenu
          spring={animatedMenuSpring}
          expanded={sidebarExpanded}
          onHamburgerClick={handleSidebarArrowClick}
        />
      </>
    );
  };

  const renderDummySidebarMenu = () => {
    return <StyledDummySidebarMenu ref={dummySidebarMenuRef} expanded />;
  };

  const renderMainScreenContent = () => {
    return (
      <StyledMainScreenContent
        spring={animatedContentSpring}
        onPaletteIdChange={onPaletteIdChange}
        onLanguageChange={onLanguageChange}
      />
    );
  };

  const renderBackground = () => {
    const renderFader = showFader => {
      const primaryPath = `/${location.pathname.split('/')[1]}`;

      let mixColor;
      switch (primaryPath) {
        case RootRoute:
          mixColor = theme.foregroundHome;
          break;
        case AboutRoute:
          mixColor = theme.foregroundAbout;
          break;
        case SkillsRoute:
          mixColor = theme.foregroundSkills;
          break;
        case WorkRoute:
          mixColor = theme.foregroundWork;
          break;
        case SettingsRoute:
          mixColor = theme.foregroundSettings;
          break;
        case ContactRoute:
          mixColor = theme.foregroundContact;
          break;
        default:
          mixColor = theme.foregroundHome;
      }

      return (
        <Fader show={showFader} mixColor={mixColor} strong={location.pathname === RootRoute} />
      );
    };

    const showLight = theme.id === LIGHT_PALETTE && lightBgURL !== '';
    const showMirage = theme.id === MIRAGE_PALETTE && mirageBgURL !== '';
    const showDark = theme.id === DARK_PALETTE && darkBgURL !== '';

    const showFader = showLight || showMirage || showDark;

    return (
      <>
        <Background>
          <SpaceBackground show={showLight} url={lightBgURL} />
          <SpaceBackground show={showMirage} url={mirageBgURL} />
          <SpaceBackground show={showDark} url={darkBgURL} />
        </Background>
        {renderFader(showFader)}
      </>
    );
  };

  const renderBrowserWarningModal = () => {
    return (
      <InfoModal
        title={t('MainScreen.aboutYourBrowser')}
        description={t('MainScreen.browserWarningBlurb')}
        onConfirm={() => setShowBrowserWarning(false)}
        confirmText={t('ok')}
      />
    );
  };

  return (
    <StyledMainScreen className={className} displayMode={displayMode}>
      {showBrowserWarning && browserName === 'ie' && renderBrowserWarningModal()}
      {renderBackground()}
      {renderTopbarMenu()}
      {renderSidebarMenu()}
      {renderDummySidebarMenu()}
      {renderMainScreenContent()}
    </StyledMainScreen>
  );
};

MainScreen.propTypes = {
  className: PropTypes.string,

  onPaletteIdChange: PropTypes.func,
  onLanguageChange: PropTypes.func,
};

export default MainScreen;
