import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import axios from 'axios';
import uuid from 'uuid';

import useI18nConsumer, { Trans } from '../../Hooks/useI18nConsumer';

import PageHeader from './Generic/PageHeader';
import PaddedPage from './Generic/PaddedPage';
import PageShadow from './Generic/PageShadow';
import Blurb from './Generic/Blurb';
import TextInput from '../Inputs/Generic/TextInput';
import TextArea from '../Inputs/Generic/TextArea';
import FlatButton from '../Inputs/Generic/FlatButton';
import InfoModal from '../Modals/InfoModal';

const StyledContactPage = styled(PaddedPage)`
  color: ${props => props.theme.foregroundContact.string()};
`;

const TextInputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const NameEmailRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const StyledFlatButton = styled(FlatButton)`
  margin-top: 10px;
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: ${props => props.theme.foregroundContact.string()};
`;

const validateEmail = email => {
  let isValidFormat = true;
  if (email.length === 0) {
    isValidFormat = false;
  } else {
    const split = email.split('@');
    if (split.length !== 2 || split[0].length <= 0 || split[1].length <= 0) {
      isValidFormat = false;
    }
  }
  return isValidFormat;
};

const ContactPage = ({ className = '' }) => {
  const { t } = useI18nConsumer();
  const [infoModalProps, setInfoModalProps] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  // TODO: use reducer since inputs are starting to get ugly
  const [nameVal, setNameVal] = useState('');
  const [nameKey, setNameKey] = useState('name');
  const [emailVal, setEmailVal] = useState('');
  const [emailKey, setEmailKey] = useState('email');
  const [messageVal, setMessageVal] = useState('');
  const [messageKey, setMessageKey] = useState('message');

  const nameValid = nameVal.length !== 0;
  const emailValid = validateEmail(emailVal);
  const messageValid = messageVal.length !== 0;
  const allValid = nameValid && emailValid && messageValid;

  const clearInputs = () => {
    setNameVal('');
    setNameKey(uuid.v4());
    setEmailVal('');
    setEmailKey(uuid.v4());
    setMessageVal('');
    setMessageKey(uuid.v4());
  };

  const handleSubmit = async event => {
    event.preventDefault();

    const data = {
      name: nameVal,
      email: emailVal,
      message: messageVal,
    };

    try {
      setSubmitting(true);

      await axios.post('https://getform.io/f/124b6fbb-8de9-4370-9a88-79ad2f25617d', data);

      setInfoModalProps({
        title: t('ContactPage.success'),
        description: (
          <div>
            {t('ContactPage.thanks')}
            <br />
            <br />
            {t('ContactPage.willContactBack')}
          </div>
        ),
        onConfirm: () => {
          setInfoModalProps(null);
          clearInputs();
        },
      });
    } catch (error) {
      console.error(error);

      let errorType = null;

      if (error && error.response && error.response.data) {
        errorType = error.response.data.error;
      }

      // generic error
      const href = 'mailto:aaron.yujung@gmail.com';

      let description = '';
      switch (errorType) {
        case 'sendLimitExceeded':
          description = (
            <Trans
              i18nKey="ContactPage.error.sendLimitExceeded"
              defaults="Receiving too many requests at this time. Please try again later, or shoot me an e-mail directly at <0>aaron.yujung@gmail.com!</0>"
              components={[<StyledLink href={href} />]}
            />
          );
          break;
        default:
          description = (
            <Trans
              i18nKey="ContactPage.error.networkError"
              defaults="Could not send a message at this time, shoot me an e-mail directly at <0>aaron.yujung@gmail.com</0>!"
              components={[<StyledLink href={href} />]}
            />
          );
      }

      setInfoModalProps({
        title: t('ContactPage.error.error'),
        description,
        onConfirm: () => setInfoModalProps(null),
      });
    } finally {
      setSubmitting(false);
    }
  };

  const renderTextInputs = () => {
    const nameError = t('ContactPage.error.noName');

    let emailError = '';
    if (emailVal.length === 0) {
      emailError = t('ContactPage.error.noEmail');
    } else if (!validateEmail(emailVal)) {
      emailError = t('ContactPage.error.invalidEmail');
    }

    const messageError = t('ContactPage.error.noMessage');

    const disabled = submitting;

    return (
      <TextInputsWrapper>
        <NameEmailRow>
          <TextInput
            key={nameKey}
            label={t('ContactPage.name')}
            value={nameVal}
            maxLength={100}
            valid={nameValid}
            error={nameError}
            onChange={e => setNameVal(e.target.value)}
            disabled={disabled}
          />
          <TextInput
            key={emailKey}
            label={t('ContactPage.emailAddress')}
            value={emailVal}
            maxLength={255}
            valid={emailValid}
            error={emailError}
            onChange={e => setEmailVal(e.target.value)}
            disabled={disabled}
          />
        </NameEmailRow>
        <TextArea
          key={messageKey}
          label={t('ContactPage.message')}
          value={messageVal}
          maxLength={3000}
          valid={messageValid}
          error={messageError}
          onChange={e => setMessageVal(e.target.value)}
          disabled={disabled}
        />
      </TextInputsWrapper>
    );
  };

  const renderButton = () => {
    const disabled = !allValid || submitting;

    return (
      <StyledFlatButton type="submit" disabled={disabled} isLoading={submitting}>
        {t('ContactPage.submit')}
      </StyledFlatButton>
    );
  };

  return (
    <StyledContactPage className={className}>
      {infoModalProps && <InfoModal confirmText={t('ok')} {...infoModalProps} />}
      <PageShadow>
        <Trans i18nKey="ContactPage.pageShadow" defaults="Co<0/>ntact" components={[<br />]} />
      </PageShadow>
      <PageHeader title={t('ContactPage.contact')} />
      <Blurb>{t('ContactPage.contactBlurb')}</Blurb>
      <form onSubmit={handleSubmit}>
        {renderTextInputs()}
        {renderButton()}
      </form>
    </StyledContactPage>
  );
};

ContactPage.propTypes = {
  className: PropTypes.string,
};

export default ContactPage;
