import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import useI18nConsumer, { Trans } from '../../Hooks/useI18nConsumer';

import PageHeader from './Generic/PageHeader';
import PaddedPage from './Generic/PaddedPage';
import PageShadow from './Generic/PageShadow';
import { StyledPortlet } from '../Portlets/Generic/Portlet';

import HtmlCssSkillsPortlet from '../Portlets/HtmlCssSkillsPortlet';
import JsSkillsPortlet from '../Portlets/JsSkillsPortlet';
import BackendSkillsPortlet from '../Portlets/BackendSkillsPortlet';
import OtherSkillsPortlet from '../Portlets/OtherSkillsPortlet';

const StyledSkillsPage = styled(PaddedPage)`
  color: ${props => props.theme.foregroundSkills.string()};
  ${StyledPortlet} {
    margin-bottom: 15px;
  }
  ${StyledPortlet}:last-child {
    margin-bottom: 0px;
  }
`;

const SkillsPage = ({ className = '' }) => {
  const { t } = useI18nConsumer();

  return (
    <StyledSkillsPage className={className}>
      <PageShadow>
        <Trans i18nKey="SkillsPage.pageShadow" defaults="Sk<0/>ills" components={[<br />]} />
      </PageShadow>
      <PageHeader title={t('SkillsPage.skills')} />
      <HtmlCssSkillsPortlet />
      <JsSkillsPortlet />
      <BackendSkillsPortlet />
      <OtherSkillsPortlet />
    </StyledSkillsPage>
  );
};

SkillsPage.propTypes = {
  className: PropTypes.string,
};

export default SkillsPage;
