import { mat4, vec3 } from 'gl-matrix';
import GLCanvas from '../GLCanvas';

import { degToRad, sphereFromSubdivision } from '../webGLUtils';
import RandomSphere from './RandomSphere';
import { vertexShaderSrc, fragmentShaderSrc } from './ParticlesShaders';

export default class ParticlesCanvas extends GLCanvas {
  constructor({
    canvas,
    clearColor = [0.0, 0.0, 0.0, 1.0],
    floorColor = [0.0, 0.7, 0.9],
    colors = [],
  }) {
    super({ canvas, eyePt: [0.0, -0.3, 3.0], clearColor });

    // colors
    this.colors = colors;

    // floor
    this.floorColor = floorColor;
    this.floorVertexPositionBuffer = null;

    // normals
    this.sphereVertexPositionBuffer = null;
    this.sphereVertexNormalBuffer = null;

    this.setState({
      spheres: [],
      lastDraw: null,
      pause: false,
    });
  }

  initialize = () => {
    this.setupShaders();
    this.setupBuffers();

    const newSpheres = [];

    // start off with 4 spheres
    for (let i = 0; i < 4; i += 1) {
      newSpheres.push(new RandomSphere({ colors: this.colors }));
    }

    this.setState({
      spheres: newSpheres,
      lastDraw: Date.now(),
    });

    window.addEventListener('blur', this.handleBlur);
    window.addEventListener('focus', this.handleFocus);

    super.initialize();
  };

  teardown = () => {
    window.removeEventListener('blur', this.handleBlur);
    window.removeEventListener('focus', this.handleFocus);
    super.teardown();
  };

  handleBlur = () => {
    this.setState({
      pause: true,
    });
  };

  handleFocus = () => {
    this.setState({
      pause: false,
      lastDraw: Date.now(),
    });
  };

  setupShaders = () => {
    super.setupShaders({ vertexShaderSrc, fragmentShaderSrc });

    this.shaderProgram.vertexPositionAttribute = this.gl.getAttribLocation(
      this.shaderProgram,
      'aVertexPosition'
    );
    this.gl.enableVertexAttribArray(this.shaderProgram.vertexPositionAttribute);

    this.shaderProgram.vertexNormalAttribute = this.gl.getAttribLocation(
      this.shaderProgram,
      'aVertexNormal'
    );
    this.gl.enableVertexAttribArray(this.shaderProgram.vertexNormalAttribute);

    this.shaderProgram.mvMatrixUniform = this.gl.getUniformLocation(
      this.shaderProgram,
      'uMVMatrix'
    );
    this.shaderProgram.pMatrixUniform = this.gl.getUniformLocation(this.shaderProgram, 'uPMatrix');
    this.shaderProgram.nMatrixUniform = this.gl.getUniformLocation(this.shaderProgram, 'uNMatrix');
    this.shaderProgram.uniformLightPositionLoc = this.gl.getUniformLocation(
      this.shaderProgram,
      'uLightPosition'
    );
    this.shaderProgram.uniformAmbientLightColorLoc = this.gl.getUniformLocation(
      this.shaderProgram,
      'uAmbientLightColor'
    );
    this.shaderProgram.uniformDiffuseLightColorLoc = this.gl.getUniformLocation(
      this.shaderProgram,
      'uDiffuseLightColor'
    );
    this.shaderProgram.uniformSpecularLightColorLoc = this.gl.getUniformLocation(
      this.shaderProgram,
      'uSpecularLightColor'
    );
    this.shaderProgram.uniformDiffuseMaterialColor = this.gl.getUniformLocation(
      this.shaderProgram,
      'uDiffuseMaterialColor'
    );
    this.shaderProgram.uniformAmbientMaterialColor = this.gl.getUniformLocation(
      this.shaderProgram,
      'uAmbientMaterialColor'
    );
    this.shaderProgram.uniformSpecularMaterialColor = this.gl.getUniformLocation(
      this.shaderProgram,
      'uSpecularMaterialColor'
    );

    this.shaderProgram.uniformShininess = this.gl.getUniformLocation(
      this.shaderProgram,
      'uShininess'
    );
  };

  setupBuffers() {
    this.setupFloorBuffers();
    this.setupSphereBuffers();
  }

  setupFloorBuffers = () => {
    const floorVertices = [
      -1.0,
      -1.0,
      -1.0,
      -1.0,
      -1.0,
      1.0,
      1.0,
      -1.0,
      -1.0,

      1.0,
      -1.0,
      -1.0,
      1.0,
      -1.0,
      1.0,
      -1.0,
      -1.0,
      1.0,
    ];

    // put floor vertices in floor vertex buffer
    this.floorVertexPositionBuffer = this.gl.createBuffer();
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.floorVertexPositionBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(floorVertices), this.gl.STATIC_DRAW);
    this.floorVertexPositionBuffer.itemSize = 3;
    this.floorVertexPositionBuffer.numItems = 6;
  };

  setupSphereBuffers = () => {
    const sphereSoup = [];
    const sphereNormals = [];
    const numT = sphereFromSubdivision(6, sphereSoup, sphereNormals);
    this.sphereVertexPositionBuffer = this.gl.createBuffer();
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.sphereVertexPositionBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(sphereSoup), this.gl.STATIC_DRAW);
    this.sphereVertexPositionBuffer.itemSize = 3;
    this.sphereVertexPositionBuffer.numItems = numT * 3;

    // Specify normals to be able to do lighting calculations
    this.sphereVertexNormalBuffer = this.gl.createBuffer();
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.sphereVertexNormalBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(sphereNormals), this.gl.STATIC_DRAW);
    this.sphereVertexNormalBuffer.itemSize = 3;
    this.sphereVertexNormalBuffer.numItems = numT * 3;
  };

  drawFloor = () => {
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.floorVertexPositionBuffer);
    this.gl.vertexAttribPointer(
      this.shaderProgram.vertexPositionAttribute,
      this.floorVertexPositionBuffer.itemSize,
      this.gl.FLOAT,
      false,
      0,
      0
    );

    this.setMatrixUniforms();
    this.gl.drawArrays(this.gl.TRIANGLES, 0, this.floorVertexPositionBuffer.numItems);
  };

  drawSphere = () => {
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.sphereVertexPositionBuffer);
    this.gl.vertexAttribPointer(
      this.shaderProgram.vertexPositionAttribute,
      this.sphereVertexPositionBuffer.itemSize,
      this.gl.FLOAT,
      false,
      0,
      0
    );

    // Bind normal buffer
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.sphereVertexNormalBuffer);
    this.gl.vertexAttribPointer(
      this.shaderProgram.vertexNormalAttribute,
      this.sphereVertexNormalBuffer.itemSize,
      this.gl.FLOAT,
      false,
      0,
      0
    );

    this.setMatrixUniforms();
    this.gl.drawArrays(this.gl.TRIANGLES, 0, this.sphereVertexPositionBuffer.numItems);
  };

  draw = () => {
    const { spheres } = this.state;

    const transformVec = vec3.create();
    const lightVec = vec3.fromValues(1, 1, 1);

    this.gl.viewport(0, 0, this.gl.viewportWidth, this.gl.viewportHeight);
    this.gl.clear(this.gl.COLOR_BUFFER_BIT | this.gl.DEPTH_BUFFER_BIT); // eslint-disable-line no-bitwise

    // We'll use perspective
    mat4.perspective(
      this.pMatrix,
      degToRad(45),
      this.gl.viewportWidth / this.gl.viewportHeight,
      0.1,
      200.0
    );

    // We want to look down -z, so create a lookat point in that direction
    vec3.add(this.viewPt, this.eyePt, this.viewDir);
    // Then generate the lookat matrix and initialize the MV matrix to that view
    mat4.lookAt(this.mvMatrix, this.eyePt, this.viewPt, this.up);
    this.mvPushMatrix();
    vec3.transformMat4(lightVec, lightVec, this.mvMatrix);

    // Use some boring white light
    this.uploadLightsToShader({
      loc: [lightVec[0], lightVec[1], lightVec[2]],
      a: [0.5, 0.5, 0.5],
      d: [1.0, 1.0, 1.0],
      s: [0.0, 0.0, 0.0],
    });

    // draw all spheres
    for (let i = 0; i < spheres.length; i += 1) {
      this.mvPushMatrix();

      const s = spheres[i];

      // move the sphere to correct place and scale it according to it's held information about its position and radius
      mat4.translate(this.mvMatrix, this.mvMatrix, s.pos);
      vec3.set(transformVec, s.radius, s.radius, s.radius);
      mat4.scale(this.mvMatrix, this.mvMatrix, transformVec);

      // upload the sphere's material colors
      this.uploadMaterialToShader({
        dcolor: s.getDiffuseMaterialColors(),
        acolor: s.getAmbientMaterialColors(),
        scolor: s.getSpecularMaterialColors(),
        shiny: s.shininess,
      });
      // draw it
      this.drawSphere();

      this.mvPopMatrix();
    }

    // make the floor blue
    this.uploadMaterialToShader({
      dcolor: [0.5, 0.5, 0.5],
      acolor: this.floorColor,
      scolor: [0.5, 0.5, 0.5],
      shiny: 88,
    });

    // draw the floor
    this.drawFloor();
    this.mvPopMatrix();
  };

  addBalls = () => {
    const { spheres } = this.state;

    const newSpheres = [];
    for (let i = 1; i <= 2; i += 1) {
      if (spheres.length + i <= 100) {
        newSpheres.push(new RandomSphere({ colors: this.colors }));
      }
    }

    this.setState(prevState => ({
      spheres: [...prevState.spheres, ...newSpheres],
    }));
  };

  clearBalls = () => {
    this.setState({ spheres: [] });
  };

  animate = () => {
    const { spheres, lastDraw, pause } = this.state;
    // calculate dt from the last time animate() was called
    const dt = (Date.now() - lastDraw) / 1000.0;

    this.setState({
      lastDraw: Date.now(),
    });

    if (pause) {
      return;
    }

    // update the position of each sphere using dt and default_drag
    for (let i = 0; i < spheres.length; i += 1) {
      spheres[i].updatePostion({ dt });
    }
  };

  handleKeys = () => {
    const { currentlyPressedKeys } = this.state;

    if (currentlyPressedKeys.KeyA) {
      this.orbit({ dir: 'left' });
    }
    if (currentlyPressedKeys.KeyD) {
      this.orbit({ dir: 'right' });
    }
    if (currentlyPressedKeys.KeyW) {
      this.orbit({ dir: 'up' });
    }
    if (currentlyPressedKeys.KeyS) {
      this.orbit({ dir: 'down' });
    }
    if (currentlyPressedKeys.KeyC) {
      this.clearBalls();
    }
    if (currentlyPressedKeys.KeyR) {
      this.resetCamera();
    }
  };
}
