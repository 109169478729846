import { vec3 } from 'gl-matrix';

const DEFAULT_SPEED_SCALE = 3.0;
const DEFAULT_GRAVITY = -9.81;
const DEFAULT_DRAG = 0.9;

export default class RandomSphere {
  constructor({ colors = [] }) {
    this.radius = Math.random() * 0.11 + 0.05;

    if (colors.length === 0) {
      this.difR = Math.random();
      this.difG = Math.random();
      this.difB = Math.random();

      this.amb_r = Math.random();
      this.amb_g = Math.random();
      this.amb_b = Math.random();

      this.spe_r = Math.random();
      this.spe_g = Math.random();
      this.spe_b = Math.random();
    } else {
      const getRandomColor = () => colors[Math.floor(Math.random() * colors.length)];

      const [difR, difG, difB] = getRandomColor();
      this.difR = difR;
      this.difG = difG;
      this.difB = difB;

      const [ambR, ambG, ambB] = getRandomColor();
      this.amb_r = ambR;
      this.amb_g = ambG;
      this.amb_b = ambB;

      const [speR, speG, speB] = getRandomColor();
      this.spe_r = speR;
      this.spe_g = speG;
      this.spe_b = speB;
    }

    this.shininess = Math.random() * 200;

    this.pos = vec3.fromValues(Math.random() - 0.5, Math.random() - 0.5, Math.random() - 0.5);

    this.x_vel = DEFAULT_SPEED_SCALE * (Math.random() * 2 - 1);
    this.y_vel = DEFAULT_SPEED_SCALE * (Math.random() * 2 - 1);
    this.z_vel = DEFAULT_SPEED_SCALE * (Math.random() * 2 - 1);

    this.gravity = DEFAULT_GRAVITY;
    this.drag = DEFAULT_DRAG;
  }

  getDiffuseMaterialColors = () => {
    return [this.difR, this.difG, this.difB];
  };

  getAmbientMaterialColors = () => {
    return [this.amb_r, this.amb_g, this.amb_b];
  };

  getSpecularMaterialColors = () => {
    return [this.spe_r, this.spe_g, this.spe_b];
  };

  updatePostion = ({ dt }) => {
    // reverse x velocity if ball x-side hits wall
    if (this.pos[0] + this.radius >= 1) this.x_vel = -Math.abs(this.x_vel);
    else if (this.pos[0] - this.radius <= -1) {
      this.x_vel = Math.abs(this.x_vel);
    }

    // reverse y velocity if ball y-side hits wall
    if (this.pos[1] + this.radius >= 1) this.y_vel = -Math.abs(this.y_vel);
    else if (this.pos[1] - this.radius <= -1) {
      this.y_vel = Math.abs(this.y_vel);
    }

    // reverse z velocity if ball y-side hits wall
    if (this.pos[2] + this.radius >= 1) this.z_vel = -Math.abs(this.z_vel);
    else if (this.pos[2] - this.radius <= -1) {
      this.z_vel = Math.abs(this.z_vel);
    }

    // update velocity with drag
    const dragFactor = this.drag ** dt;
    this.x_vel *= dragFactor;
    this.y_vel *= dragFactor;
    this.z_vel *= dragFactor;

    // make ball stop bouncing once low enough and not high enough y velocity
    if (Math.abs(this.y_vel) < 0.0001 && this.pos[1] - this.radius <= -0.999) {
      this.y_vel = 0;
    } else {
      // otherwise update y-velocity using gravity (euler integration)
      this.y_vel += this.gravity * dt;
    }

    // move the position vector by adding velocity * dt
    const velVec = vec3.fromValues(this.x_vel, this.y_vel, this.z_vel);
    vec3.scale(velVec, velVec, dt);
    vec3.add(this.pos, this.pos, velVec);

    // manually move ball above floor if now outside the box
    if (this.pos[1] - this.radius < -1) {
      this.pos[1] = -1.0 + this.radius;
      this.y_vel = Math.abs(this.y_vel);
    }
  };
}
