import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import Color from 'color';

import { PALETTE_EASE_IN, HOVER_COLOR_EASE_IN } from '../../../Constants/TransitionCurves';
import FontFamilies from '../../../Constants/FontFamilies';

import LoadingIcon from '../../Icons/LoadingIcon';

const StyledFlatButton = styled.button`
  position: relative;
  padding: 0.25em 30px;
  min-width: 6em;
  font-family: ${FontFamilies};
  font-size: 1.2em;
  background-color: ${props => props.theme.panel.string()};
  color: ${props => props.backgroundColor};
  cursor: pointer;
  transition: ${PALETTE_EASE_IN}, ${HOVER_COLOR_EASE_IN};

  border-width: 0.75px;
  border-style: solid;
  border-radius: 3px;
  border-color: ${props => props.backgroundColor};

  :disabled {
    background-color: ${props => props.theme.panel.string()};
    color: ${props => props.theme.foregroundIdle.string()};
    border-color: ${props => props.theme.foregroundIdle.string()};
    cursor: auto;
  }

  :hover:enabled {
    background-color: ${props => props.weakColor};
    color: ${props => props.theme.backgroundColor};
    border-color: ${props => props.backgroundColor};
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 7.5px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${props => (props.isLoading ? 1 : 0)};
  transition: ${PALETTE_EASE_IN}, ${HOVER_COLOR_EASE_IN};
`;

const FlatButton = ({
  className = '',
  children = null,
  primary = true,
  disabled = false,
  isLoading = false,
  type = 'button',
  primaryColor = '',
  onClick = () => {},
}) => {
  const theme = useContext(ThemeContext);

  const backgroundColor = primaryColor || theme.foregroundContact.string();
  const weakColor = Color(backgroundColor)
    .fade(0.7)
    .string();

  return (
    <StyledFlatButton
      className={className}
      primary={primary}
      disabled={disabled}
      type={type}
      onClick={onClick}
      backgroundColor={backgroundColor}
      weakColor={weakColor}
    >
      {children}
      <IconWrapper isLoading={isLoading}>
        <LoadingIcon size={15} />
      </IconWrapper>
    </StyledFlatButton>
  );
};

FlatButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  primary: PropTypes.bool,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  type: PropTypes.string,
  primaryColor: PropTypes.string,
  onClick: PropTypes.func,
};

export default FlatButton;
