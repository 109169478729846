import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { DisplayModeContext, DESKTOP } from '../../../Constants/DisplayModes';

const StyledUserControl = styled.div`
  display: flex;
  flex-direction: row;

  min-height: 27px;

  font-size: 1.05em;
  justify-content: ${props => (props.displayMode !== DESKTOP ? 'space-between' : 'flex-start')};
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: bold;
  width: 33.3%;
  max-width: 250px;
  justify-content: center;
`;

const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  text-align: ${props => (props.displayMode !== DESKTOP ? 'right' : 'left')};
`;

const UserControl = ({ className = '', contentLeft = null, contentRight = null }) => {
  const displayMode = useContext(DisplayModeContext);

  return (
    <StyledUserControl className={className} displayMode={displayMode}>
      <LeftWrapper>{contentLeft}</LeftWrapper>
      <RightWrapper displayMode={displayMode}>{contentRight}</RightWrapper>
    </StyledUserControl>
  );
};

UserControl.propTypes = {
  className: PropTypes.string,
  contentLeft: PropTypes.node,
  contentRight: PropTypes.node,
};

export default UserControl;
